import React, { Component } from 'react';
import { post } from 'axios';
import { withFirebase } from '../../config';
import mainlogo from '../../images/main-logo.png';
import lockicon from '../../images/lock-icon.png';
import '../../css/animate.css'
import '../../css/main-style.css'
import { Redirect } from 'react-router-dom';
import { Input, Button, Icon, Label,Modal } from 'semantic-ui-react'
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from '../Layout/Footer';
import { Field, reduxForm } from 'redux-form'
import validate from '../../LoginValidation/validate'
import renderField from '../../LoginValidation/renderField'
import { connect } from 'react-redux';
import { signIn } from '../../Actions/AuthActions'
import { adminForgetPassword } from '../../Actions/UserManagementActions'
import { emptyError } from '../../Actions/Empty'
import Loader from '../Layout/Loader';

/* Routes */
import {ADMIN_LAND_PAGE, ALL_SERIES, PARTNERHOME,VENDORDASHBOARD} from '../../routes';

const s = {
  paddingTop: '10px'
}

const INITIAL_STATE = {
  email: '',
  password: '',
  passwordForgotten: false,
  sendingMailMessage: 'Email has been sent to you to reset your password',
  mailSent: false
};


class Login extends Component {

  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const { auth } = this.props
    console.log("mount" + this.props.auth.uid)
    if (auth.uid) {
      return (<Redirect to={ADMIN_LAND_PAGE} ></Redirect>)
    }
  }

  openModel = (e) => {
    this.setState({ passwordForgotten: true});
  }

  closeModel = (e) => {
      this.setState({ passwordForgotten: false})
  }

  closeMessageModel = (e) => {
    this.setState({ mailSent: false})
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = (e) => {
    const { email, password } = this.state;
    this.props.signIn(email, password)
    e.preventDefault();
  }

  handleForgotPasswordSubmit = (e) => {

    this.props.adminForgetPassword(this.state.email);
    this.setState({ passwordForgotten: false, mailSent: true})
    e.preventDefault();
  }

  render() {
    const { form } = this.props
    this.props.emptyError();
    let $Loader = null;

    const { success, loading, errorMsg } = this.props;

    const { user } = this.props;

    if (loading === true) {
      $Loader = (<Loader />)
    }

    if (success === true) {
      this.props.emptyError();
      if (user.user_role.role !== "USER"  && (user.user_role.role === "SuperAdmin" || user.user_role.role === "ADMIN" || user.user_role.role === "Analyst")) {
        return (<Redirect to={ADMIN_LAND_PAGE}></Redirect>)
      }
      else if (user.user_role.role  === "ContentPublisher" || user.user_role.role  === "AppManagement") {
        return (<Redirect to={ALL_SERIES}></Redirect>)
      }
      else if (user.user_role.role  === "Vendor") {
        return (<Redirect to={VENDORDASHBOARD}></Redirect>)
      }
      else {
        alert("Error User can't login");
      }
    }
    else if (success === false) {
      alert( errorMsg);
      this.props.emptyError();
    }

    if(this.state.passwordForgotten === true){
      return (
        <Modal id="modal" onClose={this.closeModel} open={this.state.passwordForgotten}>
            <Modal.Header>Forget Password </Modal.Header>
            <Modal.Content >
                <Modal.Actions>
                    <form className="white">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <label htmlFor="email" className="label">
                                  Email </label>
                                <Input fluid name="email" type="email" required onChange={this.handleChange}
                                    value={this.state.email} />
                                <br />
                                <div className="input-field">
                                    <Button type="submit"  style={{backgroundColor: '#611358'}} onClick={this.handleForgotPasswordSubmit}
                                         id="saveButton" >
                                        Send Mail
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Actions>
            </Modal.Content>
        </Modal>
      )
    }

    else if(this.state.mailSent === true){
      return (
        <Modal id="modal" onClose={this.closeMessageModel} open={this.state.mailSent}>
            <Modal.Header>Sending Recovery Mail </Modal.Header>
            <Modal.Content >
                <Modal.Actions>
                    <form className="white">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <p>{this.state.sendingMailMessage} </p>
                                <div className="input-field">
                                    <Button type="submit"  style={{backgroundColor: '#611358'}} onClick={this.closeMessageModel}
                                         id="saveButton" >
                                        Ok
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Actions>
            </Modal.Content>
        </Modal>
      )
    }
    else{
      return (
        <div className="container" style={s}>
          <div className="row">
            <div className="col-6">
              <a className="language-btn small-font" href="#">ar</a>
            </div>
            <div className="col-6" >
              <img alt="main logo" className="main-logo float-right" src={mainlogo} />
            </div>
          </div>
          <div className="card card-container">
            {$Loader}

            <img id="profile-img" className="lock-icon" src={lockicon} alt="lockicon" />
            <p id="profile-name" className="profile-name-card">LOGIN TO ADMIN PANEL</p>
            <form className="form-signin" onSubmit={this.handleSubmit}>
              <label className="small-label" htmlFor="email">Username or Email Address</label>
              <input type="email" id="inputEmail" component={renderField} className="form-control" placeholder="Username / Email Address" name="email" autoFocus value={this.state.email} onChange={this.handleChange} />
              <label className="small-label">Password</label>
              <input type="password" id="inputPassword" className="form-control" component={renderField} name="password" placeholder="Password" value={this.state.password} onChange={this.handleChange} />
              <div id="remember" className="checkbox">
                <label className="small-font">
                  <input type="checkbox" value="remember-me" /> Remember me
                   </label>
              </div>
              <button className="btn btn-lg btn-primary btn-block btn-signin" type="submit" > Sign in</button>


            </form>
            <a href="#" onClick={this.openModel} className="small-font forgot-password">
              Forgot the password?
             </a>
          </div>
          <Footer />
        </div>
     )
    }

  }
}

const mapStateToProps = state => (
  {
    success: state.authUser.success,
    loading: state.authUser.loading,
    errorMsg: state.authUser.errorMsg,
    user: state.authUser.item,
    auth: state.firebase.auth,
    form: state.form.login
  })

export default reduxForm({
  form: 'login',
  validate
})
  (withFirebase
    (connect
      (mapStateToProps,
        { signIn, emptyError, adminForgetPassword }
      )
      (Login)
    )
  );
