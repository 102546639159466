import React from 'react';

import { Link, NavLink, withRouter } from "react-router-dom";
import { Partener_Vouchers,Partener_Batch_Assign } from '../../../routes';
import DateFormatter from '../../../utils/DateFormatter';



const PartenerBatches = ({ batches, loading }) => {
 
  if (loading) {
    return <h1>Loading...</h1>;
  }

 const formatPercentage=(partial,total)=>{

	if(!partial || partial < 1)
		return '';
			
	let percentageInDouble = (100*partial)/total;
	let percentageWithoutDouble = Math.trunc( percentageInDouble );
	
	if(percentageInDouble==percentageWithoutDouble)
	{	
		return "("+percentageInDouble+"%)"
	} 	
	else
	{
		percentageInDouble = percentageInDouble.toFixed(1)
		return "("+percentageInDouble+"%)"
	}
}
 
 
  return (
    <>
      {batches?
      batches.map((batch, index) => (
        


		<div className="row m-2 mb-3" style={{"height": "7em","borderRadius":"10px"}} key={batch.batchNo}>
			
			    <div className="col-3 pl-0">													
					<div className="col-12 mt-3">Batch No</div>
					<div className="col-12 mt-1">
						  <NavLink to={{ pathname:Partener_Vouchers, state: {date:batch.created_date,package:batch.package_type,batchId:batch.batchId}   }} activeClassName="page-active">
		                    <span> {batch.batchNo} </span>
		                  </NavLink>															
					</div>
				</div>													
			    <div className="col-2 pl-0">													
					<div className="col-12 mt-3">Date</div>
					<div className="col-12 mt-1">{DateFormatter.formatDateToDisplay(batch.created_date)}</div>
				</div>
			    <div className="col-1 pl-0">													
					<div className="col-12 mt-3">Type</div>
					<div className="col-12 mt-1">{batch.package_type}</div>
				</div>
				<div className="col-2 pl-0">													
					<div className="col-12 mt-3">N. of Cards</div>
					<div className="col-12 mt-1">{batch.noOfCards} Code</div>
				</div>
			    <div className="col-2 pl-0">													
					<div className="col-12 mt-3">N. of Active Cards</div>
					<div className="col-12 mt-1">{batch.activeCards} </div>    {/*formatPercentage(batch.activeCards,batch.noOfCards)*/}
				</div>
				
				<div className="col-2 pl-0">													
					<div className="col-12 mt-3">Import</div>  
					<div className="col-12 mt-1">
						  <NavLink to={{ pathname:Partener_Batch_Assign , state: {batchId:batch.batchId}   }}  activeClassName="page-active">
		                    <span> Assignment  </span>
		                  </NavLink>					
					</div>    
				</div>
				
			</div>


      )):''}
    </>
  );
};

export default PartenerBatches;