/************************ JEEL SERVICE NAME ******************************/
export const JEEL_SERVICE_NAME =  "جيل";

/************************ JEEL SUPPORT MAIL ******************************/
export const SUPPORT_MAIL =  "hello@jeelapp.com";

/************************ TELECOM SERVICE NAMES ******************************/
export const TPAY =  "Tpay";
export const FIT = "Fit";

/************************ FIT SERVICES ******************************/
export const FIT_STC =  "JEEL_STC";
export const FIT_MOBILY =  "JEEL_MOBILY";
export const FIT_ZAIN =  "JEEL_ZAIN";

/************************ FIT COUNTRIES ****************************/
export const EGYPT = "Egypt";
export const EGYPT_SHORT_CODE = "eg";

/************************ FIT SUBSCRIPTION MESSAGES ****************************/
export const ZAIN_SUBSCRIPTION_MESSAGE = "قيمة الاشتراك ريال واحد يوميًا (تشمل ضريبة القيمة المضافة 15%).";
export const MOBILY_SUBSCRIPTION_MESSAGE = "قيمة الاشتراك ريال واحد يوميًا (تشمل ضريبة القيمة المضافة 15%).";
export const STC_SUBSCRIPTION_MESSAGE = "قيمة الاشتراك 2.3 ريال يومياً (تشمل ضريبة القيمة المضافة 15%).";

/************************ FIT SUBSCRIPTION MESSAGES ****************************/
export const ZAIN_COMPLETED_SUBSCRIPTION_MESSAGE = "للاشتراك في باقة زين اليومية، أرسل رقم (11) في رسالة نصية إلى رقم (708080) بريال واحد يوميًا (تشمل ضريبة القيمة المضافة 15%)، وللإلغاء أرسل (غ ١) أو (U 1).";
export const MOBILY_COMPLETED_SUBSCRIPTION_MESSAGE = " للاشتراك في باقة موبايلي اليومية، أرسل رقم (1) في رسالة نصية إلى رقم (607078) بريال واحد يوميًا (تشمل ضريبة القيمة المضافة 15%)، وللإلغاء أرسل (غ ١) أو (U 1)."
export const STC_COMPLETED_SUBSCRIPTION_MESSAGE = "للاشتراك في باقة STC اليومية، أرسل رقم (1) في رسالة نصية إلى رقم (801666) بـ 2.3 ريال يومياً (تشمل ضريبة القيمة المضافة 15%)، وللإلغاء أرسل (غ ١) أو (U 1).";

/************************ TPAY COUNTRIES **************************/
export const SAUDI_ARABIA = "Saudi Arabia";
export const SAUDI_ARABIA_SHORT_CODE = "sa";

/************************ TPAY SERVICES ******************************/
export const TPAY_ORANGE =  "JEEL_Orange";
export const TPAY_VODAFONE =  "JEEL_Vodafone";
export const TPAY_ETISALAT =  "JEEL_Etisalat";
export const TPAY_WE =  "JEEL_We";

export const JEEL_Orange =  "اورانج - Orange";
export const JEEL_Vodafone =  "فودافون - Vodafone";
export const JEEL_Etisalat =  "اتصالات - Etisalat";
export const JEEL_We =  "وى -We";


export const TPAY_KEYWORDS = {
    JEEL_Orange : 'UNSUB JL',
    JEEL_Vodafone : 'UNSUB JL',
    JEEL_Etisalat : 'UNSUB JL',
    JEEL_We : 'UNSUB JL',
};

export const TPAY_SHORT_CODES = {
    JEEL_Orange : '5030',
    JEEL_Vodafone : '2303',
    JEEL_Etisalat : '1722',
    JEEL_We : '4041',
};


// --------------
// Media Worlds :
// --------------

/************************ MediaWorld SERVICES ******************************/
export const MW_ZAIN_IRAQ =  "JEEL_MW_ZAIN_IRAQ";
export const MW_BATELCO_BAHRAIN =  "JEEL_MW_BATELCO_BAHRAIN";
export const MW_VODAPHONE_QATAR =  "JEEL_MW_VODA_QATAR"; 
export const MW_LIBYANA_LIBYA =  "JEEL_MW_LIBYANA_LIBYA";
export const MW_ALMADAR_LIBYA =  "JEEL_MW_ALMADAR_LIBYA";

export const PAYMENT_METHOD_TELECOM_IRAQ_ZAIN =  "IRAQ_ZAIN";
export const PAYMENT_METHOD_TELECOM_LIBYA_MADAR =  "LIBYA_MADAR";
export const PAYMENT_METHOD_TELECOM_LIBYA_LIBYANA =  "LIBYA_LIBYANA";
export const PAYMENT_METHOD_TELECOM_QATAR_VODAPHONE =  "QATAR_VODAPHONE";
/************************ MediaWorld COUNTRIES **************************/
export const IRAQ = "Iraq";
export const IRAQ_SHORT_CODE = "iq";
export const MW_ZAIN_IRAQ_SUBSCRIPTION_MESSAGE = "قيمة الاشتراك 300 دينار يوميا (تشمل ضريبة القيمة المضافة 15%).";

export const BAHRAIN = "Bahrain";
export const BAHRAIN_SHORT_CODE = "bh";
export const MW_BATELCON_BAHRAIN_SUBSCRIPTION_MESSAGE = "قيمة الاشتراك ريال واحد أسبوعيًا (تشمل ضريبة القيمة المضافة 15%).";

export const QATAR = "Qatar";
export const QATAR_SHORT_CODE = "qa";
export const MW_VODAPHONE_QATAR_SUBSCRIPTION_MESSAGE = "قيمة الاشتراك ريال واحد أسبوعيًا (تشمل ضريبة القيمة المضافة 15%).";

export const LIBYA = "Libya";
export const LIBYA_SHORT_CODE = "ly";
//export const MW_LIBYANA_LIBYA_SUBSCRIPTION_MESSAGE = "قيمة الاشتراك 0.75 دينار يوميا (تشمل ضريبة القيمة المضافة 15%)";
//export const MW_ALMADAR_LIBYA_SUBSCRIPTION_MESSAGE = "قيمة الاشتراك 3 دينار أسبوعيًا (تشمل ضريبة القيمة المضافة 15%)";

//export const MW_LIBYANA_LIBYA_SUBSCRIPTION_MESSAGE = "قيمة الاشتراك 0.75 دينار يوميا و 3 دينار أسبوعيًا";
//export const MW_ALMADAR_LIBYA_SUBSCRIPTION_MESSAGE = "قيمة الاشتراك 3 دينار أسبوعيًا";

export const MW_LIBYANA_LIBYA_SUBSCRIPTION_MESSAGE = "قيمة الاشتراك 3 دينار أسبوعيًا ";
export const MW_ALMADAR_LIBYA_SUBSCRIPTION_MESSAGE = "قيمة الاشتراك 3 دينار أسبوعيًا ";


/************************ PAYMENT PACKAGES TYPES IN ARABIC ******************************/
export const APPLE =  "أبل";
export const STRIPE =  "بطاقة الدفع";
export const FAWRY =  "فوري";
export const PRE_PAID_CARD =  "بطاقة مسبقة الدفع";
export const MADA = "مدى";
export const PORTAL_STC_PAY = "بطاقة STC Pay";
export const PORTAL_FIT_STC =  "بطاقة STC";
export const PORTAL_FIT_MOBILY =  "بطاقة موبايلي";
export const PORTAL_FIT_ZAIN =  "بطاقة زين";
export const PORTAL_TPAY_ORANGE =  "أورانج";
export const PORTAL_TPAY_VODAFONE =  "فودافون";
export const PORTAL_TPAY_ETISALAT =  "اتصالات";
export const PORTAL_TPAY_WE =  "وي";

/************************ PAYMENT PACKAGES TYPES AS SAVED IN DB ******************************/
export const DB_APPLE =  "Apple";
export const DB_STRIPE =  "Stripe";
export const DB_FAWRY =  "Fawry";
export const DB_PRE_PAID_CARD =  "Pre_Paid_Card";
export const DB_MADA = "MADA";
export const DB_STC_PAY = "STC_PAY";
export const DB_FIT_STC =  "FIT_STC";
export const DB_FIT_MOBILY =  "FIT_MOBILY";
export const DB_FIT_ZAIN =  "FIT_ZAIN";
export const DB_TPAY_ORANGE =  "JEEL_Orange";
export const DB_TPAY_VODAFONE =  "JEEL_Vodafone";
export const DB_TPAY_ETISALAT =  "JEEL_Etisalat";
export const DB_TPAY_WE =  "JEEL_We";

/************************  SUBSCRIPTION TYPES  ******************************/
export const SUBSCRIPTION_YEARLY =  "Yearly";
export const SUBSCRIPTION_MONTHLY =  "Monthly";
export const SUBSCRIPTION_WEEKLY = "Weekly";

export const PORTAL_SUBSCRIPTION_YEARLY =  "سنوياً";
export const PORTAL_SUBSCRIPTION_MONTHLY =  "شهرياً";
export const PORTAL_SUBSCRIPTION_WEEKLY = "أسبوعياً";

//************************  Regex  ******************************/
export const ARABICREGEX = /^[\u0600-\u06FF\s-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\؟\[\]\^\|\d]*$/;
export const ENGLISHREGEX = /^[a-zA-Z\s-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|\d]*$/;


//*********************** Student Activity **********************/

// Period constants
export const PERIODS = {
    PERIOD_12AM_6AM: "12AM - 6AM",
    PERIOD_6AM_12PM: "6AM - 12PM",
    PERIOD_12PM_6PM: "12PM - 6PM",
    PERIOD_6PM_12AM: "6PM - 12AM",
};

export const PERIOD_KEYS = [
    PERIODS.PERIOD_12AM_6AM,
    PERIODS.PERIOD_6AM_12PM,
    PERIODS.PERIOD_12PM_6PM,
    PERIODS.PERIOD_6PM_12AM,
];

export const DAY_ORDER = ["Saturday", "Sunday","Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];


