import {
    FETCH_ALL_VENDOR, LOAD_VENDOR,
    ERROR_LOADING_ALL_VENDOR,
    ADD_VENDOR_SUCCESSFULLY, ADD_VENDOR_ERROR,
    UPDATE_VENDOR_SUCCESSFULLY, UPDATE_VENDOR_ERROR,
    GET_VENDOR_DATA_SUCCESSFULLY,GET_VENDOR_DATA_ERROR,
    EMPTY_VENDOR,LOAD_VENDOR_DASHBOARD, FETCH_VENDOR_DASHBOARD_SUCCESS, FETCH_VENDOR_DASHBOARD_ERROR,
    FETCH_CHILDREN_BY_VENDOR, ERROR_FETCHING_CHILDREN_BY_VENDOR



} from '../Actions/Types'


const initialState = {
    items: [],
    item: {},
    loading: '',

    error_all_loading_vendors: '',
    getting_allVendors_sucessfully: '',

    vendor_data_successfully:'',
    vendor_data_error:'',
    
    add_vendor_successfully: '',
    add_vendor_error: '',

    update_vendor_successfully: '',
    update_vendor_error: '',

    dashboardData: {},
    dashboardLoading: false,
    dashboardError: '',

    childrenByVendor: [], 
    childrenError: ''
}

export default function (state = initialState, action) {
    switch (action.type) {


        case LOAD_VENDOR:
            return {
                ...state,
                loading: true
            }
        case FETCH_ALL_VENDOR:
            return {
                ...state,
                items: action.payload,
                error_all_loading_vendors: '',
                getting_allVendors_sucessfully: true,
                loading: false,
                item: {}
            }
        case ERROR_LOADING_ALL_VENDOR:
            return {
                ...state,
                error_all_loading_vendors: action.payload,
                loading: false
            }

            
        case GET_VENDOR_DATA_SUCCESSFULLY:
            return {
                ...state,
                item: action.payload,
                vendor_data_successfully: true,
                vendor_data_error: '',
                loading: false
            }
            
        case GET_VENDOR_DATA_ERROR:
            return {
                ...state,
                vendor_data_error: action.payload,
                vendor_data_successfully: false,
                loading: false
            }

        case ADD_VENDOR_SUCCESSFULLY:
            return {
                ...state,
                add_vendor_successfully: true,
                loading: false
            }

        case ADD_VENDOR_ERROR:
            return {
                ...state,
                add_vendor_successfully: false,
                add_vendor_error: action.payload,
                loading: false
            }

        case UPDATE_VENDOR_SUCCESSFULLY:
            return {
                ...state,
                update_vendor_successfully: true,
                update_vendor_error:'',
                loading: false
            }

        case UPDATE_VENDOR_ERROR:
            return {
                ...state,
                update_vendor_successfully: false,
                update_vendor_error: action.payload,
                loading: false
            }

        case EMPTY_VENDOR:
            return {
                ...state,

                error_all_loading_vendors: '',
                getting_allVendors_sucessfully: '',

                add_vendor_successfully: '',
                add_vendor_error: '',

                update_vendor_successfully: '',
                update_vendor_error: '',

                vendor_data_successfully:'',
                vendor_data_error:'',
                item:{}
            }

            case LOAD_VENDOR_DASHBOARD:
            return {
                ...state,
                dashboardLoading: true
            };
        case FETCH_VENDOR_DASHBOARD_SUCCESS:
            return {
                ...state,
                dashboardData: action.payload,
                dashboardError: '',
                dashboardLoading: false
            };
        case FETCH_VENDOR_DASHBOARD_ERROR:
            return {
                ...state,
                dashboardError: action.payload,
                dashboardLoading: false
            };

        case FETCH_CHILDREN_BY_VENDOR:
            return {
                ...state,
                childrenByVendor: action.payload,
                childrenError: '',
                loading: false
            };
        case ERROR_FETCHING_CHILDREN_BY_VENDOR:
            return {
                ...state,
                childrenError: action.payload,
                loading: false
            };    
            
        default:
            return state

    }
}