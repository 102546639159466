// new
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import firebase from 'firebase/app'
import propTypes from 'prop-types';
import ReactTable from 'react-table';
import FoldableTableHOC from 'react-table/lib/hoc/foldableTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popup } from 'semantic-ui-react'

/**Actions */
import { emptyCustomer } from '../../../Actions/CustomerActions.js';
import { getVendorCustomerAsPages } from '../../../Promises/Customer.js'
import { emptyPackageActivation } from '../../../Actions/CustomerActions.js';
import { getUserData } from '../../../Actions/MobilePaymentProfileAction.js';
import { addSeriesIDtoRedirectionPage } from '../../../Actions/RedirectionPagesActions.js'

/*Components */
import Loader from '../../Layout/Loader.js';
import BlockingCustomer from '../../Customer/BlockingCustomer.js';
import ResetPasswordCustomer from '../../Customer/ResetPasswordCustomer.js';
import CustomerMobilePaymentControll from '../../Customer/CustomerMobilePaymentControll.js';
/*CSS */
import 'react-table/react-table.css';
/* Routes */
import { PARTNERPARENTS, VIEW_CUSTOMER } from '../../../routes.js';

const FoldableTable = FoldableTableHOC(ReactTable);


class ParentsTable extends Component {

    state = {
        page: '',
        pageSize: '',
        pages: -1,
        loading: false,

        filtered: [],
        customers: [],

        checkedItems: new Map(),

        modal: false
    };

    constructor(props) {
        super(props);

        if (firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((token) => {
                this.props.getUserData(token);
            })
        }

        this.setState({ loading: true })
        getVendorCustomerAsPages(0, 20, '', '').then((res) => {
            this.setState({
                customers: res.data.content,
                loading: false,
                page: res.data.pageNumber,
                pageSize: res.data.pageSize,
                pages: res.data.totalPages
            })
        })
    }

    componentDidMount() {

        this.props.addSeriesIDtoRedirectionPage(PARTNERPARENTS, 0);

        this.setState({ loading: true })
        getVendorCustomerAsPages(0, 20, '', '').then((res) => {
            this.setState({
                customers: res.data.content,
                loading: false,
                page: res.data.pageNumber,
                pageSize: res.data.pageSize,
                pages: res.data.totalPages
            })
        })
    }

    handleCheckbuttonChange = (uid, e) => {

        let { checkedItems } = this.state;

        const isChecked = e.target.checked;
        // console.log(" isChecked = " + isChecked);

        console.log(uid);
        let checkeditem = { "uid": uid, "isChecked": isChecked }

        var temCheckded = new Map([...checkedItems]);
        if (temCheckded.has(uid)) {
            if (isChecked == false) {
                temCheckded.delete(uid);
            }
        } else {
            temCheckded.set(uid, checkeditem);
        }
        this.setState({ checkedItems: temCheckded })

        this.props.getListOfCheckedCustomersCallBack(temCheckded);

        // console.log(this.state.checkedItems);
    }


    getCheckedState(uid) {
        //if Msp Contains my uid
        if (this.state.checkedItems.has(uid)) {
            // console.log("found here" + uid);
            return true;
        }
        //Cannot find it
        return false;
    }

    handleBlockingCustomer = (uid, e) => {

        console.log("handleBlockingCustomer")
        console.log(uid);
    }

    // handleExpirationDateChange = (e) => {
    //     this.setState({ [e.target.name]: e.target.value });

    //     getCustomerAsPages(0, 20, 'expirationDate', e.target.value).then((res) => {
    //         this.setState({
    //             customers: res.data.content,
    //             loading: false,
    //             page: res.data.pageNumber,
    //             pageSize: res.data.pageSize,
    //             pages: res.data.totalPages
    //         })
    //     })
    // }



    render() {
        console.log(this.state.customers)
        // console.log('Parent');

        const { userPaymentProfile } = this.props;

        var columns = [
            {
                Header: "",
                filterable: false,
                width: 40,
                MinWidth: 40,
                Maxwidth: 40,
                resizable: false,
                headerClassName: 'table-header',
                Cell: props => {
                    //       console.log("checkbox = " + props.original.uid);
                    let checkState = this.getCheckedState(props.original.uid);
                    return (
                        <div scope="row">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox"
                                    className="custom-control-input"
                                    name="check_customer"
                                    id={props.original.uid} checked={checkState}
                                    onClick={this.handleCheckbuttonChange.bind(this, props.original.uid)}
                                />
                                <label className="custom-control-label"
                                    for={props.original.uid}></label>
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: "Name",
                accessor: "name",
                width: 140,
                MinWidth: 140,
                Maxwidth: 140,
                foldable: true,
                resizable: true,

                headerClassName: 'table-header',

                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>
                            <Link className="link"
                                to={{ pathname: VIEW_CUSTOMER + "/" + props.original.uid }}>

                                {props.original.name}
                                {/* </div> */}
                            </Link>
                        </div>
                    )
                }
            },
            {
                Header: "Email",
                accessor: "email",
                width: 140,
                MinWidth: 140,
                Maxwidth: 140,
                foldable: true,
                resizable: true,

                headerClassName: 'table-header',

                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>
                            <div>
                                {props.original.email}
                                {/* </div> */}
                            </div>
                        </div>
                    )
                }

            },
            {
                Header: "Gender",
                accessor: "gender",
                width: 140,
                MinWidth: 140,
                Maxwidth: 140,
                foldable: true,
                resizable: true,

                headerClassName: 'table-header',

                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>
                            <div>
                                {props.original.gender}
                                {/* </div> */}
                            </div>
                        </div>
                    )
                }

            },
            {
                Header: "Country",
                accessor: "country",
                width: 140,
                MinWidth: 140,
                Maxwidth: 140,
                foldable: true,
                resizable: true,
                headerClassName: 'table-header',

                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>
                            <div>
                                {props.original.country}
                                {/* </div> */}
                            </div>
                        </div>
                    )
                }

            },
            {
                Header: "Num of kids",
                accessor: "numberOfKids",
                width: 100,
                // foldable: true,
                resizable: false,
                headerClassName: 'table-header',
                Cell: props => {
                    return (
                        <div style={{ "textAlign": "center" }}>
                            {props.original.numberOfKids}
                        </div>)
                }
            },
            {
                Header: "Payment Plan",
                accessor: "paymentPlan",
                filterable: true,
                width: 150,
                headerClassName: 'table-header',

                foldable: true,
                resizable: false,

                Cell: props => {
                    if (props.original.packageType !== null &&
                        props.original.packageType !== undefined) {
                            return (<div style={{ "textAlign": "center" }}>
                                {props.original.packageType}
                            </div>)
        
                    }
                    else {
                        return (<div style={{ "textAlign": "center" }}> -- </div>)
                    }
                }

            },
            {
                Header: "Expiration Date",
                accessor: "expirationDate",
                width: 150,
                headerClassName: 'table-header',

                foldable: true,
                resizable: false,

                // Filter: ({ filter, onChange }) =>
                //     <div>
                //         <input type="date"
                //             name="date" value={this.state.date}
                //             onChange={this.handleExpirationDateChange} />

                //     </div>,
                // filterMethod: (filter, row) => {
                //     console.log("change")

                //     console.log(filter.value)

                // },

                Cell: props => {
                    const { expirationDatetime } = props.original;
                
                    if (expirationDatetime !== null && expirationDatetime !== undefined) {
                        const date = new Date(expirationDatetime);
                        
                        const formattedDate = 
                            ('0' + date.getDate()).slice(-2) + '-' +
                            ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
                            date.getFullYear();
                        
                        return (
                            <div style={{ textAlign: "center" }}>
                                {formattedDate}
                            </div>
                        );
                    } else {
                        return (
                            <div style={{ textAlign: "center" }}> -- </div>
                        );
                    }
                }
                
            },
            {
                Header: "Last login",
                accessor: "lastLoginDifference",
                filterable: false,
                width: 80,
                headerClassName: 'table-header',
                resizable: false,

                // foldable: true,
                Cell: props => {
                    return (
                        <div style={{ "textAlign": "center" }}>
                            {props.original.lastLoginDifference} m
                        </div>)
                }
            },
        ]

        if (userPaymentProfile.user_role.role !== "Analyst") {
            let actions = {
                Header: "Actions",
                width: 120,
                filterable: false,
                headerClassName: 'table-header',
                resizable: false,
                Cell: props => {
                    return (
                        <div style={{ "textAlign": "center" }}>
                            <ResetPasswordCustomer email={props.original.email} />
                            <BlockingCustomer disabled
                                onClick={this.handleBlockingCustomer.bind(this, props.original.uid)}
                                uid={props.original.uid}
                                state={props.original.status} />
                            {/* <CustomerMobilePaymentControll
                                uid={props.original.uid}
                                closeModal={this.state.modal} /> */}

                            <Link className="link"
                                to={{ pathname: VIEW_CUSTOMER + "/" + props.original.uid }}>

                                <Popup trigger={<a className="user-lock" >
                                    <i>  <FontAwesomeIcon icon="id-card" />
                                    </i>
                                </a>}
                                    content='Customer Profile'
                                    position='top center'
                                />



                            </Link>
                        </div>
                    )
                }
            }
            columns.push(actions);
        }
        //////////// start handeling alert in resertPassword
        const { resetPassworderror, resetPasswordsuccess, resetItem } = this.props;

        if (resetPassworderror !== '') {
            alert("error missing data or network " + resetPassworderror)
            this.props.emptyCustomer();
        }
        else if (resetPasswordsuccess === true) {
            alert(resetItem)
            this.props.emptyCustomer();

            let filterValue = '';
            if (this.state.filtered[0] !== undefined) {
                filterValue = this.state.filtered[0].value
            }
            getVendorCustomerAsPages(this.state.page, this.state.pageSize, this.state.filtered[0].id, filterValue)
                .then((res) => {
                    // Update react-table
                    this.setState({
                        customers: res.data.content,
                        pages: res.data.totalPages,
                        loading: false,

                    })
                })
        }
        //////////// end handeling alert in resertPassword

        //////////// start handeling alert in Blocking Customer
        const { blockingCustomerError, blockingCustomerSuccess } = this.props;

        console.log(blockingCustomerError)
        console.log(blockingCustomerSuccess)

        if (blockingCustomerError !== '') {
            alert("error missing data or network " + blockingCustomerError)
            this.props.emptyCustomer();
        }
        else if (blockingCustomerSuccess === true) {
            alert("Customer State Changed Successfully")
            this.props.emptyCustomer();

            let filterValue = '';
            if (this.state.filtered[0] !== undefined) {
                filterValue = this.state.filtered[0].value
            }
            console.log("*******************")
            console.log(this.state.page)
            console.log(this.state.pageSize)
            console.log(filterValue)

            console.log("*******************")

            if (this.state.filtered[0] !== undefined && this.state.filtered !== null) {
                getVendorCustomerAsPages(this.state.page, this.state.pageSize, this.state.filtered[0].id, filterValue)
                    .then((res) => {

                        console.log("customers = ")
                        console.log(res.data.content)
                        console.log("pages:" + res.data.totalPages)

                        // Update react-table
                        this.setState({
                            customers: res.data.content,
                            pages: res.data.totalPages,
                            loading: false,

                        })
                    })
            } else {
                getVendorCustomerAsPages(this.state.page, this.state.pageSize, '', '')
                    .then((res) => {

                        console.log("customers = ")
                        console.log(res.data.content)
                        console.log("pages:" + res.data.totalPages)

                        // Update react-table
                        this.setState({
                            customers: res.data.content,
                            pages: res.data.totalPages,
                            loading: false,

                        })
                    })
            }
        }
        //////////// end handeling alert in Blocking Customer

        //////////// start handeling alert in Blocking activePayment

        const { paymentSuccess,
            paymentError } = this.props;

        console.log(paymentSuccess)
        console.log(paymentError)

        if (paymentSuccess === true) {
            alert("Customer Package Activated Successfully ! ")

            this.setState({

                modal: true
            })
            this.props.emptyPackageActivation();

        }
        else if (paymentSuccess === false) {
            alert("Error " + paymentError)
            this.props.emptyPackageActivation();
        }

        const { loading } = this.props;
        let customersList = null


        if (loading === true) {
            customersList = (<Loader />)
        }
        else {
            return (
                <div>
                    <ReactTable
                        //{/* <FoldableTable */}
                        className=" table-striped custm-table"
                        columns={columns}
                        data={this.state.customers}
                        filterable
                        pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
                        loading={this.state.loading}
                        manual // informs React Table that you'll be handling sorting and pagination server-side
                        onFetchData={(state, instance) => {
                            console.log(state)
                            this.setState({ loading: true })
                            console.log('filter', state.filtered)
                            let filterValue = '';
                            if (state.filtered[0] !== undefined) {
                                filterValue = state.filtered[0].value

                                getVendorCustomerAsPages(state.page, state.pageSize, state.filtered[0].id, filterValue)
                                    .then((res) => {
                                        // Update react-table
                                        this.setState({
                                            customers: res.data.content,
                                            pages: res.data.totalPages,
                                            loading: false,

                                        })
                                    })
                            }
                            else {
                                getVendorCustomerAsPages(state.page, state.pageSize, '', filterValue)
                                    .then((res) => {
                                        // Update react-table
                                        this.setState({
                                            customers: res.data.content,
                                            pages: res.data.totalPages,
                                            loading: false,

                                        })
                                    })
                            }
                        }}
                        onFilteredChange={(filter) => {
                            if (filter !== undefined) {
                                this.setState({ filtered: filter })
                            }
                        }}
                    >
                    </ReactTable>
                    {/* </FoldableTable> */}

                </div>)
        }


        return (
            <div>
                <div className="mt-4 responsive-table">
                    {customersList}
                </div>

            </div>
        )
    }

}

ParentsTable.propTypes = {

    getVendorCustomerAsPages: propTypes.func.isRequired,
    emptyPackageActivation: propTypes.func.isRequired,


}
const mapStateToProps = state => ({

    customers: state.customers.items,
    loading: state.customers.loading,
    userPaymentProfile: state.userPaymentProfile.item,

    resetPassworderror: state.customers.customer_reset_password_error,
    resetPasswordsuccess: state.customers.customer_reset_password_sucessfully,
    resetItem: state.customers.item,


    blockingCustomerError: state.customers.blocking_customer_error,
    blockingCustomerSuccess: state.customers.blocking_customer_sucessfully,

    paymentError: state.customers.active_customer_package_error,
    paymentSuccess: state.customers.active_customer_package_sucessfully,

    addSeriesIDtoRedirectionPage: propTypes.func.isRequired,

});

export default withRouter
    (connect
        (mapStateToProps,
            {
                emptyCustomer,
                getUserData,
                getVendorCustomerAsPages,
                emptyPackageActivation,
                addSeriesIDtoRedirectionPage
            }
        )(ParentsTable)
    );
