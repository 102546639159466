import React from 'react';



const Pagination = ({ batchesPerPage, length,handlePagination,currentPage,totalPages}) => {

  const paginationNumbers = [];



//for (let i = 1; i <= Math.ceil(length / batchesPerPage); i++) {
	for (let i = 0; i < totalPages; i++) {

    paginationNumbers.push(i);

  }



return (
	
		
		    <div className='pagination'>
		
		      {paginationNumbers.map((pageNumber) => (
		
				/*<button  key={pageNumber} className={currentPage === pageNumber ? 'active' : ''}>*/
				
				<button key={pageNumber} className={currentPage === pageNumber ? 'page-active' : ''}  onClick={()=>{handlePagination(pageNumber)}}>{pageNumber+1}</button>
		
		      ))}
		
		    </div>
  );

};



export default Pagination;

