import React, { Component } from 'react';
import { withFirebase } from '../../config';
import { Link } from 'react-router-dom'
import {  Button, Dropdown } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import Footer from '../Layout/Footer';
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import { saveVendor } from '../../Actions/AuthActions';
import { emptyVendor } from '../../Actions/VendorActions'
/*CSS */
import '../../css/AppCss/Series/AddSeries.css';
import country_list from '../ConstData/PaymentAndVendorCountriesList'
/* Routes */
import { ADMIN_LAND_PAGE, VENDORS } from '../../routes';

const INITIAL_STATE = {

    name: '',
    email: '',
    password: '',
    phone: '',
    country: '',
    note: '',
    contractType: '',
    city: '',
    influencerInfo: '',
    addess: '',

    ////////////////// validation form
    error: true,
    errorMessage: "",
};

export class AddVendor extends Component { 

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }


    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });

        console.log("email")
        console.log(checkSpaces)

        setTimeout(() => {
            if ((this.state.name === '' || this.state.name === null || this.state.name === undefined)
                || (this.state.password === '' || this.state.password === null || this.state.password === undefined)
                || (this.state.email === '' || this.state.email === null || this.state.email === undefined)
                || (this.state.phone === '' || this.state.phone === null || this.state.phone === undefined)
                || (this.state.country === '' || this.state.country === null || this.state.country === undefined)
            ) {
                this.setState({ error: true, errorMessage: "Empty Data is not accepted" });
            }
            else if ((this.state.name !== '' && this.state.name !== null && this.state.name !== undefined)
                && (this.state.password !== '' && this.state.password !== null && this.state.password !== undefined)
                && (this.state.email !== '' && this.state.email !== null && this.state.email !== undefined)
                && (this.state.phone !== '' && this.state.phone !== null && this.state.phone !== undefined)
                && (this.state.country !== '' && this.state.country !== null && this.state.country !== undefined)

            ) {
                this.setState({ error: false, errorMessage: "" });
            }
        }, 0);
    }

    handleCountryChange = (e, { value }) => {
        this.setState({ country: value });
    };

    handleSubmit = (e) => {

        const { email, password, name, phone, country, note, contractType, city, influencerInfo, addess } = this.state;
        this.props.saveVendor(email, password, name, phone, country, note, contractType, city, influencerInfo, addess);
        // this.closeModal();
        e.preventDefault();

    }

    render() {
        const { success, error, loading } = this.props;


        let buttonSubmitLoader = (
            <Button onClick={this.handleSubmit}
                id="nextButton" disabled={this.state.error}>
                Add
            </Button>);

        if (loading === true) {
            buttonSubmitLoader = (
                <button id="nextButton"
                    class="ui loading button">
                    Loading
                </button>
            )
        }
        if (success === true) {
            this.props.emptyVendor();
            alert("Adding Partner Done Successfully");
            return (<Redirect to={VENDORS}></Redirect>)

        }
        else if (success === false) {
            alert(error);
            this.props.emptyVendor();
        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <div className="container">

                                                {/* <!--  start status bar --> */}
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span> {'>'} </span>
                                                        <Link to={VENDORS}>Partner</Link><span> {'>'} </span>

                                                    </span>
                                                    <span>Add New Partner</span>

                                                </div>
                                                {/* <!--  end status bar --> */}

                                                <h3>Add New Partner</h3>
                                                <hr />

                                                <div className="">
                                                    <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                                        <p style={{ color: "red" }}>{this.state.errorMessage} </p>

                                                        <label htmlFor="name" className="label">
                                                            <span className="required_input">*</span>
                                                                    Name</label>
                                                        <input
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="name" id="name" onChange={this.handleChange}
                                                            value={this.state.name} />
                                                        <br />


                                                        <label htmlFor="name" className="label">
                                                            <span className="required_input">*</span>
                                                            Password</label>
                                                        <input
                                                            type="password"
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="password" id="password"
                                                            onChange={this.handleChange}
                                                            value={this.state.password}
                                                            required />
                                                        <br />

                                                        <label htmlFor="name" className="label">
                                                            <span className="required_input">*</span>
                                                            Email</label>
                                                        <input
                                                            type="email"
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="email" id="email"
                                                            onChange={this.handleChange}
                                                            value={this.state.email}
                                                            required />
                                                        <br />

                                                        <label htmlFor="phone" className="label">
                                                            <span className="required_input">*</span>
                                                            Phone</label>
                                                        <input
                                                            type="number"
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="phone" id="email"
                                                            onChange={this.handleChange}
                                                            value={this.state.phone}
                                                            required />
                                                        <br />


                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            <span className="required_input">*</span>
                                                                Select Country </label><br />

                                                        <Dropdown fluid search selection
                                                            placeholder='Select Country'
                                                            id="episode_series_id"
                                                            onChange={this.handleCountryChange}
                                                            options={country_list}
                                                            value={this.state.country}
                                                        />
                                                        <br />

                                                        <label htmlFor="city" className="label">
                                                            City</label>
                                                        <input
                                                            type="text"
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="city" id="city"
                                                            onChange={this.handleChange}
                                                            value={this.state.city}
                                                        />
                                                        <br />


                                                        <div className="form-group">
                                                            <label htmlFor="addess" className="label">
                                                                Addess
                                                            </label>

                                                            <textarea dir="auto"
                                                                fluid rows="3"
                                                                className="form-control"
                                                                name="addess"
                                                                id="addess"
                                                                onChange={this.handleChange}
                                                                value={this.state.addess} />
                                                        </div>
                                                        <br />

                                                        <div className="form-group">
                                                            <label htmlFor="contractType" className="label">
                                                                Contract Type
                                                            </label>

                                                            <input
                                                                type="text"
                                                                dir="auto"
                                                                className="form-control"
                                                                name="contractType"
                                                                id="contractType"
                                                                onChange={this.handleChange}
                                                                value={this.state.contractType} />
                                                        </div>
                                                        <br />


                                                        <div className="form-group">
                                                            <label htmlFor="influencerInfo" className="label">
                                                                Influencer Info
                                                            </label>

                                                            <textarea dir="auto"
                                                                fluid rows="3"
                                                                className="form-control"
                                                                name="influencerInfo"
                                                                id="influencerInfo"
                                                                onChange={this.handleChange}
                                                                value={this.state.influencerInfo} />
                                                        </div>
                                                        <br />


                                                        <div className="form-group">
                                                            <label htmlFor="note" className="label">
                                                                Note
                                                            </label>

                                                            <textarea dir="auto"
                                                                fluid rows="3"
                                                                className="form-control"
                                                                name="note"
                                                                id="note"
                                                                onChange={this.handleChange}
                                                                value={this.state.note} />
                                                        </div>

                                                    </form>

                                                </div>


                                                <div className="input-field">

                                                    {buttonSubmitLoader}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>


        )
    }
}


AddVendor.propTypes = {
    saveVendor: propTypes.func.isRequired,
    //     fetchAllSeries: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    loading: state.vendor.loading,
    success: state.vendor.add_vendor_successfully,
    error: state.vendor.add_vendor_error
});

export default withFirebase
    (connect
        (mapStateToProps,
            {
                saveVendor,
                emptyVendor
            }
        )
        (AddVendor)
    );
