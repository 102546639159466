import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import AddGroupStep from './AddGroupStep';
import AssignStudentStep from './AssignStudentStep';
import {findChildrenByVendorIdAndName} from '../../../Actions/ChildActions';
import './../../../../src/css/group.css';
import { createGroup,fetchAllGroups } from '../../../Actions/GroupActions';
import { GROUPS } from '../../../routes';
import { Redirect } from 'react-router-dom';

class AddGroupModal extends Component {
  state = {
    step: 1,
    groupName: '',
    isActive: false,
    students: [],
    groupNameError: false,
  };
  componentDidMount() {
    findChildrenByVendorIdAndName('').then((res) => {
      this.setState({ students: res.data.content });
    });
}
handleSearchChange = (e) => {
  const searchQuery = e.target.value;

  this.setState({ searchQuery });

  // Trigger the search only when the query has at least 3 characters
  if (searchQuery.length >= 3) {
    findChildrenByVendorIdAndName(searchQuery).then((res) => {
      this.setState({ students: res.data.content });
    });
  }
  else if (searchQuery.length === 0) {
    findChildrenByVendorIdAndName('').then((res) => {
      this.setState({ students: res.data.content });
    });
  }
};
handleInputChange = (e) => {
  this.setState({ groupName: e.target.value, groupNameError: false });
};

  handleToggle = () => {
    this.setState((prevState) => ({ isActive: !prevState.isActive }));
  };

  handleNextStep = () => {
    if (this.state.groupName.trim() === '') {
      this.setState({ groupNameError: true });
      return;
    }

    if (this.state.students.length === 0) {
      findChildrenByVendorIdAndName('').then((res) => {
        this.setState({ students: res.data.content });
      });
    }
    this.setState({ step: 2 });
  };

  handleBackStep = () => {
    this.setState({ step: 1 });
  };

  toggleStudentSelection = (index) => {
    const updatedStudents = [...this.state.students];
    updatedStudents[index].selected = !updatedStudents[index].selected;
    this.setState({ students: updatedStudents });
  };

  handleAssign = () => {
    /*
  group=  {
    "name": "x",
    "status": "Active",
    "startDate": "2024-10-10T00:00:00",
    "children": [{"id":292187}]
}
    */
    const group = {
      name: this.state.groupName,
      status: this.state.isActive ? 'Active' : 'Inactive',
      children: this.state.students
        .filter((student) => student.selected)
        .map((student) => ({ id: student.id })),
    }
     this.props.createGroup(group);
  };
onCloseModal = () => {
  this.setState({ 
    step: 1,
    students: [], 
    groupName: '',
    isActive: false 
  });
  this.props.onClose();
}
  render() {
    const { step, groupName, isActive, students, groupNameError } = this.state;
    const { isOpen, onClose } = this.props;

    if (!isOpen) return null;
    if(this.props.createGroupSuccess) {
      alert("Adding Group Done Successfully");
      this.onCloseModal();
      this.props.fetchAllGroups();
    }
    if(this.props.addGroupError!=="" ) {
      alert(this.props.addGroupError);
     
    }
    
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <button className="close-modal" onClick={this.onCloseModal}>X</button>
          {step === 1 ? (
            <AddGroupStep
              groupName={groupName}
              isActive={isActive}
              groupNameError={groupNameError}
              handleInputChange={this.handleInputChange}
              handleToggle={this.handleToggle}
              handleNextStep={this.handleNextStep}
            />
          ) : (
            <AssignStudentStep
              students={students}
              loading={this.props.loading}
              handleSearchChange={this.handleSearchChange}
              toggleStudentSelection={this.toggleStudentSelection}
              handleBackStep={this.handleBackStep}
              handleAssign={this.handleAssign}
            />
          )}
        </div>
      </div>
    );
  }
}

// export default AddGroupModal;
AddGroupModal.propTypes = {
  createGroupSuccess: propTypes.bool.isRequired,
  addGroupError: propTypes.string,
};

const mapStateToProps = state => ({
createGroupSuccess: state.group.add_group_successfully,
addGroupError: state.group.add_group_error,
loading: state.group.loading

});

export default withRouter(
  connect(
      mapStateToProps,
      { createGroup,fetchAllGroups }
  )(AddGroupModal)
);
