
import React, { Component } from 'react'
import { DateInput } from 'semantic-ui-calendar-react';
import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import firebase from 'firebase/app';

/*Navbar*/
import OuterHeader from '../../Layout/OuterHeader';
import InnerHeader from '../../Layout/InnerHeader';
import Dashboard from '../../Dashboard';
import Footer from '../../Layout/Footer';
import { Popup } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ReactTable from 'react-table';


import { useLocation } from 'react-router-dom'


import { useState } from "react";
import { useEffect } from 'react';
import { getFirebase } from 'react-redux-firebase';
import { post, get } from 'axios';

import { Partener_Cards } from '../../../routes';
import DateFormatter from '../../../utils/DateFormatter';

import { getBatchesVouchers } from './PartenerBatchActions';


//const PartenerVouchers = ()=>{   
const PartenerVouchers = ({ getBatchesVouchers, partenerBatchVouchers, loading,totalPages, success, error }) => {

	let location = useLocation();

	//const [listOfPartenerBatchVouchers, setListOfPartenerBatchVouchers] = useState([])
	//const [listOfFilteredCriteria, setListOfFilteredCriteria] = useState([])       
	const [batchId, setBatchId] = useState(location.state.batchId)
	const [tableControlsObj, setTableControlsObj] = useState({ loading, page: partenerBatchVouchers.pageable.pageNumber, pageSize: 10, pages: totalPages })

//console.log('pageNummmmmm:'+partenerBatchVouchers.pageable.pageNumber);
//console.log('Totla Pages is: '+totalPages)

	const creationDate = location.state.date;
	const packageType = location.state.package;

	// =======================================================================================================================================================
	const voucherClos = [

		{
			Header: "Code",
			accessor: "code",
			width: 170,
			MinWidth: 170,
			Maxwidth: 170,
			foldable: true,
			resizable: true,

			headerClassName: 'table-header',

			Cell: props => {

				return (
					<div style={{ "textAlign": "center" }}>
						<div>
							{props.original.code}
							{/* </div> */}
						</div>
					</div>
				)
			}

		},
		{
			Header: "Status",
			accessor: "used",
			width: 100,
			MinWidth: 100,
			Maxwidth: 100,
			foldable: true,
			resizable: true,

			headerClassName: 'table-header',

			Cell: props => {

				return (
					<div style={{ "textAlign": "center" }}>
						<div>
							{props.original.used ? 'Active' : 'UnActive'}
							{/* </div> */}
						</div>
					</div>
				)
			}

		},
		{
			Header: "Name",
			accessor: "name",
			width: 130,
			MinWidth: 130,
			Maxwidth: 130,
			foldable: true,
			resizable: true,

			headerClassName: 'table-header',

			Cell: props => {

				return (
					<div style={{ "textAlign": "center" }}>
						<Link className="link"
							to={{ pathname: "url const" + "/" + props.original.uid }}>

							{props.original.name}
							{/* </div> */}
						</Link>
					</div>
				)
			}
		},
		{
			Header: "Email",
			accessor: "email",
			width: 160,
			MinWidth: 160,
			Maxwidth: 160,
			foldable: true,
			resizable: true,

			headerClassName: 'table-header',

			Cell: props => {

				return (
					<div style={{ "textAlign": "center" }}>
						<div>
							{props.original.email}
							{/* </div> */}
						</div>
					</div>
				)
			}

		},
		{
			Header: "Start Date",
			accessor: "paymentDate",
			width: 100,
			MinWidth: 100,
			Maxwidth: 100,
			foldable: true,
			resizable: true,
			filterable: false,

			headerClassName: 'table-header',

			Cell: props => {

				return (
					<div style={{ "textAlign": "center" }}>
						<div>
							{props.original.paymentDate ? DateFormatter.formatDateToDisplay(props.original.paymentDate) : ''}

							{/* </div> */}
						</div>
					</div>
				)
			}

		},
		{
			Header: "Expiration Date",
			accessor: "expirationDate",
			width: 120,
			MinWidth: 120,
			Maxwidth: 120,
			foldable: true,
			resizable: true,
			filterable: false,

			headerClassName: 'table-header',

			Cell: props => {

				return (
					<div style={{ "textAlign": "center" }}>
						<div>
							{props.original.expirationDate ? DateFormatter.formatDateToDisplay(props.original.expirationDate) : ''}
							{/* </div> */}
						</div>
					</div>
				)
			}

		}
	]
	// =======================================================================================================================================================        

	// =======================================================================================================================================================




	function ConvertDate(date) {
		if (!date)
			return "";

		var d = new Date(date)

		//var datestring = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes();   
		var datestring = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear();

		return datestring
	}



	const getCheckedState = (uid) => {
		//if Msp Contains my uid
		//if (this.state.checkedItems.has(uid)) {
		// console.log("found here" + uid);
		return true;
		//}
		//Cannot find it
		return false;
	}

	const handleCheckbuttonChange = (uid, e) => {
		/*
			let { checkedItems } = this.state;
		
			const isChecked = e.target.checked;
			// console.log(" isChecked = " + isChecked);
		
			console.log(uid);
			let checkeditem = { "uid": uid, "isChecked": isChecked }
		
			var temCheckded = new Map([...checkedItems]);
			if (temCheckded.has(uid)) {
				if (isChecked == false) {
					temCheckded.delete(uid);
				}
			} else {
				temCheckded.set(uid, checkeditem);
			}
			this.setState({ checkedItems: temCheckded })
		
			this.props.getListOfCheckedCustomersCallBack(temCheckded);
			*/

		// console.log(this.state.checkedItems);
	}

	// =====================================================================

	useEffect(() => {
		getBatchesVouchers([], batchId, tableControlsObj.page, tableControlsObj.pageSize);
	}, []);

	// =====================================================================

	/* =====================================================================================================================================================*/
	/* =====================================================================================================================================================*/
	/* =====================================================================================================================================================*/

	//console.log('tableControlsObj.pages##############3 is :'+tableControlsObj.pages);

	return (

		<div className="container">
			<OuterHeader />
			<div className="container">
				<div className="container-body">
					<div className="row">
						<Dashboard />
						<div className="col-9">
							<InnerHeader />
							<div className="main-content">
								<div className="row">
									<div className="col-12 left-main-body" id="left-main-body">
										<div className="row m-1 " style={{ "height": "40px" }}><span className="font-weight-bold mr-2">
											<NavLink to={{ pathname: Partener_Cards }} activeClassName="page-active">
												<span> Cards </span>
											</NavLink>
										</span> &gt; Voucher</div>
										<div className="box-container">
											<div className="row">
												<div className="col-2"><h2 className="main-title mt-2 font-weight-bold"> <span>Vouchers</span> </h2></div>
												{/*<div className="col-2 p-0 pt-3"> <h3><span className="badge badge-pill badge-success">Active</span></h3> </div>*/} {/* badge-secondary */}
												<div className="col-8"></div>
											</div>



											<div className="row m-1 h-25">
												<span className="text-secondary"> Voucher Type: </span>    <span className="font-weight-bold ml-2">{" "} {packageType}</span>
												<span className="ml-3 text-secondary"> Creation Date: </span> <span className="font-weight-bold ml-2">{" "} {DateFormatter.formatDateToDisplay(creationDate)}</span>
											</div>

											<div className="card mt-3 p-0">


												{/* =================================================================================================== */}

												{success ?

													<ReactTable

														className=" table-striped custm-table"
														columns={voucherClos}
														data={partenerBatchVouchers.content}
														filterable
														pages={tableControlsObj.pages} // should default to -1 (which means we don't know how many pages we have)
														loading={tableControlsObj.loading}
														manual // informs React Table that you'll be handling sorting and pagination server-side
														onFetchData={(state, instance) => {
															//console.log(state)
															
															//this.setState({ loading: true })															
															//console.log('State ::::::::::: ',JSON.stringify(state,null,2))

															let filteredArr = state.filtered;
															if (filteredArr && filteredArr.length > 0) {
																let arr = [];
																for (let i = 0; i < filteredArr.length; i++) {
																	let colFilteredId = state.filtered[i].id
																	let colFilteredVal = state.filtered[i].value
																	//console.log('The colFilteredId is :'+colFilteredId + " , colFilteredVal: "+colFilteredVal);

																	arr.push({ name: colFilteredId, value: colFilteredVal });
																}
																//setListOfFilteredCriteria(arr);
																
																setTableControlsObj({...tableControlsObj,page:state.page,pageSize:state.pageSize});
																getBatchesVouchers(arr, batchId, state.page, tableControlsObj.pageSize);																
																
															}

															if (state.filtered[0] !== undefined) {
															}
															else {
																//console.log('OnFetchDataaaaaa NoFilter: page:'+tableControlsObj.page);
																
																setTableControlsObj({...tableControlsObj,page:state.page,pageSize:state.pageSize});
																getBatchesVouchers([], batchId,state.page, tableControlsObj.pageSize);
															}

														}}
														onFilteredChange={(filter) => {

															if (filter !== undefined) {
																//this.setState({ filtered: filter })
															}

														}}
													>
													</ReactTable>

													: ''}
												{/* =================================================================================================== */}

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}


//export default PartenerVouchers;
const mapStateToProps = state => ({
	// userPaymentProfile: state.userPaymentProfile.item
	partenerBatchVouchers: state.partenerBatchReducer.State_items,
	loading: state.partenerBatchReducer.State_loading,
	error: state.partenerBatchReducer.State_Partener_Batch_Get_Error,
	success: state.partenerBatchReducer.State_Partener_Batch_Get_sucessfully,
	totalPages:state.partenerBatchReducer.State_TotalPages,
});

//export default PartenerCards;
export default connect(mapStateToProps, { getBatchesVouchers })(withRouter(PartenerVouchers));