import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
    Dropdown, Input,
    Button
} from 'semantic-ui-react';
import { withFirebase } from '../../config'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Components */
import QRCCodeList from './QRCodeList'
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';
import VendorManagement from '../Layout/VendorManagement';

/* Actions */
import {
    generatePaymentCardList,
    generatePaymentCardListNew,
    generatePaymentCardListStampedOnImg,
    emptySecureRandomNumber,
    emptySecureRandomNumberItems,
    getDownloadLink,
    fetchAllQrImagesTemplates
} from '../../Actions/SecureRandomNumberActions'

import { fetchAllVendors } from '../../Actions/VendorActions'

const payment_package_type_list = [{
    key: 0,
    text: 'Monthly',
    value: 'Monthly'
},
{
    key: 1,
    text: 'Yearly',
    value: 'Yearly'
},
{
    key: 2,
    text: 'Weekly',
    value: 'Weekly'
}]

class QRcodesGenerator extends Component {
    state = {
        cardsNumber: 0,
        paymentPackageType: 'Monthly',
        vendorId: 0,
        qrTemplateName: '',
        stopShowLoad:'',
        qrTemplatesCardsArr:''
    }

    constructor(props) {
        super(props);

        this.props.emptySecureRandomNumberItems();
        this.props.fetchAllVendors();
        this.props.fetchAllQrImagesTemplates();
    }

    handlePaymentPackageType = (e, { value }) => {

        this.setState({
            paymentPackageType: value
        })
    }

    handleChange = (e) => {
        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }

    handleVendor = (e, { value }) => {
        //console.log("partner " + value);
        this.setState({ vendorId: value })
    }

    handleSubmit = (e) => {
	//alert('Handle submit');

        const { cardsNumber, paymentPackageType, vendorId } = this.state;

        this.props.generatePaymentCardList(cardsNumber, paymentPackageType, vendorId);

        e.preventDefault();
    }



    handleGenerateQrCodesInExcelBtn = (e) => {



		const { cardsNumber, paymentPackageType, vendorId ,qrTemplateName } = this.state;

        this.props.generatePaymentCardListNew(cardsNumber, paymentPackageType, vendorId , qrTemplateName);

        e.preventDefault();
    }


  //  handleGenerateQrCodesStampedOnImgBtn = (e) => {

  //      const { cardsNumber, paymentPackageType, vendorId,qrTemplateName } = this.state;

  //      this.props.generatePaymentCardListStampedOnImg(cardsNumber, paymentPackageType, vendorId,qrTemplateName);

  //      e.preventDefault();
  //  }

    getPaymentCardTemplatesList = (e) => {

		//alert('Start new Generate');

        this.props.getPaymentCardTemplatesList();

        e.preventDefault();
    }


    handleQrTemplateSelection = (e, { value }) => {
        //console.log("qrTemplateName " + value);
        this.setState({ qrTemplateName: value })
    }

        
    render() {

        const { randomNumbersList, loading, Success, Error, qrGenerationId, downloadLink,fileLocally,stopShowLoad } = this.props;

        //console.log('Props: '+JSON.stringify(this.props,null,3));


        const { vendor } = this.props;
        //console.log(vendor)

        const vendorList = [];
        for (let i = 0; i < vendor.length; i++) {
            vendorList.push({
                key: vendor[i].id,
                text: vendor[i].name,
                value: vendor[i].id
            })
        }

        const { qrTemplates } = this.props;
        //console.log('qrTemplates: '+qrTemplates)

        const qrTemplatesList = [];
        
        if(this.state['qrTemplatesCardsArr']!=null && this.state['qrTemplatesCardsArr'].length > 0)
        {
	        for (let i = 0; i < this.state['qrTemplatesCardsArr'].length; i++) 
	        {
	            qrTemplatesList.push({
	                key: qrTemplates[i],
	                text: qrTemplates[i],
	                value: qrTemplates[i]
	            })
	        }
	
		}
		else
		{
	        for (let i = 0; i < qrTemplates.length; i++) 
	        {
	            qrTemplatesList.push({
	                key: qrTemplates[i],
	                text: qrTemplates[i],
	                value: qrTemplates[i]
	            })
	        }			
	        
	        //this.setState({ qrTemplatesCardsArr: qrTemplates });
		}
        

        const validate = this.state.cardsNumber == 0 ||
            this.state.vendorId == 0;


        /*let button = (
            <Button
                id="nextButton" onClick={this.handleSubmit} disabled={validate} >
                Generate
            </Button>)*/
            
            /*id=qrCodesBtnId*/
            
        let generate_qrCodes_btn = ''

		//console.log('Loading now is :'+loading);
		
		if(loading === true)
		{
			generate_qrCodes_btn = ( <Button id="nextButton" className="ui loading button" > Loading </Button>)	
		}
		else
		{
			generate_qrCodes_btn = (
            <Button
                id="nextButton" onClick={this.handleGenerateQrCodesInExcelBtn} disabled={validate} > 
                Generate QrCodes New
            </Button>)
		}
		

        if (loading === true && qrGenerationId !== '' && qrGenerationId !== undefined ) {   //&& qrGenerationId !== "undefined"
         //console.log('stopShowLoad :'+stopShowLoad);   
        }
        else if (loading === true && !stopShowLoad) {
	/*
	console.log(' stopShowLoad :'+stopShowLoad);
	alert('not loading , stopShowLoad: '+stopShowLoad);
            button = (
                <button
                    id="qrCodesBtnId"
                    className="ui loading button">
                    Loading
                </button>
            );
            */
        }

//console.log('fileLocally :'+fileLocally);

		if(fileLocally !== '' && fileLocally !== undefined)
		{
			//window.open(downloadLink);
            //console.log(downloadLink);
            //this.props.emptySecureRandomNumber();
		}
        else if (Success === true) {
            window.open(downloadLink);
            // console.log(downloadLink);
            // alert("Data Generated Successfully ")
            this.props.emptySecureRandomNumber();
        }
        else if (Success === false) {

            alert(Error)
            this.props.emptySecureRandomNumber();
        }

        return (

            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">

                                        <div className="col-12 left-main-body"
                                            id="left-main-body">
                                            <VendorManagement />
                                            <div className="box-container">


                                                {/* <!--  start status bar --> */}
                                                {/* <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to="/">Home</Link><span> > </span>
                                                    </span>
                                                    <span>QR Codes Generator</span>

                                                </div> */}
                                                {/* <!--  end status bar --> */}

                                                <div className="col-12 left-main-body" id="left-main-body">

                                                </div>

                                                {/* <h5 className="main-title mt-2 font-weight-bold">QR Codes Generator</h5> */}


                                                <br />
                                                <div className="col-12 left-main-body" id="left-main-body">

                                                </div>
                                                <div className="card mt-3 p-0">
                                                    <div className="card-body p-4">

                                                        <br />
                                                        <label htmlFor="episode_number"
                                                            className="label">
                                                            <span className="required_input">*</span>
                                                            Cards Number</label>
                                                        <br />
                                                        <input
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid type="number" min="0"
                                                            name="cardsNumber" id="episode_number"
                                                            onChange={this.handleChange}
                                                            value={this.state.cardsNumber} />
                                                        <br />


                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            <span className="required_input">*</span>
                                                            Select payment package type
                                                        </label>
                                                        <br />

                                                        <Dropdown fluid selection
                                                            placeholder='Select Payment package type'
                                                            id="episode_series_id"
                                                            onChange={this.handlePaymentPackageType}
                                                            options={payment_package_type_list}
                                                            value={this.state.paymentPackageType}
                                                        />
                                                        <br />


                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            <span className="required_input">*</span>
                                                            Select Partner
                                                        </label>
                                                        <br />

                                                        <Dropdown fluid selection
                                                            placeholder='Select Partner'
                                                            id="episode_series_id"
                                                            onChange={this.handleVendor}
                                                            options={vendorList}

                                                        />

                                                        <label htmlFor="QrTemplate_id"
                                                            className="label">
                                                            <span className="required_input"></span>
                                                            Select QR Template Images
                                                        </label>
                                                        <br />

                                                        <Dropdown fluid selection
                                                            placeholder='Select QR Template'
                                                            id="QrTemplate_id"
                                                            onChange={this.handleQrTemplateSelection}
                                                            options={qrTemplatesList}

                                                        />
                                                        

                                                        <div className="buttons_container">

                                                            {/*button*/}
                                                            
                                                            {generate_qrCodes_btn}

															
                                                            <QRCCodeList items={/*randomNumbersList*/''} />
                                                            

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}


QRcodesGenerator.propTypes = { 
    generatePaymentCardList: propTypes.func.isRequired,
    generatePaymentCardListNew: propTypes.func.isRequired,
    generatePaymentCardListStampedOnImg: propTypes.func.isRequired,    
    getDownloadLink: propTypes.func.isRequired,
    emptySecureRandomNumber: propTypes.func.isRequired,
    emptySecureRandomNumberItems: propTypes.func.isRequired,
    fetchAllVendors: propTypes.func.isRequired,
    fetchAllQrImagesTemplates: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
    randomNumbersList: state.randomNumbers.items,
    loading: state.randomNumbers.loading,
    qrGenerationId: state.randomNumbers.qr_generation_id,
    Error: state.randomNumbers.error_get_secure_random_number,
    Success: state.randomNumbers.get_secure_random_number_successfully,
    downloadLink: state.randomNumbers.download_link,
    vendor: state.vendor.items,
    qrTemplates : state.randomNumbers.items

})

export default withFirebase
    (connect
        (mapStateToProps,
            {
                generatePaymentCardList,
                generatePaymentCardListNew,
                generatePaymentCardListStampedOnImg,
                emptySecureRandomNumber,
                getDownloadLink,
                emptySecureRandomNumberItems,
                fetchAllVendors,
                fetchAllQrImagesTemplates
            }
        )
        (QRcodesGenerator)
    );
