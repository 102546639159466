import React, { Component } from 'react';
import './../../../../src/css/group.css';


class AddGroupStep extends Component {
  render() {
    return (
      <div className="add-group-step">
        <h2 className="modal-title">Add Group</h2>

        <label htmlFor="groupName" className="modal-label">Group Name</label>
        <input
          type="text"
          id="groupName"
          className="modal-input"
          value={this.props.groupName}
          onChange={this.props.handleInputChange}
          placeholder="Enter group name"
        />
         {/* Display error message if groupName is empty */}
         {this.props.groupNameError && (
          <p className="error-message">Group Name is required.</p>
        )}

        <div className="toggle-container">
          <label htmlFor="groupStatus" className="modal-label">Group Status</label>
          <div className="switch-wrapper">
            <label className="switch">
              <input
                type="checkbox"
                id="groupStatus"
                checked={this.props.isActive}
                onChange={this.props.handleToggle}
              />
              <span className="slider"></span>
            </label>
            <span className="toggle-label">Active</span>
          </div>
        </div>

        <button className="next-btn" onClick={this.props.handleNextStep}>
          Next
        </button>
      </div>
    );
  }
}

export default AddGroupStep;
