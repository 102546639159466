import React from "react";
import GamesIcon from "../../images/portal/icons/games-library.svg";
import StoriesIcon from "../../images/portal/icons/stories-menu.svg";
import VideosIcon from "../../images/portal/icons/tv-time.svg";
import WorkshopIcon from "../../images/portal/icons/workshop.svg";

export const FeaturesList = [
  {
    icon: GamesIcon,
    title: "العاب",
    description:
      "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة",
  },
  {
    icon: StoriesIcon,
    title: "قصص",
    description:
      "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة",
  },
  {
    icon: WorkshopIcon,
    title: "رسم تلوين",
    description:
      "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة",
  },
  {
    icon: VideosIcon,
    title: "فيديوهات",
    description:
      "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة",
  },
];

export const HeaderData = {
  title: "عالم جيل الآمن في انتظار طفلك",
  descPart_1: "تطبيق جيل هو البديل الآمن الذي تحتاجه لأطفالك",
  descPart_2:
    "يحتوي جيل على أكثر من 1000 شخصية حصرية تحترم هويتنا وثقافتنا العربية الفريدة، في قرابة أكثر من 30 مسلسلًا ممتعًا، ومئات المقاطع والألعاب المتنوعة، والآمنة لطفلك.",
  descPart_3:
    "يثري التطبيق خيال طفلك، ويعزز لغته العربية الفصحى؛ من خلال محتوى مقدم بلغة سهلة ومعبرة. ",
  descPart_4:
    "يوفر التطبيق قسمًا خاصًّا للآباء «جسور» غنيًّا بالمحتوى التربوي الذي يقيم طفلك، ويمكنك من خلاله التحكم في وقت مشاهدته للمحتوى، مع تقديم تقارير لمتابعة نشاطه وأدائه خلال التطبيق، إضافة إلى العديد من البرامج والمقالات التي توضح قيم الحلقات المعروضة للطفل، مع نصائح خاصة بكل مرحلة، وكيفية التعامل مع صغارك فيها.",
  descPart_5:
    "تطبيق جيل مناسب وآمن للأطفال من سن 3 حتى 9 سنوات.- يتيح كل اشتراك حسابًا للوالدين و3 حسابات منفصلة للطفل",
  descPart_6: "......................",
  descPart_7: (
    <span>
      <a className="subsribe-now-color" href="#subscribe-now">
        اشترك الآن
      </a>{" "}
      واطمئن على أطفالك في عالم جيل المدهش
    </span>
  ),
};

export const googlePlayLink = 
  "https://play.google.com/store/apps/details?id=com.jeelapp.android"
export const appleAppLink   = 
  "https://apps.apple.com/us/app/jeel-%D8%AC%D9%8A%D9%84/id1489596085"
export const appGalleryLink ="https://appgallery.huawei.com/app/C103389579"
export const webAppLink = "https://web.jeelapp.com"
export const about = "https://jeelapp.com/#about"
export const feature = "https://jeelapp.com/#feature"
export const install = "https://jeelapp.com/#install"
export const content = "https://jeelapp.com/#content"
export const faq = "https://jeelapp.com/#faq"
export const questions = "https://jeelapp.com/jeel-questions/"
export const blog = "https://jeelapp.com/blog-jeel/"
export const subscribe = "https://jeelapp.com/blog-jeel/#"

//Social
export const jeel_youtube = "http://bit.ly/JeelYou"
export const jeel_intsagram = "http://bit.ly/JeelIns"
export const jeel_twitter = "http://bit.ly/Jeeltwi"
export const jeel_facebook= "https://fb.com/TheJeelApp"
export const jeel_linkedin= "https://www.linkedin.com/company/12987814/admin/"
export const contactUs = "https://jeelapp.com/contact-us-jeel/"

//Payment URL
export const MadaWidgetURL = "https://oppwa.com/v1/paymentWidgets.js"
export const Fawry_Return_Url = "https://pay.jeelapp.com/FawryResult"
export const Order_WebHook_Url=""

//Payment Method 
export const JeelMasterCard = "MasterCard"
export const JeelMADA = "MADA"
export const Fawry_Payment_Method = 'FAWRY'
export const E_Wallet_Payment_Method = 'E-WALLET'
export const JEEL_We = 'JEEL_We'
export const JEEL_Etisalat = 'JEEL_Etisalat'
export const Fawry_Payment_Method_Type = 'PayAtFawry'
export const E_Wallet_Payment_Method_Type = 'MWALLET'
export const JEEL_STC = 'JEEL_STC'
export const JEEL_MOBILY =  "JEEL_MOBILY";
export const JEEL_ZAIN =  "JEEL_ZAIN";
export const PRE_PAID_CARD = "PRE_PAID_CARD";
export const IRAQ_ZAIN =  "IRAQ_ZAIN";
export const LIBYA_MADAR =  "LIBYA_MADAR"; 
export const LIBYA_LIBYANA =  "LIBYA_LIBYANA"; 
export const QATAR_VODAPHONE =  "QATAR_VODAPHONE";  

//Telecom Price
export const JEEL_STC_PRICE = "2.3";
export const JEEL_MOBILY_PRICE = "1.0";
export const JEEL_ZAIN_PRICE = "1.0";
export const IRAQ_ZAIN_PRICE = "300";
export const LIBYA_DAILY_PRICE = "0.75";
export const LIBYA_WEEKLY_PRICE = "3";
export const QATAR_WEEKLY_PRICE = "1.0";
export const DEFAULT_PRICE = "47";
//Package Slug
export const SLUG_DAILY = "Daily";
export const SLUG_WEEKLY = "Weekly";
export const SLUG_MONTHLY = "Monthly";
export const SLUG_YEARLY = "Yearly";

//Package Type
export const DAILY_SUBSCRIPTION = "الاشتراك اليومي";
export const MONTHLY_SUBSCRIPTION = "الاشتراك شهري";
export const WEEKLY_SUBSCRIPTION = "الاشتراك الاسبوعى";
export const YEARLY_SUBSCRIPTION = "الاشتراك سنوي";


export const MERCHANT_CODE = "QC6TMCSj0e15fu5Urczqow==";
export const SECURE_KEY = "9576dcd778e44bb69ee711dfde4abe97";

//VdoCipher folders 
export const EPISODE_FOLDER_NAME = "vdocipher_episode_folder_id";
export const STORY_FOLDER_NAME = "vdocipher_story_folder_id";
export const SONG_FOLDER_NAME = "vdocipher_song_folder_id";
export const SERIES_FOLDER_NAME = "vdocipher_series_folder_id";
export const DISCOVER_FOLDER_NAME = "vdocipher_discover_folder_id";
export const NOTIFICATION_FOLDER_NAME = "vdocipher_inAppNotification_folder_id";

export const DISCOVER_FOLDER_NAME_WITH_MUSIC = "vdocipher_discoverWithMusic_folder_id";
export const DISCOVER_FOLDER_NAME_WITH_OUT_MUSIC = "vdocipher_discoverWithoutMusic_folder_id";
export const SONG_FOLDER_NAME_WITH_MUSIC = "vdocipher_songWithMusic_folder_id";
export const SONG_FOLDER_NAME_WITH_OUT_MUSIC = "vdocipher_songWithoutMusic_folder_id";
export const SERIES_FOLDER_NAME_WITH_MUSIC = "vdocipher_seriesWithMusic_folder_id";
export const SERIES_FOLDER_NAME_WITH_OUT_MUSIC = "vdocipher_seriesWithoutMusic_folder_id";
export const STORY_FOLDER_NAME_WITH_MUSIC = "vdocipher_storyWithMusic_folder_id";
export const STORY_FOLDER_NAME_WITH_OUT_MUSIC = "vdocipher_storyWithoutMusic_folder_id";

export const GENERAL_SETTING_FOLDER_NAME_WITH_MUSIC = "vdocipher_generalSettingWithMusic_folder_id";
export const GENERAL_SETTING_FOLDER_NAME_WITH_OUT_MUSIC = "vdocipher_generalSettingWithoutMusic_folder_id";

