import firebase from 'firebase/app';
import 'firebase/auth'

const config = {
    apiKey: "AIzaSyA5n_Hv9livSdbKGLuGJ_xga-K3z4iSf9A",
    authDomain: "jeel-df8a7.firebaseapp.com",
    databaseURL: "https://jeel-df8a7.firebaseio.com",
    projectId: "jeel-df8a7",
    storageBucket: "jeel-df8a7.appspot.com",
    messagingSenderId: "846403722503",
    appId: "1:846403722503:web:a6367f1c48b21dbfdb39a2",
    measurementId: "G-QCMM45E39K"

}

firebase.initializeApp(config);
export default firebase;