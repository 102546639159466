import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../../../css/ViewsDetailsView.css';

class ViewsDetailsViewData extends Component {
    constructor(props) {
        super(props);
        const locationState = props.location.state || {};

        this.state = {
            item: locationState.item || null,
            type: locationState.type || 'series',
        };
    }

    render() {
        const { item, type } = this.state;

        if (!item) {
            return <p>No details available. Please navigate through the main dashboard.</p>;
        }

        const title = type === 'series' || type === 'discover' ? (item.seriesName || 'Series View') : (item.radioName || 'Radio View');
        const totalDuration = item.number_of_watching || 0;

        const lastWatched = type === 'radio' ? item.channelsInRadio[0].lastWatchedChannel : item.episodesInSeries[0].lastWatchedEpisode;
        const mostWatched = type === 'radio' ? item.channelsInRadio[0].mostWatchedChannel : item.episodesInSeries[0].mostWatchedEpisode;

        return (
            <div className="views-details">

                <div className="view-title">
                    <h1>
                        {type === 'series' || type === 'discover'
                            ? `${item.seriesName || ''} Series View`
                            : `${item.radioName || ''} Radio View`}
                    </h1>
                </div>

                {/* Header Section */}
                <div className="header-det-view">
                    <img src={type === 'radio' ? item.radioImage : item.seriesImage} alt={title} className="series-image" />
                    <div className="series-info">
                        <h2>{title}</h2>
                        <p>Watch: <span className="highlight-text">{totalDuration} mins</span></p>
                    </div>
                </div>

                {/* Episodes/Channels Section */}
                <div className="episodes-section">
                    <h3>Episodes</h3>
                    <div className="summary">
                        <div className="summary-item">
                            <h4>Last Episode:</h4>
                            {lastWatched && (
                                <div className="episode-thumbnail">
                                    <img src={lastWatched.image} alt="Last Watched" />
                                    <p>{lastWatched.name || 'Unnamed Episode'}</p>
                                </div>
                            )}
                        </div>
                        <div className="summary-item">
                            <h4>Most Watched Episode:</h4>
                            {mostWatched && (
                                <div className="episode-thumbnail">
                                    <img src={mostWatched.image} alt="Most Watched" />
                                    <p>{mostWatched.name || 'Unnamed Episode'}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Episodes/Channels List */}
                <div className="episodes-list">
                    <h3>Series Episodes</h3>
                    <p className="total-duration">{totalDuration} Mins Watching</p>
                    <div className="episodes-container">
                        {type !== 'radio' && item.episodesInSeries[0].episodesReport.map((episode, index) => (
                            <div className="episode-item" key={index}>
                                <img src={episode.episodeImage} alt={episode.episodeName || 'Episode Image'} />
                                <div className="episode-details">
                                    <h4>{episode.episodeName || 'Unnamed Episode'}</h4>
                                    <p>Watching: {episode.number_of_watching} mins</p>
                                </div>
                                <div className="views-count">
                                    {episode.number_of_watching} Views
                                </div>
                            </div>
                        ))}

                        {type === 'radio' && item.channelsInRadio[0].radioChannelReport.map((channel, index) => (
                            <div className="episode-item" key={index}>
                                <img src={channel.channelImage} alt={channel.channelName || 'Channel Image'} />
                                <div className="episode-details">
                                    <h4>{channel.channelName || 'Unnamed Channel'}</h4>
                                    <p>Listen Time: {channel.number_of_watching} mins</p>
                                </div>
                                <div className="views-count">
                                    {channel.number_of_watching} Views
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ViewsDetailsViewData);
