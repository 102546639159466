import React, { Component } from 'react';
import './../../../../src/css/group.css';


class AssignStudentStep extends Component {
  render() {
    return (
      <div className="add-group-step">
        <h2 className="modal-title">Assign Student</h2>
        <input
          type="text"
          className="search-input"
          placeholder="type 3 letters to start searching"
          onChange={this.props.handleSearchChange}
        />

        <div className="student-list">
          {this.props.students.map((student, index) => (
            <div
              key={index}
              className={`student-item ${student.selected ? 'selected' : ''}`}
              onClick={() => this.props.toggleStudentSelection(index)}
            >
              <img src={student.profilePicture} alt="Student Avatar" className="student-avatar" />
              <div className="student-info">
                <p className="student-name">{student.name}</p>
                <p className="student-date">{student.joinDate}</p>
              </div>
              <div className="student-checkbox">
                <span className="checkmark"></span>
              </div>
            </div>
          ))}
        </div>

        <div className="step-buttons">
          <button className="assign-btn" onClick={this.props.handleAssign} disabled={this.props.loading}>
            Assign
          </button>
        </div>
      </div>
    );
  }
}

export default AssignStudentStep;
