import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../../../css/ActivityDetailView.css';

// Import icons
import totalViewsIcon from '../../../images/total-num-of-views.png';
import activitySolvingIcon from '../../../images/num-of-activity-solving.png';
import activityEvaluationIcon from '../../../images/activity-evaluation.png';
import educationalPapersIcon from '../../../images/educational-papers.png';
import devProgrammeIcon from '../../../images/dev-programme.png';

class ActivityDetailViewData extends Component {
    constructor(props) {
        super(props);
        const locationState = props.location.state || {};

        this.state = {
            activity: locationState.activity || {},
            type: locationState.type || '',
            startDate: locationState.startDate || '',
            endDate: locationState.endDate || '',
        };
    }

    render() {
        const { activity, type } = this.state;
        const { customActivityTrackingDTO, watchingReport } = activity;

        return (
            <div className="activity-detail-view">
                <h2 className="activity-title">
                    {type === 'songs' ? 'Song Activity' :
                     type === 'stories' ? 'Story Activity' :
                     type === 'series' ? 'Series Activity' : 'Learning & Skills Activity'} - {watchingReport.name}
                </h2>

                <div className="activity-content">
                    {/* Left Section: Activity Image */}
                    <div className="activity-image-container">
                        <img src={watchingReport.image} alt={watchingReport.name} className="main-activity-image"/>
                    </div>

                    {/* Right Section: Summary Boxes and More Info */}
                    <div className="activity-summary-and-info">
                        <div className="activity-summary-container">
                            <div className="summary-box">
                                <img src={totalViewsIcon} alt="Total number of views" className="summary-icon" />
                                <h4>Total number of views</h4>
                                <p className="summary-value">{watchingReport.number_of_watching || 0}</p>
                            </div>
                            <div className="summary-box">
                                <img src={activitySolvingIcon} alt="No. of Activity Solving" className="summary-icon" />
                                <h4>No. of Activity Solving</h4>
                                <p className="summary-value">{customActivityTrackingDTO.numberOfSolvingActivity || 0}</p>
                            </div>
                            <div className="summary-box">
                                <img src={activityEvaluationIcon} alt="Activity Evaluation" className="summary-icon" />
                                <h4>Activity Evaluation</h4>
                                <p className="summary-value evaluation-score">{customActivityTrackingDTO.avgScoreText || 'N/A'}</p>
                            </div>
                        </div>

                        {/* More Info Section */}
                        <div className="activity-info">
                            <h4>More Info</h4>
                            <p>{customActivityTrackingDTO.recommendation}</p>
                        </div>
                    </div>
                </div>

                <div className="educational-activity">
                    <h4>Educational activity</h4>
                    <p>An educational activity will help you communicate the value better to your child.</p>
                </div>

                {/* Educational Links Section */}
                <div className="educational-section">
                    <a href={customActivityTrackingDTO.file} target="_blank" rel="noopener noreferrer" className="educational-link">
                        <h4>Development Programme</h4>
                        <img src={devProgrammeIcon} alt="Development Programme" className="educational-icon" />
                    </a>
                    <a href={customActivityTrackingDTO.articleUrl} target="_blank" rel="noopener noreferrer" className="educational-link">
                        <h4>Educational Papers</h4>
                        <img src={educationalPapersIcon} alt="Educational Papers" className="educational-icon" />
                    </a>
                </div>
            </div>
        );
    }
}

export default withRouter(ActivityDetailViewData);
