import React, { Component } from 'react';
import './../../../../src/css/group.css';


class AssignToGroupModal extends Component {
  render() {
    const { isOpen } = this.props;
    if (!isOpen) return null;
    return (
        <div className="modal-overlay">
        <div className="modal-content">
          <button className="close-modal" onClick={this.props.onClose}>X</button>
      <div className="add-group-step">
        <h2 className="modal-title">Assign to Group</h2>
        <input
          type="text"
          className="search-input"
          placeholder="Enter Group Name"
          onChange={this.props.handleGroupSearchChange}
        />

        <div className="student-list">
          {this.props.groups.map((group, index) => (
            <div
              key={index}
              onClick={() => this.props.assignToGroup(group.id)}
            >
              <div className="student-info">
                <p className="student-name">{group.name}</p>
                <p className="student-date">{group.status}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      </div>
      </div>
    );
  }
}

export default AssignToGroupModal;
