//root reducer
import { combineReducers } from 'redux';
import SongReducer from './SongReducer';
import SongParentReducer from './SongParentReducer';
import BookParentReducer from './BookParentReducer';
import GameParentReducer from './GameParentReducer';
import QuestionCategoryReducer from './QuestionCategoryReducer';
import ActivityReducer from './ActivityReducer';
import QuestionReducer from './QuestionReducer';
import EpisodeReducer from './EpisodeReducer';
import SeriesReducer from './SeriesReducer';
import userReducer from './UserReducer'
import UserRolesReducer from './UserRolesReducer'
import StoryReducer from './StoryReducer'
import StoryParentReducer from './StoryParentReducer'
import StoryVideosReducer from './StoryVideosReducer'
import UserManagementReducer from './UserManagementReducer'
import RadioReducer from './RadioReducer'
import RadioChannelReducer from './RadioChannelReducer'
import ReadWithJeelReducer from './ReadWithJeelReducer'
import PdfBookReducer from './PdfBookReducer'
import PaymentDataReducer from './PaymentDataReducer';
import CountryReducer from './CountryReducer';

import { reducer as reduxFormReducer } from 'redux-form';
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'
import AuthReducer from './AuthReducer'
import BlogReducer from './BlogReducer'
import IssueReducer from './IssueReducer'
import CommentReducer from './CommentReducer'
import CustomerReducer from './CustomerReducer'
import BlogCategoryReducer from './BlogCategoryReducer';
import TermsAndConditionsReducer from './TermsAndConditionsReducer'
import FAQReducer from './FAQReducer'
import EvaluationReducer from './EvaluationReducer'
import DiscoverUsSeriesReducer from './DiscoverUsSeriesReducer';
import GeneralSettingReducer from './GeneralSettingReducer';
import KidsReportReducer from './KidsReportReducer';
import CustomeReportReducer from './CustomeReportReducer';
import EvaluationCategoryReducer from './EvaluationCategoryReducer';
import RedirectionPagesReducer from './RedirectionPagesReducer';
import StripePaymentReducer from './StripePaymentReducer';

import MobilePaymentHistoryReducer from './MobilePaymentHistoryReducer';
import MobilePaymentProfileReducer from './MobilePaymentProfileReducer';
import MobilePaymentReducer from './MobilePaymentReducer';

import ContentMainValuesReducer from './ContentMainValuesReducer';
import ContentSubValuesReducer from './ContentSubValuesReducer';

import TimeOfUseReducer from './TimeOfUseReducer';
import DurationOfUseReducer from './DurationOfUseReducer';

import FawryPaymentReducer from './FawryPaymentReducer';
import MobilePaymentPriceReducer from './MobilePaymentPriceReducer';
import PaymentCountriesReducer from './PaymentCountriesReducer';

import MobileSpecialCountriesReducer from './MobileSpecialCountriesReducer'

import SecureRandomNumberReducer from './SecureRandomNumberReducer'
import VendorReducer from './VendorReducer';
import GroupReducer from './GroupReducer';
import ContentVendorReducer from './ContentVendorReducer';

import VendorsReportReducer from './VendorsReportReducer'

import PromoCodeReducer from './PromoCodeReducer'

import DiscountTypeReducer from './DiscountTypeReducer'

import CouponTypeReducer from './CouponTypeReducer'

import PaymentMethodsReducer from './PaymentMethodsReducer'

import SubscriptionProfileReducer from './SubscriptionProfileReducer'

import SuccessMessagesReducer from './SuccessMessagesReducer';

import CurrencyReducer from './CurrencyReducer';

import PaymentPortalRedirectReducer from './PaymentPortalRedirectReducer';

import PrePaidCardPaymentReducer from './PrePaidCardPaymentReducer';
import InAppNotificationReducer from './InAppNotificationReducer'
import GameDataReducer from './GameDataReducer';

import TpayReducer from './TpayReducer';
import FitReducer from './FitReducer';

import HyperPayReducer from './HyperPayReducer';
import SubscriptionsReportReducer from './SubscriptionsReportReducer';
import FawryPayReducer from './FawryPayReducer';

import MwZainIraqReducer from './MwZainIraqReducer';
import MwBahrainReducer from '../portal/mwBahrain/MwBahrainReducer';
import MwQatarReducer from '../portal/mwQatar/MwQatarReducer';
import BannerReducer from './BannerReducer'
import ParentLibraryReducer from './ParentLibraryReducer';

import VideoCipherReducer from './VideoCipherReducer';
import CountrySubscriptionReportReducer from './CountrySubscriptionReportReducer';
import CountryCountSubscriptionsReportReducer from './CountryCountSubscriptionsReportReducer';

import DynamicSectionReducer from './DynamicSectionReducer';
import SettingReducer from './SettingReducer';

import PartenerBatchReducer from './PartenerBatchReducer';
import studentActivityReducer from './StudentActivityReducer';


export default combineReducers(
    {
        studentActivity: studentActivityReducer,
        songs: SongReducer,
        songParent: SongParentReducer,
        bookParent: BookParentReducer,
        gameParent: GameParentReducer,
        questionCategories: QuestionCategoryReducer,
        user: userReducer,
        userRole: UserRolesReducer,
        activities: ActivityReducer,
        questions: QuestionReducer,
        episodes: EpisodeReducer,
        series: SeriesReducer,
        stories: StoryReducer,
        storyParent: StoryParentReducer,
        storyVideos: StoryVideosReducer,
        form: reduxFormReducer,
        firestore: firestoreReducer,
        firebase: firebaseReducer,
        authUser: AuthReducer,
        admins: UserManagementReducer,
        blogs: BlogReducer,
        issues: IssueReducer,
        comments: CommentReducer,
        customers: CustomerReducer,
        blogCategories: BlogCategoryReducer,
        termsandconditions: TermsAndConditionsReducer,
        faq: FAQReducer,
        evaluation: EvaluationReducer,
        evaluationCategory: EvaluationCategoryReducer,
        discoverUsSeries: DiscoverUsSeriesReducer,
        generalSetting: GeneralSettingReducer,
        kidsReport: KidsReportReducer,
        customeReport: CustomeReportReducer,
        redirectionPage: RedirectionPagesReducer,
        stripePayment: StripePaymentReducer,

        userPaymentHistory: MobilePaymentHistoryReducer,
        userPaymentProfile: MobilePaymentProfileReducer,
        mobilePaymentPackage: MobilePaymentReducer,
        paymentCountriesPackage: PaymentCountriesReducer,
        contentMainValue: ContentMainValuesReducer,
        ContentSubValue: ContentSubValuesReducer,

        timeOfUse: TimeOfUseReducer,
        durationOfUse: DurationOfUseReducer,
        fawryPaymentPrices: FawryPaymentReducer,
        mobilePaymentPrice: MobilePaymentPriceReducer,
        specialCountriesPrice: MobileSpecialCountriesReducer,
        randomNumbers: SecureRandomNumberReducer,

        vendor: VendorReducer,
        group: GroupReducer,
        contentVendor: ContentVendorReducer,
        vendorsReport: VendorsReportReducer,        
        //partenersReport: PartenersReportReducer,
        
        partenerBatchReducer: PartenerBatchReducer, 

        promoCodes: PromoCodeReducer,

        discountTypes: DiscountTypeReducer,

        couponTypes: CouponTypeReducer,

        paymentMethods: PaymentMethodsReducer,

        subscriptionProfile: SubscriptionProfileReducer,

        successMessages: SuccessMessagesReducer,

        currencyRate: CurrencyReducer,

        fawryPaymentRedirect: PaymentPortalRedirectReducer,

        prepaidCard: PrePaidCardPaymentReducer,
        radio: RadioReducer,
        radioChannel: RadioChannelReducer,
        readWithJeel: ReadWithJeelReducer,
        pdfBook: PdfBookReducer,
        inAppNotification: InAppNotificationReducer,
        gameData: GameDataReducer,

        tpay: TpayReducer,
        fit: FitReducer,
        mwZainIraqReducer: MwZainIraqReducer,
        mwBahrainReducer:MwBahrainReducer,
        mwQatarReducer:MwQatarReducer,
        paymentData:PaymentDataReducer,
        countryName:CountryReducer,
        hyperPay: HyperPayReducer,
        subscriptionsReport: SubscriptionsReportReducer,
        countrySubscriptionReport: CountrySubscriptionReportReducer,
        countryCountSubscriptionReport: CountryCountSubscriptionsReportReducer,
        settings:SettingReducer,
        fawryPay:FawryPayReducer,
        vdoCipher: VideoCipherReducer,
        banners: BannerReducer,
        parentLibrary : ParentLibraryReducer,
        dynamicSection: DynamicSectionReducer,
    }
);
