
import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Link, NavLink, withRouter } from "react-router-dom";


import { connect } from 'react-redux';
import firebase from 'firebase/app';

/*Navbar*/
import OuterHeader from '../../Layout/OuterHeader';
import InnerHeader from '../../Layout/InnerHeader';
import Dashboard from '../../Dashboard';
import Footer from '../../Layout/Footer';
import '../../../css/partener.css';
import '../../../css/AppCss/dropzone.css'
import uploadImg from '../../../images/upload.png';
import { FileUploader } from "./FileUploader";

import  Pagination from './Pagination';
import PartenerBatches from './PartenerBatch';
import {getPartenerBatches,uploadPartenerBatchAssignment,emptyPartenerBatchAssignment} from './PartenerBatchActions';

import { useLocation } from 'react-router-dom'   

import { useState } from "react";
import { useEffect } from 'react';
import {getFirebase} from 'react-redux-firebase';
import { get,post } from 'axios';
import axios from 'axios'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Partener_Vouchers } from '../../../routes';



 const PartenerAssignation = ({ uploadPartenerBatchAssignment,emptyPartenerBatchAssignment,loading,success,error,emptyAlert,assignErrors }) => {
	
	//console.log('assignErrors :'+JSON.stringify(assignErrors,null,2));

		
	
	
	
let location = useLocation();
const [batchId, setBatchId] = useState(location.state.batchId)
const [file, setFile] = useState(null);
const [fileName, setFileName] = useState('');

//const params = new URLSearchParams(location.search);
//console.log('params :'+params)
//const batchId2 = params.get('batchId');



//console.log('batchId is : '+batchId);
//console.log('file is : '+file);          
// =======================================================================================================================================================        
useEffect(() => {    
    //console.log('empty after :'+emptyAlert);
    //if(!success || !error )
    	emptyPartenerBatchAssignment();
    
  }, []);  
  
    
// =======================================================================================================================================================
const downloadExcelFile = () => {
    // Define the header row
    const headers = [
        ["First Name", "Last Name", "Email", "password(Optional)", "Country"]
    ];

    // Create a new workbook and add a sheet
    const worksheet = XLSX.utils.aoa_to_sheet(headers);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to a binary string
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Create a Blob from the binary string and download it
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "UserTemplate.xlsx");
};
  const uploadAssignment = ()=>
  {
		//console.log('upload Clicked :'+batchId);
	
		if(file){	
			uploadPartenerBatchAssignment(file,batchId);
			setFile(null);
		}else{
			alert('Please Choose a file to Upload');
		}
			
  }


	const onFileChange = event => {
        //this.setState({ selectedFile: event.target.files[0] });
        //this.setState({ selectedFileName: event.target.files[0].name });
        
        //console.log('File is selected : '+event.target.files[0]);
        //console.log('BatchId after file :'+batchId);
        
        setFile(event.target.files[0]);
        
    };
  
  const handleFileSelection = (file) => {    
    setFile(file);
    setFileName(file.name);
  };
  
  



/*
 {<div className="assign-file-upload">	
				              <img src={uploadImg} width="40px" height="40px" />
				              <h3>Click box to upload</h3>
				              <p>Maximun file size 10mb</p>
				              <input type="file" onChange={onFileChange} />
			              </div>}
*/

/* =====================================================================================================================================================*/
/* =====================================================================================================================================================*/
/* =====================================================================================================================================================*/


        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                           
                                            <div className="box-container">                                                
                                                

                                                <h4 className="main-title mt-2 font-weight-bold pl-1"> Upload Assignment File </h4>
                                                
                                                
                                                <div className ="row">
                                                	<div className="form-group">
					                                    <label className="mr-5  ml-3">Download Template</label>
					                                    <button onClick={()=>downloadExcelFile()}  id="nextButton">Download</button>
					                                </div>
					                                
                                                </div>   
					<div className ="row">
						<div className="form-group">
	
	            
	            	  {loading ?  
				      	<h2>Uploading......</h2>
				      : 
				        <>
				        	<label className="mr-5 ml-3">Select File</label>  
				      		<FileUploader handleFile={handleFileSelection} /> 
				      		<p className="ml-3 text-primary">{fileName}</p> 
				      		<br/><br/><br/>
				      						      			
				      		<button id="nextButton" className="ml-3" onClick={uploadAssignment} >Upload</button>
				      			
				      	</> 
		              }
	            
						</div>
					</div>                                                
                                                
                                                
                                                
                                                {success ?
	                                                <div className="row m-5">
	                                                	<div className="col-3"></div> 
	                                                	<div className="col-6 text-success"> <h3> File uploaded Successfully </h3> </div>  
	                                                </div>
                                                :''}
                                                
                                                {error ?
	                                                <div className="row m-5">
	                                                	<div className="col-3"></div> 
	                                                	{assignErrors?
	                                                		assignErrors.map((error, index) => (   
	                                                			<div className="col-6 text-danger" key="index">  {error?JSON.stringify(error):''}  </div>
	                                                	)):''}  
	                                                </div>
                                                :''}                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )        
}

const mapStateToProps = state => ({
    // userPaymentProfile: state.userPaymentProfile.item
    loading: state.partenerBatchReducer.State_Assign_loading,    
    
    error: state.partenerBatchReducer.State_Assign_Error,
    success: state.partenerBatchReducer.State_Assign_sucessfully,
    emptyAlert: state.partenerBatchReducer.State_Assign_empty,
    assignErrors: state.partenerBatchReducer.State_Assign_Error_List,
    
});

//export default PartenerCards;
//export default withRouter (connect (mapStateToProps, { getPartenerBatches }) (PartenerBatch));

export default connect( mapStateToProps, { uploadPartenerBatchAssignment,emptyPartenerBatchAssignment } )(withRouter(PartenerAssignation));
    
