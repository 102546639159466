import React, { Component } from 'react';
import './../../../../src/css/group.css';

class DeleteGroupModal extends Component {
    render() {
        const { groupName, onClose, onDelete } = this.props;
console.log(groupName);
        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <button className="close-modal" onClick={onClose}>X</button>
                    <h2 className="modal-title">Delete Group</h2>                    
                    <p className="modal-text">
                        You are deleting the group <span className="group-name">{groupName}</span> here, 
                        the group students will be removed. This action cannot be undone.
                    </p>

                    <button className="delete-btn" onClick={onDelete}>Delete</button>
                </div>
            </div>
        );
    }
}

export default DeleteGroupModal;
