import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import ZenDeskChat from "@goranefbl/react-zendesk-chat";
import { sha256 } from "js-sha256";
/*CSS */
import "../../css/AppCss/MobilePayment/payment.css";
import "../../css/AppCss/MobilePayment/main-mQuery.css";
/* images */
import logo from "../../images/logo.png";
/*Actions */
import { saveFawryOrder } from "../../Promises/FawryOrders";
import { addPaymentData } from "../../Actions/MobilePaymentActions";
import { cancelRenew } from "../../Actions/StripePaymentAction";
import { addSeriesIDtoRedirectionPage } from "../../Actions/RedirectionPagesActions.js";
import {
  getUserData,
  emptyMobileUserProfile,
  emptyMobileUserProfileWithItem,
} from "../../Actions/MobilePaymentProfileAction";
import { addTokenToredux, emptyToken } from "../../Actions/loginActions.js";
import { getUserInfo } from "../../Promises/UserData.js";
import { getFawryPrices } from "../../Actions/FawryPaymentAction";
/*Components */
import MobilePaymentFooter from "../StripePayment/MobilePaymentFooter";
import Loader from "../Layout/Loader";
/*Redux */
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import propTypes from "prop-types";

/*consts*/
var merchantCode = "QC6TMCSj0e15fu5Urczqow==";
var securekey = "9576dcd778e44bb69ee711dfde4abe97";
var fawryUrl = "http://fawry.jeelpay.com";

//'http://localhost/app/src/components/Fawry/fawry.html';

var today = new Date();

function addMonths(date, months) {
  var d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() != d) {
    date.setDate(0);
  }
  return date;
}

function expirationDateFormate(date, type, expirationDate) {
  var expiDate;
  var dd;
  var mm;
  var yyyy;

  if (
    expirationDate == null ||
    expirationDate == undefined ||
    expirationDate == "null"
  ) {
    expiDate = new Date();
  } else {
    let arrayOfDate = expirationDate.split("-");

    dd = arrayOfDate[0];

    mm = arrayOfDate[1];

    yyyy = arrayOfDate[2];

    var formatedEpxDate = mm + "/" + dd + "/" + yyyy;
    var oldDate = new Date(formatedEpxDate);

    if (today > oldDate) {
      expiDate = new Date();
    } else {
      // Subtract 1 from month to convert it to system ( January is 0!)
      expiDate = new Date(yyyy, mm - 1, dd, 0, 0, 0, 0);
    }
  }

  if (type == "Month") {
    expiDate = addMonths(expiDate, 1);
  } else {
    expiDate = addMonths(expiDate, 12);
  }
  // add 1 to month to convert it to general ( January is 1!)
  var tempMonth = expiDate.getMonth();
  var tempMonth = tempMonth + 1;

  date = expiDate.getDate() + "-" + tempMonth + "-" + expiDate.getFullYear();
  return date;
}

const INITIAL_STATE = {
  errorPage: false,
  isYearActive: false,
  token: "",
  fawryRedirection: false,
  fawryOrderId: 0,
  cash: 0,
  expirationDate: null,
};

class FawryMobilePayment extends Component {
  constructor(props) {
    console.log("Fawry Mobile Payment Constructor");
    super(props);
    this.state = { ...INITIAL_STATE };

    this.props.emptyToken();

    let token = this.props.match.params.token;
    this.props.addTokenToredux(token);

    getUserInfo(token)
      .then((res) => {
        this.setState({ token: token });

        if (
          res.data.currentPackagePaymentId !== null &&
          res.data.currentPackagePaymentId !== undefined
        ) {
          if (res.data.currentPackagePaymentId.expirationDatetime) {
            this.setState({
              expirationDate:
                res.data.currentPackagePaymentId.expirationDatetime,
            });
          }
        }

        this.props.getFawryPrices(res.data.country, "FAWRY", token);
      })
      .catch((Error) => {
        this.setState({ errorPage: true });
      });
  }

  handlePay = (cash, paymentType, e) => {
    const { token } = this.props;

    saveFawryOrder(paymentType, token, cash)
      .then((res) => {
        this.setState({
          fawryRedirection: true,
          fawryOrderId: res.data.id,
          cash: cash,
        });
      })
      .catch((Error) => {
        this.setState({ errorPage: true });
      });
  };

  activetheYear = () => this.setState({ isYearActive: true });

  deactivetheYear = () => this.setState({ isYearActive: false });

  render() {
    const { error, loading } = this.props;

    if (this.state.errorPage === true || error !== "") {
      return <Redirect to={"/FawryFailure"}> </Redirect>;
    }
    const { userPaymentProfile } = this.props;

    if (
      this.state.fawryRedirection == true &&
      this.state.fawryOrderId !== 0 &&
      this.state.cash !== 0
    ) {
      var cashRounded = Math.round(this.state.cash);
      var cashAsFloat = cashRounded + ".00";
      var hashData =
        merchantCode +
        this.state.fawryOrderId +
        "122221" +
        cashAsFloat +
        securekey;

      var signature = sha256(hashData);

      let phone = userPaymentProfile.phone;
      if (!userPaymentProfile.phone.startsWith(0)) {
        phone = "0" + userPaymentProfile.phone;
      }

      window.location.assign(
        fawryUrl +
          "?" +
          "orderId=" +
          this.state.fawryOrderId +
          "&name=" +
          userPaymentProfile.name +
          "&email=" +
          userPaymentProfile.email +
          "&uid=" +
          userPaymentProfile.uid +
          "&phone=" +
          phone +
          "&cash=" +
          cashRounded +
          "&signature=" +
          signature
      );
    }

    const { isYearActive } = this.state;

    let calcExpirationDateMonth = expirationDateFormate(
      today,
      "Month",
      this.state.expirationDate
    );
    let calcExpirationDateYear = expirationDateFormate(
      today,
      "Year",
      this.state.expirationDate
    );

    const { fawryPaymentPackage } = this.props;

    let yearContent = "";
    let monthContent = "";
    let monthActive = "";
    let yearActive = "";

    if (loading === false && error === "") {
      monthContent = (
        <div id="tab02" className="tab-contents">
          <div className="  card-body">
            <p className="font">استمتع بمحتوى جيل لمدة شهر كامل لثلاثة أطفال</p>

            <div className="  div-box mb-3">
              <div className="  row">
                <div className="  col-12 text-center">
                  <div className=" font special-offer p-2 mb-4">خصم خاص</div>
                  <div className="  Pkg-price">
                    <div className=" font small-font text-right">
                      سعر الباقة
                    </div>
                    <div className=" font price-value h1 m-0 eng-lang">
                      {fawryPaymentPackage.monthDiscountPrice}
                      <span className=" font small-font h1 text-right">
                        جنيه
                      </span>
                    </div>
                  </div>
                  <p className=" font Pkg-hint small-font p-2">
                    إضافة حتى 3 أطفال
                    <br />
                    محتوى تربوي ممتع
                    <br />
                    تقارير تفصيلية مستقلة لكل طفل
                    <br />
                    أنشطة تربوية بعد كل حلقة
                    <br />
                    الاستفادة بالقسم التربوي للآباء (جسور)
                  </p>
                  <p className="  danger-color small-font m-0">
                    <span className="font">تنتهي في</span>
                    <span className=" font danger-color eng-lang">
                      {calcExpirationDateMonth}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <button
              className=" font btn btn-lg btn-primary btn-block btn-signin mt-2"
              type="submit"
              onClick={this.handlePay.bind(
                this,
                fawryPaymentPackage.monthDiscountPrice,
                "Monthly"
              )}
            >
              اشترك الآن
            </button>
            <br />
            <Link id="livechat-open" className="h6 mt-3 mb-0 font">
              لديك مشكلة؟
            </Link>
          </div>
          <ZenDeskChat
            onlineMsg="Live Chat Support"
            offlineMsg="Leave us a message"
            appID="rVsdP33FK31Q8eZxdFwegHbpDOk2rzMd"
            buttonID="livechat-open"
          />
        </div>
      );

      yearContent = (
        <div id="tab01" className="tab-contents">
          <div className="  card-body">
            <p className="font">
              استمتع بكل محتوى جيل لمدة عام كامل لثلاثة أطفال
            </p>
            <div className="  div-box mb-3">
              <div className="  row">
                <div className="  col-12 text-center">
                  <div className=" font special-offer p-2 mb-4">خصم خاص</div>
                  <div className="  Pkg-price">
                    <div className=" font small-font text-right">
                      سعر الباقة
                    </div>
                    <div className=" font price-value h1 m-0 eng-lang">
                      {fawryPaymentPackage.yearDiscountPrice}
                      <span className=" font small-font h1 text-right">
                        جنيه
                      </span>
                    </div>
                  </div>
                  <p className=" font Pkg-hint small-font p-2">
                    إضافة حتى 3 أطفال
                    <br />
                    محتوى تربوي ممتع
                    <br />
                    تقارير تفصيلية مستقلة لكل طفل
                    <br />
                    أنشطة تربوية بعد كل حلقة
                    <br />
                    الاستفادة بالقسم التربوي للآباء (جسور)
                  </p>
                  <p className=" font danger-color small-font m-0">
                    <span className="font">تنتهي في</span>
                    <span className=" font danger-color eng-lang">
                      {calcExpirationDateYear}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <button
              className=" font btn btn-lg btn-primary btn-block btn-signin mt-2"
              type="submit"
              onClick={this.handlePay.bind(
                this,
                fawryPaymentPackage.yearDiscountPrice,
                "Yearly"
              )}
            >
              اشترك الآن
            </button>
            <br />
            <Link id="livechat-open" className="h6 mt-3 mb-0 font">
              لديك مشكلة؟
            </Link>
            <ZenDeskChat
              onlineMsg="Live Chat Support"
              offlineMsg="Leave us a message"
              appID="rVsdP33FK31Q8eZxdFwegHbpDOk2rzMd"
              buttonID="livechat-open"
            />
          </div>
        </div>
      );
    }

    yearActive = (
      <div className="tabs card card-container Current-Package mb-4 ">
        <br />
        <br />
        <span className="h4 font" style={{ paddingRight: "30px" }}>
          {" "}
          مرحبا &nbsp; {userPaymentProfile.name}
        </span>
        <div className="tab-button-outer">
          <ul id="tab-button">
            <li>
              <a>
                <div
                  className="  card-header profile-name-card"
                  onClick={this.deactivetheYear}
                >
                  <div className=" font h4">الباقة السنوية</div>
                </div>
              </a>
            </li>

            <li className="is-active">
              <a>
                <div
                  className="card-header profile-name-card"
                  onClick={this.activetheYear}
                >
                  <div className=" font h4">الباقة الشهرية</div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        {monthContent}
      </div>
    );

    monthActive = (
      <div className="tabs card card-container Current-Package mb-4 ">
        <br />
        <br />
        <span className="h4 font" style={{ paddingRight: "30px" }}>
          {" "}
          مرحبا &nbsp; {userPaymentProfile.name}
        </span>
        <div className="tab-button-outer">
          <ul id="tab-button">
            <li className="is-active">
              <a>
                <div
                  className="  card-header profile-name-card"
                  onClick={this.deactivetheYear}
                >
                  <div className=" font h4">الباقة السنوية</div>
                </div>
              </a>
            </li>

            <li>
              <a>
                <div
                  className="  card-header profile-name-card"
                  onClick={this.activetheYear}
                >
                  <div className=" font h4">الباقة الشهرية</div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        {yearContent}
      </div>
    );

    let content = "";
    if (loading === true) {
      content = <Loader />;
    } else {
      if (isYearActive == true) {
        content = <span>{yearActive}</span>;
      } else {
        content = <span>{monthActive}</span>;
      }
    }

    return (
      <div className="main-payment">
        <div className="shape-container">
          <div className=" bg-img"></div>
        </div>

        <div className="container leftToRight">
          <div className="pay-box">
            <div className="  main-logo text-center h4">
              <img className="  logo" alt="logo" src={logo} />
              <p className=" font  small-font main-color ">بوابة الدفع لجيل</p>
            </div>
            <div className="  h5 text-center mb-4 mt-4 font">
              من فضلك قم بالاشتراك فى احدى الخطط التالية
            </div>

            <div className="  row justify-content-md-center Plans-box">
              {content}
            </div>
          </div>

          <MobilePaymentFooter />
        </div>
      </div>
    );
  }
}

FawryMobilePayment.propTypes = {
  addPaymentData: propTypes.func.isRequired,
  addSeriesIDtoRedirectionPage: propTypes.func.isRequired,
  getUserData: propTypes.func.isRequired,
  emptyMobileUserProfile: propTypes.func.isRequired,
  addTokenToredux: propTypes.func.isRequired,
  getFawryPrices: propTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  fawryPaymentPackage: state.fawryPaymentPrices.item,
  loading: state.fawryPaymentPrices.loading,
  error: state.fawryPaymentPrices.getting_fawry_prices_error,
  sucess: state.fawryPaymentPrices.getting_fawry_prices_sucessfully,
  userPaymentProfile: state.userPaymentProfile.item,
  token: state.user.token,
});

export default withRouter(
  connect(mapStateToProps, {
    addPaymentData,
    cancelRenew,
    addSeriesIDtoRedirectionPage,
    getUserData,
    emptyToken,
    addTokenToredux,
    emptyMobileUserProfile,
    emptyMobileUserProfileWithItem,
    getFawryPrices,
  })(FawryMobilePayment)
);
