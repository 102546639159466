// src/utils/DateFormatter.js

class DateFormatter {

    static formatDateToDisplay(dateString) {
        const date = new Date(dateString);
        
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }
}

export default DateFormatter;
