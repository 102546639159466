import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchGroupMembers, fetchGroupDashboard,editGroupNameAndStatus } from '../../../Actions/GroupActions'; // Adjust the path based on your structure
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popup,Button, Dropdown } from 'semantic-ui-react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import '../../../css/GroupMembers.css';
import ContentSection from '../Dashboard/ContentSection';
import {VIEW_CUSTOMER} from '../../../routes.js'
import EditGroupModal from './EditGroupModal';

class GroupMembersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 'members',
            selectedDateRange: 'Last Month',
            totalPages: 0,
            pageSize: 5,
            page: 0,
            childName: '',
            parentEmail: '',
            debounceTimeout: null,
            loading: false,
            pieData: [],
            series: [],
            videos: [],
            radioChannels: [],
            games: [],
            isEditModalOpen: false,
            groupName: '',
            groupStatus: 'Inactive',
        };
    }

    componentDidMount() {
        this.fetchMembers();
        this.fetchGroupDashboard();

        const groupDetails = this.props.location.state && this.props.location.state.group;

        if (groupDetails) {
            this.setState({
                groupName: groupDetails.name || '',
                groupStatus: groupDetails.status || 'Inactive',
            });
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.dashboardData !== this.props.dashboardData && this.props.dashboardData) {
            const { totalTimeOfVideos, totalTimeOfRadioChannels, totalTimeOfGames, series, videos, radioChannels, games } = this.props.dashboardData;

            const pieData = [
                { name: 'Videos', minutes: totalTimeOfVideos, color: '#aa54e0' },
                { name: 'Games', minutes: totalTimeOfGames, color: '#51dc8a' },
                { name: 'Radio Channels', minutes: totalTimeOfRadioChannels, color: '#48bfd6' },
            ];

            this.setState({
                pieData,
                series,
                videos,
                radioChannels,
                games
            });
        }
    }
    debounce = (func, delay) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), delay);
        };
    };

    // Calculate startDate and endDate based on selectedDateRange
    calculateDateRange = (selectedDateRange) => {
        const now = new Date();
        let startDate, endDate;

        switch (selectedDateRange) {
            case 'Last Week':
                startDate = new Date(now.setDate(now.getDate() - 7));
                endDate = new Date();
                break;
            case 'Last Month':
                startDate = new Date(now.setMonth(now.getMonth() - 1));
                endDate = new Date();
                break;
            case 'Last Year':
                startDate = new Date(now.setFullYear(now.getFullYear() - 1));
                endDate = new Date();
                break;
            default:
                startDate = new Date(now.setMonth(now.getMonth() - 1));
                endDate = new Date();
        }

        // Format dates to 'yyyy-MM-dd'
        startDate = startDate.toISOString().split('T')[0];
        endDate = endDate.toISOString().split('T')[0];

        return { startDate, endDate };
    };

    fetchMembers = () => {
        const { selectedDateRange, page, pageSize, childName, parentEmail } = this.state;
        const groupId = this.props.match.params.id;
        const { startDate, endDate } = this.calculateDateRange(selectedDateRange);

        this.props.fetchGroupMembers(startDate, endDate, groupId, page, pageSize, childName, parentEmail)
            .then((response) => {
                console.log(response.totalPages);
                if (response && response.totalPages) {
                    this.setState({
                        totalPages: response.totalPages, 
                    });
                }
            });
    };
fetchGroupDashboard = () => {
    const groupId = this.props.match.params.id;
    this.props.fetchGroupDashboard(groupId)
        .then((response) => {
            console.log(response);
        });
}
    // Handle tab change
    handleTabChange = (tab) => {
        this.setState({ selectedTab: tab });
    };

    // Handle date range change and reload members
    handleDateRangeChange = (e, { value }) => {
        this.setState({ selectedDateRange: value, page: 0 }, this.fetchMembers);
    };

    // Handle page change
    handlePageChange = (pageIndex) => {
        this.setState({ page: pageIndex }, this.fetchMembers);
    };

    // Handle page size change
    handlePageSizeChange = (size, pageIndex) => {
        this.setState({ pageSize: size, page: pageIndex }, this.fetchMembers);
    };

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, this.fetchMembers);
    };


    debouncedFetchMembers = this.debounce(this.fetchMembers, 300);


    handleFilterChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        this.debouncedFetchMembers();
    };

    openEditModal = () => {
        this.setState({
            isEditModalOpen: true,
            groupName: this.state.groupName, 
            groupStatus: this.state.groupStatus, 
        });
    };
    

    closeEditModal = () => {
        this.setState({ isEditModalOpen: false });
    };

    handleEditGroup = () => {
        const groupId = this.props.match.params.id;
        const { groupName, groupStatus } = this.state;
    
        this.props
            .editGroupNameAndStatus(groupId, { name: groupName, status: groupStatus })
            .then((updatedGroup) => {
                alert('Group updated successfully!');
                this.setState({
                    isEditModalOpen: false,
                    groupName: updatedGroup.name,
                    groupStatus: updatedGroup.status,
                });
            })
            .catch((err) => {
                alert('Failed to update group: ' + err.message);
            });
    };
    



    render() {
        const { selectedTab, selectedDateRange, pageSize, page, childName, parentEmail, totalPages,groupName,groupStatus,isEditModalOpen } = this.state;
        const { members, loading, error } = this.props;

        const dateOptions = [
            { key: 'lastMonth', text: 'Last Month', value: 'Last Month' },
            { key: 'lastWeek', text: 'Last Week', value: 'Last Week' },
            { key: 'lastYear', text: 'Last Year', value: 'Last Year' },
        ];

        const columns = [
            {
                Header: "Child Name",
                accessor: "childName",
                headerClassName: 'table-header',
                Filter: () => (
                    <input
                        type="text"
                        name="childName"
                        value={childName}
                        placeholder=""
                        onChange={this.handleFilterChange}
                        style={{ width: '100%', height: '80%' }}
                    />
                ),
                Cell: (props) => (
                    <div className="name-cell">
                        <img
                            src={props.original.profileImageLink}
                            alt="profile"
                            className="profile-image"
                        />
                        <span>{props.original.name}</span>
                    </div>
                ),
            },
            {
                Header: "Gender",
                accessor: "gender",
                width: 100,
                headerClassName: 'table-header',
                filterable: false, 
                Cell: props => (
                    <div style={{ textAlign: "center" }}>{props.original.gender}</div>
                ),
            },
            {
                Header: "Age",
                accessor: "age",
                width: 70,
                headerClassName: 'table-header',
                filterable: false, 
                Cell: props => (
                    <div style={{ textAlign: "center" }}>{props.original.age}</div>
                ),
            },
            {
                Header: "Parent Email",
                accessor: "parentEmail",
                headerClassName: 'table-header',
                Filter: () => (
                    <input
                        type="text"
                        placeholder=""
                        name="parentEmail"
                        value={parentEmail}
                        onChange={this.handleFilterChange}
                        style={{ width: '100%', height: '80%' }}
                    />
                ),
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>
                            <Link className="link"
                                to={{ pathname: VIEW_CUSTOMER + "/" + props.original.parentUID }}>

                                {props.original.parentEmail}
                                {/* </div> */}
                            </Link>
                        </div>
                    )
                }
            },
            {
                Header: "Number of Views",
                accessor: "numberOfViews",
                width: 130,
                headerClassName: 'table-header',
                filterable: false, 
                Cell: props => (
                    <div style={{ textAlign: "center" }}>{props.original.numberOfViews}</div>
                ),
            },
            {
                Header: "Total Score",
                accessor: "totalScore",
                width: 120,
                headerClassName: 'table-header',
                filterable: false, 
                Cell: props => (
                    <div style={{ textAlign: "center" }}>{props.original.totalScore}</div>
                ),
            },
            // {
            //     Header: "Action",
            //     width: 100,
            //     headerClassName: 'table-header',
            //     Cell: props => (
            //         <div style={{ textAlign: "center" }}>
            //             <Popup
            //                 trigger={
            //                     <button className="delete-button">
            //                         <FontAwesomeIcon icon="trash" />
            //                     </button>
            //                 }
            //                 content="Delete Member"
            //                 position="top center"
            //             />
            //         </div>
            //     ),
            // },
        ];

        return (
            <div className="group-members">
                <div className="header">
                    <h2>Group ID: {this.props.match.params.id}</h2>
                    <Button style = {{backgroundColor: "#7c4a7b"}} primary onClick={this.openEditModal}>
                        Edit Group
                    </Button>

                    <EditGroupModal
                        isOpen={isEditModalOpen}
                        onClose={this.closeEditModal}
                        title="Edit Group"
                        onSave={this.handleEditGroup}
                    >
                        <div>
                            <label style={{color: "#a7a7a7"}}>Group Name</label>
                            <input
                                type="text"
                                value={groupName}
                                onChange={(e) => this.setState({ groupName: e.target.value })}
                                style={{ width: '100%', padding: '8px', marginBottom: '20px' }}
                            />
                        </div>
                        <div>
                            <label style={{color: "#a7a7a7"}}>Group Status</label>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                <label className="group-status-toggle">
                                    <input
                                        type="checkbox"
                                        checked={groupStatus === 'Active'}
                                        onChange={(e) =>
                                            this.setState({
                                                groupStatus: e.target.checked ? 'Active' : 'Inactive',
                                            })
                                        }
                                    />
                                    <span className="group-status-slider"></span>
                                </label>
                                <span style={{ marginLeft: '10px' }}>
                                    {groupStatus === 'Active' ? 'Active' : 'Inactive'}
                                </span>
                            </div>
                        </div>
                    </EditGroupModal>

                </div>

                <div className="tabs-dropdown-container">
                    <div className="tabs-switch">
                        <button
                            className={selectedTab === 'analytics' ? 'active-tab' : ''}
                            onClick={() => this.handleTabChange('analytics')}
                        >
                            Analytics
                        </button>
                        <button
                            className={selectedTab === 'members' ? 'active-tab' : ''}
                            onClick={() => this.handleTabChange('members')}
                        >
                            Members
                        </button>
                    </div>

                   {(selectedTab === 'members') && <div className="date-range-dropdown">
                        <Dropdown
                            className="ui selection dropdown"
                            placeholder="Select Date Range"
                            selection
                            options={dateOptions}
                            value={selectedDateRange}
                            onChange={this.handleDateRangeChange}
                        />
                    </div>}
                </div>

                <div className="mt-4 responsive-table">
                    {(selectedTab === 'members') && 
                    <ReactTable
                        className="table-striped custm-table"
                        columns={columns}
                        data={members}
                        pageSize={pageSize}
                        page={page}
                        pages={totalPages} 
                        manual 
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        filterable
                        loading={loading}
                    />}
                    {
                    (selectedTab === 'analytics') &&
                    <ContentSection
                    pieData={this.state.pieData}
                    series={this.state.series}
                    videos={this.state.videos}
                    radioChannels={this.state.radioChannels}
                    games={this.state.games}
                />
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    members: state.group.members,
    loading: state.group.membersLoading,
    error: state.group.membersError,
    dashboardData: state.group.dashboard,
    dashboardError: state.group.group_dashboard_error,
    dashboardSuccess: state.group.group_dashboard_successfully
});

export default connect(mapStateToProps, { fetchGroupMembers, fetchGroupDashboard,editGroupNameAndStatus })(withRouter(GroupMembersTable));
