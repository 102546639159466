import {
    FETCH_ALL_GROUP, LOAD_GROUP,
    ERROR_LOADING_ALL_GROUP,
    ADD_GROUP_SUCCESSFULLY, ADD_GROUP_ERROR,
    UPDATE_GROUP_SUCCESSFULLY, UPDATE_GROUP_ERROR,
    GET_GROUP_DATA_SUCCESSFULLY, GET_GROUP_DATA_ERROR,
    EMPTY_GROUP, LOAD_GROUP_PAGE_OR_FILTER,
    LOAD_MEMBERS, FETCH_MEMBERS_SUCCESS, FETCH_MEMBERS_ERROR,FETCH_DASHBOARD_SUCCESS,FETCH_DASHBOARD_ERROR,DELETE_GROUP_SUCCESSFULLY,
    ASSIGN_STUDENT_TO_GROUP_SUCCESS, ASSIGN_STUDENT_TO_GROUP_ERROR,LOAD_ASSIGN_GROUP
} from '../Actions/Types';

const initialState = {
    items: [],
    item: {},
    loading: false,
    number: '',
    pageSize: 10,
    totalPages: -1,
    filterOrPageLoading: false,

    error_all_loading_groups: '',
    getting_allGroups_successfully: false,

    group_data_successfully: false,
    group_data_error: '',

    add_group_successfully: false,
    add_group_error: '',
    update_group_successfully: false,
    delete_group_successfully: false,
    update_group_error: '',
    group_dashboard_successfully: false,
    group_dashboard_error: '',
    dashboard: {},
    assign_studet_to_group_success: false,
    assign_studet_to_group_error: ""

};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_GROUP:
            return {
                ...state,
                items: [],
                item: {},
                add_group_successfully: false,
                delete_group_successfully: false,
                error_all_loading_groups: '',
                getting_allGroups_successfully: false,
                loading: true,
                assign_studet_to_group_success: false

            };
        case LOAD_ASSIGN_GROUP:
            return {
                ...state,
              
                assign_studet_to_group_success: false,
                assign_studet_to_group_error: ""

            };
        case LOAD_GROUP_PAGE_OR_FILTER:
            return {
                ...state,
                items: [],
                item: {},
                error_all_loading_groups: '',
                getting_allGroups_successfully: false,
                filterOrPageLoading: true
            };
        case FETCH_ALL_GROUP:
            return {
                ...state,
                items: action.payload.content,
                error_all_loading_groups: '',
                add_group_successfully: false,
                getting_allGroups_successfully: true,
                loading: false,
                filterOrPageLoading: false,
                number: action.payload.number,
                pageSize: action.payload.size,
                totalPages: action.payload.totalPages,
                item: {}
            };

        case ERROR_LOADING_ALL_GROUP:
            return {
                ...state,
                error_all_loading_groups: action.payload,
                loading: false
            };

        case GET_GROUP_DATA_SUCCESSFULLY:
            return {
                ...state,
                item: action.payload,
                group_data_successfully: true,
                group_data_error: '',
                loading: false
            };

        case GET_GROUP_DATA_ERROR:
            return {
                ...state,
                group_data_error: action.payload,
                group_data_successfully: false,
                loading: false
            };

        case ADD_GROUP_SUCCESSFULLY:
            return {
                ...state,
                add_group_successfully: true,
                loading: false
            };

        case ADD_GROUP_ERROR:
            return {
                ...state,
                add_group_successfully: false,
                add_group_error: action.payload,
                loading: false
            };

        case UPDATE_GROUP_SUCCESSFULLY:
            return {
                ...state,
                update_group_successfully: true,
                update_group_error: '',
                loading: false
            };

        case UPDATE_GROUP_ERROR:
            return {
                ...state,
                update_group_successfully: false,
                update_group_error: action.payload,
                loading: false
            };

        case EMPTY_GROUP:
            return {
                ...state,
                items: [],
                item: {},
                loading: false,
                number: '',
                pageSize: 10,
                totalPages: -1,
                filterOrPageLoading: false,
            
                error_all_loading_groups: '',
                getting_allGroups_successfully: false,
            
                group_data_successfully: false,
                group_data_error: '',
            
                add_group_successfully: false,
                add_group_error: '',
                update_group_successfully: false,
                delete_group_successfully: false,
                update_group_error: '',
                group_dashboard_successfully: false,
                group_dashboard_error: '',
                dashboard: {},
                assign_studet_to_group_success: false,
                assign_studet_to_group_error: ""
            };

        case LOAD_MEMBERS:
            return {
                ...state,
                members: [],
                membersLoading: true,
                membersError: '',
            };

        case FETCH_MEMBERS_SUCCESS:
            return {
                ...state,
                members: action.payload,
                membersLoading: false,
                membersError: '',
            };

        case FETCH_MEMBERS_ERROR:
            return {
                ...state,
                membersLoading: false,
                membersError: action.payload,
            };
        case FETCH_DASHBOARD_SUCCESS:
            return {
                ...state,
                dashboard: action.payload,
                group_dashboard_successfully: true,
                group_dashboard_error: '',
                loading: false
            };
        case FETCH_DASHBOARD_ERROR:
            return {
                ...state,
                group_dashboard_error: action.payload,
                group_dashboard_successfully: false,
                loading: false
            };

        case DELETE_GROUP_SUCCESSFULLY:
            return {
                ...state,
                delete_group_successfully: true,
                loading: false
        }
        case ASSIGN_STUDENT_TO_GROUP_SUCCESS:
            return {
            ...state,
            assign_studet_to_group_success: true,
            assign_studet_to_group_error: ''
        }
        case ASSIGN_STUDENT_TO_GROUP_ERROR:
            return {
            ...state,
            assign_studet_to_group_success: false,
            assign_studet_to_group_error: action.payload
        }

        default:
            return state;
    }
}
