import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Dropdown } from 'semantic-ui-react';

/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

/*Action */
import { addSuccessMessage } from '../../../Actions/SuccessMessagesActions';
import { tpayRegister,mondiaRegister,mondiaLogin,tpayLogin, empty, signOut } from '../../../Actions/AuthActions';
import { fitRegister, fitLogin } from '../../../Actions/AuthActions';
import {
    addSeriesIDtoRedirectionPage,
    emptyExchangeData, redirectionPage
} from '../../../Actions/RedirectionPagesActions'
import { getTelecomPrices } from '../../../Actions/FawryPaymentAction'
import { getDigest, emptyTpay } from '../../../Actions/TpayActions';

/*component */
import mainLogoM from '../../../images/portal/logo-big@2x.png';


import orange from '../../../images/tpay/orange.png'

import stc from '../../../images/fit/stc.png'
import '../../../css/mondiaLogin.css';

import Footer from '../Layout/Footer';
import Testimonials from '../Layout/Testimonials';
import Loader from '../../../components/Layout/Loader'
import "../../../css/Fit.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* Routes */
import { PORTAL_LOGIN_METHODS } from '../../../routes';

import * as CONSTANTS from '../../../constants';

import axios, { post } from 'axios';
//submitBtnClass:'submitContainerOpacityNonActive',


const TPAY_SERVICES = [
    {
        key: 0,
        text: CONSTANTS.JEEL_Orange,
        value: CONSTANTS.TPAY_ORANGE,
        image: { avatar: true, src: orange },
    }
]

const FIT_SERVICES = [
    {
        key: 0,
        text: 'STC',
        value: CONSTANTS.FIT_STC,
        image: { avatar: true, src: stc },
    },
]


const COUNTRIES = [
    {
        text: 'مصر',
        value: 'Egypt'
    },
  
]

const TPAY_SERVICES_ARRAY = []
const FIT_SERVICES_ARRAY = []


class MondiaLoginComponent extends Component 
{

    state = {
		submitBtnClass:'submitContainerOpacityNonActive',
        register: true,
        email: "",
        date: "",

        country: '',
        phone: "",
        telecomCompany: "JEEL_Orange",
        activeRegisterBtn: 'active',
        activeSignInBtn: '',
        activeItemIndex: 0,
        disabled: true,
        loginDisabled: true,
        errorMsg: '',
        error: false,

        openSnakBar: false,
        vertical: 'bottom',
        horizontal: 'right',

        isRevealPassword: false,
        password: "",

        redirect: false,
        loginSuccess: false,

        services: [],
        countryPhoneCode: 'eg',
        subscriptionMessage: '',

        telecomCompanySubscriptionPrice: '',
        frequency: '',
        keyword: CONSTANTS.TPAY_KEYWORDS[CONSTANTS.TPAY_ORANGE],
        shortCode: CONSTANTS.TPAY_SHORT_CODES[CONSTANTS.TPAY_ORANGE],
        supportMail: CONSTANTS.SUPPORT_MAIL,
        serviceName: CONSTANTS.JEEL_SERVICE_NAME,
        telecomCompanyText: 'اورانج - Orange',

        digest: '',
        loaded: false,
        sessionToken: '',
        paymentTelecomName:'',
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        //console.log("Signout Telecom")
        const searchParams = new URLSearchParams(window.location.search);
        const paymentTelecome = searchParams.get('name');
        
        this.props.signOut();
        this.props.emptyTpay();
        this.props.empty();
        // empty redirection page
        this.props.redirectionPage();
        this.props.getTelecomPrices("Egypt", "JEEL_Orange");

        //date send
        var date = new Date();
        var utc_date = date.toISOString();
        this.props.getDigest(utc_date);
        this.passwordOneRef = React.createRef();
        this.iconRevealPassword = React.createRef();
        this.setState({
            digest: '',
            date: utc_date,
        })
        if(paymentTelecome !== null && paymentTelecome !== ''){
            this.setState({
                paymentTelecomName:paymentTelecome,
                telecomCompany: paymentTelecome,
                telecomCompanyText: CONSTANTS[paymentTelecome],
                shortCode: CONSTANTS.TPAY_SHORT_CODES[paymentTelecome],
                keyword: CONSTANTS.TPAY_KEYWORDS[paymentTelecome],
            })

            if(paymentTelecome === CONSTANTS.FIT_STC)
            {
                this.setState({
                    services: FIT_SERVICES,
                    country: "Saudi Arabia",
                    countryPhoneCode: 'sa'
        
                })                
            }       
            else{
                this.setState({
                    services: TPAY_SERVICES,
                    country: "Egypt",
                    countryPhoneCode: 'eg'
        
                })
            }
       }
       // ---------------------------------------------------------------------------------------------------------------------------------------
       //alert('Will call BE now ');
       //
       const subscriptionId = searchParams.get('subscriptionId');
        
       axios.get('/mondia/load?subscriptionId='+subscriptionId)
      .then(res => {
	
        const persons = res.data;
        //this.setState({ persons });
        //alert('inside send');
        console.log('Data: '+JSON.stringify(res.data,null,2));
        
        this.setState({
	                    email: res.data.email,
	                    country: res.data.msg,
	                    countryPhoneCode: '',
	                    phone: res.data.phone,
        
                   }) 
                   
      })

    }

    static getDerivedStateFromProps(props, state) 
    {
	
        // check props for digest
        if (props.digest !== state.digest) {
            return {
                digest: props.digest,
            }
        }
        
        if (props.packagePrice && state.country === CONSTANTS.EGYPT) 
        {
            return {
                subscriptionMessage: '',
                telecomCompanySubscriptionPrice: props.packagePrice.monthDiscountPrice,
                frequency: CONSTANTS.PORTAL_SUBSCRIPTION_MONTHLY,
            }
        } 
        
        if (state.countryPhoneCode === CONSTANTS.EGYPT_SHORT_CODE) {
            return {
                country: CONSTANTS.EGYPT,
                services: TPAY_SERVICES,
                telecomCompany: CONSTANTS.TPAY_ORANGE,
                telecomCompanyText: CONSTANTS.JEEL_Orange,
            }
        } 
    }

    loadScript(src) {
        var script = document.createElement('script');
        script.async = false;
        script.src = src;
        var body = document.getElementsByTagName('body')[0];
        body.appendChild(script);
        this.setState({
            loaded: true
        })
        //console.log("load script Tpay");
        script.addEventListener("load", this.handleScript.bind(this));
        script.addEventListener("error", this.handleScript);

    }

    handleScript(e) {
        if (e.type === "load") {

            const sessionToekn = window.getSessionToken();
            //console.log(sessionToekn);
            this.setState({
                sessionToken: sessionToekn
            });
        } else {
            //console.log(e);
        }
    }

    handleRegisterForm = () => {
        this.setState({ register: true, activeRegisterBtn: 'active', activeSignInBtn: '' });
        this.handleRegisterChange();
    }

    handleSignInForm = () => {
        this.setState({ register: false, activeRegisterBtn: '', activeSignInBtn: 'active' });
        this.handleChange();
    }

    handlePhoneLoginChange = (e, country) => {
        this.setState({ phone: e, country: country.name });
        if (country.name === CONSTANTS.EGYPT) {
            this.setState({
                services: TPAY_SERVICES,
                countryPhoneCode: CONSTANTS.EGYPT_SHORT_CODE
            });
            if (!TPAY_SERVICES_ARRAY.includes(this.state.telecomCompany)) {
                this.setState({
                    telecomCompany: CONSTANTS.TPAY_ORANGE,
                    telecomCompanyText: CONSTANTS.JEEL_Orange
                })
            }
        } 
        else {
            this.setState({ country: '' });
        }
        setTimeout(() => {
            this.setState({ error: false, errorMsg: '' })
            if (this.state.email !== '' && this.state.email !== null && this.state.email !== undefined && this.state.email !== ' ' &&
                this.state.password !== '' && this.state.password !== null && this.state.password !== undefined && this.state.password !== ' ' &&
                this.state.phone !== '20' && this.state.phone !== '966' && this.state.phone !== '' && this.state.phone !== null && this.state.phone !== undefined && this.state.phone !== ' '

            ) {
                this.setState({ loginDisabled: false })
            } else {
                this.setState({ loginDisabled: true })
            }
        }, 0);
    }

    handlePhoneChange = (e) => {
	console.log('phone state: ',e.target.name);
	
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        setTimeout(() => {
            this.setState({ error: false, errorMsg: '' })
            if (this.state.email !== '' && this.state.email !== null && this.state.email !== undefined && this.state.email !== ' ' &&
                this.state.password !== '' && this.state.password !== null && this.state.password !== undefined && this.state.password !== ' ' &&
                this.state.phone !== '20' && this.state.phone !== '' && this.state.phone !== null && this.state.phone !== undefined && this.state.phone !== ' '

            ) {
                this.setState({ disabled: false })
            } else {
                this.setState({ disabled: true })
            }
        }, 0);
    }


    handlePhoneRegisterChange = (e) => {
	
	//console.log('evt is: '+e.target.value);
	
        this.setState({
            phone: e.target.value,
        });
        
        this.handlePhoneChange(e);
        
        /*
        if (country.name === CONSTANTS.EGYPT) {
            this.setState({
                services: TPAY_SERVICES,
                countryPhoneCode: CONSTANTS.EGYPT_SHORT_CODE
            });
            if (!TPAY_SERVICES_ARRAY.includes(this.state.telecomCompany)) {
                this.setState({
                    telecomCompany: CONSTANTS.TPAY_ORANGE,
                    telecomCompanyText: CONSTANTS.JEEL_Orange
                })
            }

        } else {
            this.setState({ country: '' });
        }
        setTimeout(() => {
            this.setState({ error: false, errorMsg: '' })
            if (this.state.email !== '' && this.state.email !== null
                && this.state.email !== undefined && this.state.email !== ' ' &&

                this.state.password !== '' && this.state.password !== null
                && this.state.password !== undefined && this.state.password !== ' ' &&

                this.state.phone !== '20' && this.state.phone !== '966' &&
                this.state.phone !== '' && this.state.phone !== null
                && this.state.phone !== undefined && this.state.phone !== ' ' &&

                this.state.telecomCompany !== '' && this.state.telecomCompany !== null
                && this.state.telecomCompany !== undefined && this.state.telecomCompany !== ' '
            ) {
                this.setState({ disabled: false })
            } else {
                this.setState({ disabled: true })
            }
        }, 0);
        
        */

    }

    handleTelecomCompany = (e, { value }) => {
        if(this.state.paymentTelecomName !== null &&
            this.state.paymentTelecomName !== '' ){ // this condition to not select any telecomCompanyText if it is selected 
            return;
        }
        let c = value;
        this.setState({
            telecomCompany: value,
            telecomCompanyText: CONSTANTS[c],
            shortCode: CONSTANTS.TPAY_SHORT_CODES[c],
            keyword: CONSTANTS.TPAY_KEYWORDS[c],
        })
    }

    handleCountryChange = (e, { value }) => {

        if(this.state.paymentTelecomName  !== null &&
            this.state.paymentTelecomName  !== ''){ // this condition to not select any telecomCompanyText if it is selected 
            return;
        }

        let countryInput = value;
        this.setState({
            country: value
        })
        if (countryInput === 'Egypt') {
            this.setState({ services: TPAY_SERVICES, countryPhoneCode: 'eg', telecomCompany: CONSTANTS.TPAY_ORANGE});
        } else {
            this.setState({ services: '', countryPhoneCode: '' });
        }
    }   

    

    handleRegisterChange = (e) => {
		if(e)
		{
	        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
	        this.setState({ [e.target.name]: checkSpaces });
	    }
        setTimeout(() => {
            this.setState({ error: false, errorMsg: '' })
            if (this.state.email !== '' && this.state.email !== null && this.state.email !== undefined && this.state.email !== ' ' &&
                this.state.password !== '' && this.state.password !== null && this.state.password !== undefined && this.state.password !== ' ' &&
                this.state.phone !== '20' && this.state.phone !== '' && this.state.phone !== null && this.state.phone !== undefined && this.state.phone !== ' '
            ) {
                this.setState({ disabled: false,submitBtnClass:'submitContainerOpacityActive' })
            } else {
                this.setState({ disabled: true,submitBtnClass:'submitContainerOpacityNonActive' })
            }
        }, 0);
    }

    handleChange = (e) => {
		if(e)
		{
	        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        	this.setState({ [e.target.name]: checkSpaces });
	    }
        
        setTimeout(() => {
            this.setState({ error: false, errorMsg: '' })
            if (this.state.email !== '' && this.state.email !== null && this.state.email !== undefined && this.state.email !== ' ' && 
                this.state.password !== '' && this.state.password !== null && this.state.password !== undefined && this.state.password !== ' ' 
                //&& this.state.phone !== '20' && this.state.phone !== '' && this.state.phone !== null && this.state.phone !== undefined && this.state.phone !== ' '
            ) {


                this.setState({ loginDisabled: false,submitBtnClass:'submitContainerOpacityActive' })
            } else {
                this.setState({ loginDisabled: true,submitBtnClass:'submitContainerOpacityNonActive' })
            }
        }, 0);
    }

    
    handleRegisterSubmit = (e) => {
        const { email, password,phone,telecomCompany, country, sessionToken } = this.state;
            //alert(JSON.stringify(this.state,null,5));
            //alert('sessionToken: '+sessionToken);
        /// TODO: fix this issue
        //commented because it causes compilation issue
        
        //alert(' Will register Submit Mon, sess: '+sessionToken);
        
        
        
        const searchParams = new URLSearchParams(window.location.search);
        const subscriptionId = searchParams.get('subscriptionId');
        //console.log('sessionToken:'+sessionToken);
        
        if (sessionToken != '' || country!=CONSTANTS.EGYPT) {
            // call Register API            
            
            this.props.mondiaRegister(subscriptionId,email, password, phone, telecomCompany, country, sessionToken);
            
            this.setState({ loginSuccess: true })                         
        }
        e.preventDefault();
    }

    handleLoginSubmit = (e) => {
	
	
	
        const { email, password, phone, country, sessionToken } = this.state;

        /// TODO: fix this issue
        //commented because it causes compilation issue
        //var sessionToken = TPay.HeaderEnrichment.sessionToken();
        
        //const subscriptionId = searchParams.get('subscriptionId');
        // call Login API
        if (sessionToken != ''|| country!=CONSTANTS.EGYPT) {
	
            //this.props.tpayLogin(email, password, phone, telecomCompany, country, sessionToken);
            this.props.mondiaLogin(email, password, phone,'', country, sessionToken);

            this.setState({ loginSuccess: true })
        }
        e.preventDefault();
    }

    togglePassword = event => {
        this.setState({ isRevealPassword: !this.state.isRevealPassword })
    }

    redirect = event => {

        if (
            this.state.phone !== '20' &&
            this.state.phone !== '' && this.state.phone !== null
            && this.state.phone !== undefined && this.state.phone !== ' ') {

            var path = "telecomLogin";
            this.props.addSeriesIDtoRedirectionPage(path, this.state.phone);
            this.setState({ redirect: true });

        } else {
            this.setState({ errorMsg: "يرجى ادخال رقم الهاتف اولا" });

        }
    }



    render() {

        const { isRevealPassword,loginSuccess, redirect, phone,country, telecomCompany,submitBtnClass } = this.state;

        let loader = null;
        
        const { telecom, loading,success, errorResponse } = this.props;

        if (redirect === true) {
            return (<Redirect to={PORTAL_LOGIN_METHODS}></Redirect>)
        }
        

        if (loading === true) {
            loader = (<Loader />)
        }
        
        let tpaySubContractCode, tpayTransactionCode;
        
        if (success === true &&
            loginSuccess === true &&
            telecom !== undefined &&
            telecom !== null) {
            tpaySubContractCode = telecom.subscriptionContractId;
            tpayTransactionCode = telecom.transactionId;
//alert('Login success : '+country);
		    
									            if (country === 'Egypt') 
									            {
										
										
									                // Add subscription Api Returns error in json when operationStatusCode !=0 
									                if (telecom.operationStatusCode !== 0) {
									
									                    let errorMsg = " لقد حدث خطأ  " + telecom.errorMessage
									
									                    if (telecom.errorMessage.includes('This user already subscribed to the given product')) {
									                        errorMsg = 'هذا الرقم مشترك بالفعل في خدمتنا.'
									                    } else if (telecom.errorMessage.includes('Please enter valid phone number for the selected mobile operator.')) {
									                        errorMsg = 'الرجاء إدخال رقم هاتف صالح لمشغل الشبكة المحددة.'
									                    } else if (telecom.errorMessage.includes("MSISDN Can't be null")) {
									                        errorMsg = 'رقم الموبايل مطلوب ولا يمكن تركه فارغًا. '
									                    } else if (telecom.errorMessage.includes('mobile number is blacklisted')) {
									                        errorMsg = 'عذرًا ، لا يمكن تنشيط رقم الموبايل هذا.'
									                    } else if (telecom.errorMessage.includes('is duplicate, wait for 2 minutes before issuing same request')) {
									                        errorMsg = 'عذرًا ، لم نتمكن من إكمال تسجيلك ، انتظر بضع دقائق وحاول مرة أخرى.'
									                    } else if (telecom.errorMessage.includes('for same customer was cancelled or suspended previously and cool off period did not pass')) {
									                        errorMsg = 'عذرًا ، لا يمكنك الاشتراك ، يرجى إعادة المحاولة بعد يوم واحد.'
									                    }
									
									                    this.setState({ errorMsg: errorMsg });
									
									                    this.props.empty();
									                    this.props.emptyExchangeData();
									                }
									                else {
									                    let message = 'تم تسجيل البيانات بنجاح'
									                    this.props.addSuccessMessage(message);
									                    this.props.empty();
									                    this.props.emptyExchangeData();
									                   
									                   //alert('Will Rediretc you here to Vcode , i guess');
									                   
									                   
									                    let redirect = "/TpayPhoneCode/" + phone + "/" + tpaySubContractCode + "/" + tpayTransactionCode + "/" + telecomCompany;
									                    return (<Redirect path to={redirect}> </Redirect>);
									                }
									            }
									            
									            else
									            {
														let message = 'تم تسجيل البيانات بنجاح'
									                    this.props.addSuccessMessage(message);
									                    this.props.empty();
									                    this.props.emptyExchangeData();
									                   
									                   //alert('Will Rediretc you here to Vcode , i guess');
									                   
									                   let redirect = "/mondiaSuccess" + "/" + this.state.phone;
            
														//console.log(' newRedirect after success: '+redirect);            
											            this.props.emptyTpay(); 
									                   
									                    return (<Redirect path to={redirect}> </Redirect>);
												}
									            
									            									            
        }
else if (success === false &&  (errorResponse !== '' && errorResponse !== undefined && loginSuccess === true)    ) 
        {
	//console.log(' ==========@@@@### Error: '+errorResponse);
									            if (errorResponse === "308 PERMANENT_REDIRECT") 
									            {
									                return (<Redirect path to={'/subscription'}> </Redirect>);
									            }
									            this.setState({ errorMsg: errorResponse });
									            //console.log(errorResponse)
									            this.props.empty();
									            this.props.emptyExchangeData();

        }

        let registerForm = '';
        let signinForm = '';
        let phoneSection = '';
        
        let registerForm2 = '';
        let signinForm2 = '';
       
       
        let loginactive = this.state.loginDisabled

        if (this.state.email !== '' && this.state.email !== null
            && this.state.email !== undefined && this.state.email !== ' ' &&

            this.state.password !== '' && this.state.password !== null
            && this.state.password !== undefined && this.state.password !== ' ' &&

            this.state.phone !== '20' &&
            this.state.phone !== '' && this.state.phone !== null
            && this.state.phone !== undefined && this.state.phone !== ' ' &&

            this.state.telecomCompany !== '' && this.state.telecomCompany !== null
            && this.state.telecomCompany !== undefined && this.state.telecomCompany !== ' '
        ) {
            loginactive = false
        }

        let buttonLoginLoader = (
            <div className="custom-form-group w-100 mb-3">
                <button disabled={loginactive}
                    className="btn form-control submit-btn"
                    type="submit" onClick={this.handleLoginSubmit} >
                    تفعيل التسجيل
                </button>
            </div>
        );

        let buttonSubmitLoader = (
            <div className="custom-form-group">
                <button disabled={this.state.disabled}
                    className="btn form-control submit-btn"
                    type="submit" onClick={this.handleRegisterSubmit} >
                    تفعيل التسجيل
                
                </button>
            </div>
        );
        // --------------------------  

        let buttonSubmitLoader2 = (
                <button disabled={this.state.disabled} type="submit" onClick={this.handleRegisterSubmit} >
                    تفعيل التسجيل
                </button>
        );
        
        //<input type="submit" value="Login"/>

        if (this.state.register === true) 
        {
				            
				            phoneSection = (
				                <div className=" telecom-phone-card " >
				
				                    <div className="" >
				                        <strong>   رقم الهاتف الذي تود الاشتراك به
				                        </strong> :
				                        <br /><br />
				                        <div className="phoneNum" id="right-lift">
															                             
												<input type="text" value={this.state.phone} name="phone" onChange={this.handlePhoneRegisterChange.bind(this)} />
				                        </div>
				                    </div>
				                </div>
				            )
				            
	registerForm2 = (                           
						<>	                            
											         	<div class="field">
											               <input type="text" required class="p-3"  
											               placeholder="رقم الهاتف" 
											               dir="auto" 
											               className="filed form-control"  
											               name="phone" 
											               value={this.state.phone} 
											               onChange={this.handleRegisterChange} />
											               <label>Phone</label>
											            </div>
											            
											            <div class="field">
											               <input type="email" required 
											               		  className="filed form-control"
											                      name="email"
											                      placeholder="بريدك الإلكتروني"
											                      dir="auto"
											                      value={this.state.email}
											                      onChange={this.handleRegisterChange}/>
											               <label>Email</label>
											            </div>
											            
											           
														<div class="field">
														               <input required class="p-3"
														               		  type={isRevealPassword ? "text" : "password"}
														                      dir="auto"
														                      className="filed form-control"
														                      name="password"
														                      placeholder="كلمة المرور"
														                      value={this.state.password}
														                      onChange={this.handleRegisterChange}
														                      ref={this.passwordOneRef}
														               />
														               <label>Password</label>
														                           
														</div>            
											            
											            <div class="field" style={{opacity:this.state.submitBtnClass=='submitContainerOpacityActive'?'1':'0.5'}}>
											               <input type="submit" value="تفعيل التسجيل" disabled={this.state.disabled} onClick={this.handleRegisterSubmit} />
											            </div>
											            <div class="signup-link">
															<div className="policies">
												                    <div className="text mb-3">
												                        <p>بتسجيلك فانت توافق على <span className="color-font">
												                           <a href="/Privacy" style={{color:'#4183c4'}}> شروط الاستخدام و سياسه الخصوصيه
																	      </a>
												                        	</span> 
												                        </p>
												
												                    </div>
												                </div>
															                                   
											            </div>
						</>
				                            				
				    )
				            				            
        } 
        else 
        {
           
           phoneSection = (
							<div className=" telecom-phone-card">
					                <div className="">
					                    <strong>   رقم الهاتف الذي تود الاشتراك به
					                    </strong> 
					                    <br /><br />
					                    <div className="phoneNum" id="right-lift">
					                        <PhoneInput
					                            country={this.state.countryPhoneCode}
					                            placeholder="رقم الهاتف"
					                            name="phone"
					                            value={this.state.phone}
					                            onlyCountries={['eg', 'sa','iq','bh','qa']}
					                            onChange={this.handlePhoneLoginChange} />
					                    </div>
					
					                </div>
					            </div>
				            )
				            
				          
            signinForm2 = (
				                    
									<>	                            

										            <div class="field">
										               <input type="text" required 
										                      class="p-3"
										                      className="filed form-control"  
										                      name="email"
										                      placeholder="بريدك الإلكتروني"
										                      dir="auto"
										                      value={this.state.email}
										                      onChange={this.handleChange}/>
										               <label>Email</label>
										            </div>
										            <div class="field">
										               <input required class="p-3"
										               		  type={isRevealPassword ? "text" : "password"}
										                      dir="auto"
										                      className="filed form-control"
										                      name="password"
										                      placeholder="كلمة المرور"
										                      value={this.state.password}
										                      onChange={this.handleChange}
										                      ref={this.passwordOneRef}
										               />
										               <label>Password</label>
										                           
										            </div>
										            
										            <div class="field" style={{opacity:this.state.submitBtnClass=='submitContainerOpacityActive'?'1':'0.5'}}>
										               <input type="submit"  disabled={this.state.loginDisabled} value="الدخول" onClick={this.handleLoginSubmit} />										               
										            </div>
										            <div class="signup-link">
														<div className="policies">
										                        <div className="text mb-3">
										                            <p>بتسجيلك فانت توافق على <span className="color-font">
										                               <a href="/Privacy" style={{color:'#4183c4'}}> شروط الاستخدام و سياسه الخصوصيه
																      </a>
										                            	</span> 
										                            </p>
										
										                        </div>
										                    </div>
														                                   
										            </div>
									</>				                
				          )
				          				          
        }
        
        
        
if (this.state.digest && this.state.date && this.state.loaded === false) 
        {
            //alert("inside " + this.state.digest);
            //var date = new Date();
            //var utc_date = date.toISOString();
            this.loadScript("http://enrichment-staging.tpay.me/idxml.ashx/js-staging?date=" + this.state.date + "&digest=" + this.state.digest + "");
        }
        
        let beforePriceDisclaimer = '';
        //let afterPriceDisclaimer = this.state.frequency + ' تجدد تلقائيا. ' + ' لالغاء الاشتراك، لعملاء ' + this.state.telecomCompanyText + ' أرسل ' + this.state.keyword + ' الى ' + this.state.shortCode + ' مجانا. ' + ' لأيّة استفسارات يرجي التواصل معنا على ' + this.state.supportMail + '. استهلاك الانترنت سوف يخصم من الباقة الخاصة بك.';
        let afterPriceDisclaimer = ' ' + ' لأيّة استفسارات يرجي التواصل معنا على ' + this.state.supportMail + '';
        let disclaimerSection = '';
        
        if (this.state.country === CONSTANTS.EGYPT && this.state.countryPhoneCode === CONSTANTS.EGYPT_SHORT_CODE)
            disclaimerSection = (
					                <div style={{ textAlign: 'right' }}>
					                    <div className="text">
					                        <p>
					                            {beforePriceDisclaimer}
					                            
					                            {afterPriceDisclaimer}
					                        </p>
					
					                    </div>
					                </div>
					            )        
            
                        
        return (
            <body>
												                <section className="jeel-fit">
												                    <div className="logo-container">
												                        <div className="container">
												                            <div className="logo">
												                                <img src={mainLogoM} alt="logo" />
												                            </div>
												                        </div>
												                    </div>
												
												                    <div className="container">
												                        <div className="row justify-content-md-center">
												                            <div className="col-12 col-md-6">
												                                <div className="center fit-about-text">
												                                    <div className="title h2 white ">
												                                        <center>
												                                            أدخل رقم هاتفك و سجل فوراً في تطبيق جيل!
												                                            <br />
												                                            <span className=" h5">
												                                                {this.state.subscriptionMessage}
												                                            </span>
												                                        </center>
												                                    </div>
												                                </div>
												                            </div>
												                        </div>
												                        <div className="row justify-content-md-center">
												
												                            <div className="col-12 col-md-6">
												                                <div className="  main-progress-bar">
												                                    <ol className="progress-tracker">
												                                        <li className="step fit">
												                                            <span className=" step-name small-font">
												                                                <span className="bold">
												                                                    التسجيل
												                                                </span>
												                                            </span>
												                                        </li>
												                                        
												                                        <li className="step fit">
												                                            <span className="step-name small-font">
												                                                <span className="notbold">
												                                                    تحميل التطبيق
												                                                </span>
												                                            </span>
												                                        </li>
												                                    </ol>
												                                </div>
												                            </div>
												                        </div>
												                    </div>
												                    <br />
												                    <br />
												                </section>


																															                
                
                <br /><br />
                
                {loader}
                

                
                
                <br/>
                {/* *************************************** New Form *******************************************  */}
                <div class="mt-1" style={{display:'flex'}}>
                
						<div class="wrapper">
					         <div class="title">
					            &nbsp;
					         </div>
					         
								<div className="mx-auto text-rtl row">
                                    <p style={{ color: 'red', textAlign: 'right' }}> {this.state.errorMsg}</p>
                                </div>					         
											
					         <div className="row">
					         	<div className="col-6 p-3 text-center">
						         	<button data-toggle-form=".signup-form" className={"btn toggle-btn signin-btn " + this.state.activeRegisterBtn} onClick={this.handleRegisterForm}> انشاء حساب جديد </button>
								</div>
					         	<div className="col-6 p-3 text-center">
						         	<button data-toggle-form=".signin-form" className={"btn toggle-btn signup-btn " + this.state.activeSignInBtn} onClick={this.handleSignInForm}> تسجيل دخول</button>
								</div>
					         </div>               
					                                             
					         <form >
					         	{registerForm2} 
					         	{signinForm2}        	
					         </form>
					      </div>                
                
                </div>
                
                {/* *************************************** New Form *******************************************  */}
                


                <Testimonials />
                {/** Start Footer **/}
                <Footer />
                {/** End Footer */}
            </body>
        )
    }
}

const mapStateToProps = state => ({
    success: state.authUser.success,
    errorResponse: state.authUser.errorMsg,
    loading: state.authUser.loading,
    telecom: state.authUser.tpayData,
    successMessage: state.successMessages.item,
    exchangeData: state.redirectionPage.item,

    token: state.user.token,
    packagePrice: state.fawryPaymentPrices.item,

    digest: state.tpay.digest,
    paymentMethodName:  state.paymentData.paymentMethod,
});

export default withRouter
    (connect
        (mapStateToProps,
            {
                tpayRegister,
                mondiaRegister,
                mondiaLogin,
                empty,
                tpayLogin,
                fitLogin,
                fitRegister,
                addSuccessMessage,
                addSeriesIDtoRedirectionPage,
                emptyExchangeData,
                redirectionPage,
                signOut,
                getTelecomPrices,
                emptyTpay,
                getDigest
            })
        (MondiaLoginComponent)
    );
