import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { withFirebase } from '../../config';
import Switch from "react-switch"
import { Dropdown } from 'semantic-ui-react';


/*Redux*/
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { getGameData } from '../../Actions/GameActions';

/*Actions*/
import { updateGame, emptyGameData } from '../../Actions/GameDataAction';
import {
    fetchAllGameParent
} from '../../Actions/GameParentAction';
import { addSeriesIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'

/*Headers*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
import UploadImage from '../S3Uploading/UploadImage';
import UploadAudio from '../S3Uploading/UploadAudio';
import { fetchAllContentVendors } from '../../Actions/ContentVendorActions.js'

import '../../css/AppCss/gamePreview.css'
/* Routes */
import { SIGN_IN, ADMIN_LAND_PAGE, ALL_GAME_PARENTS, GAME_DATA_DETAIL, VIEW_GAME_PARENT } from '../../routes';

function validURL(str) {
    var res = str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)

}

const INITIAL_STATE = {
    name: "",
    name_in_english: "",
    key: "",
    game_Image: "",
    girl_image: "",
    redirection: false,
    is_free: '',
    status: '',
    type: "Unity",
    order: 0,
    url: "",
    voice: "",
    selected_gameParent_id: '',
    selected_gameParent_name: '',
    featured: false,
    returned_gameParent_id_viewGameParentPage: '',
    returned_gameParent_name_viewGameParentPage: '',
    contentVendorSelectedId: '',

}

const STATUESES = [
    {
        text: 'Active',
        value: 'ACTIVE'
    },
    {
        text: 'Suspended',
        value: 'SUSPENDED'
    }
]

const TYPES = [
    {
        text: 'Unity',
        value: 'Unity'
    },
    {
        text: 'Html5',
        value: 'Html5'
    }
]

class EditGameData extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        var id = this.props.match.params.gameId;
        const { gameParent } = this.props
        const selectedParent = []
        const { gameParentId } = this.props.match.params;
        getGameData(id).then(res => {
            var tempBoyImg = res.data.image
            var tempGirlImg = res.data.girlImage
            var url = res.data.url
            var voice = res.data.voice

            if (res.data.image == null || res.data.image == "null") {
                tempBoyImg = ""
            }
            if (res.data.girlImage == null || res.data.girlImage == "null") {
                tempGirlImg = ""
            }
            if (res.data.url == null || res.data.url == "null") {
                url = ""
            }
            if (res.data.voice == null || res.data.voice == "null"
                || res.data.voice === 'undefined') {
                voice = null
            }
            let filterParent = gameParent.filter(item => item.id === Number(gameParentId)) // parent id key => gameParent_id
            selectedParent.push(...filterParent)
            let selectedGameParentName = selectedParent[0] ? selectedParent[0].name : ''
            let returnedGameParentName_viewGameParentPage = selectedParent[0] ? selectedParent[0].name : ''
            this.setState({
                name: res.data.name,
                name_in_english: res.data.nameInEnglish,
                key: res.data.gameKey,
                game_Image: tempBoyImg,
                girl_image: tempGirlImg,
                is_free: res.data.isFree,
                status: res.data.status,
                type: res.data.type,
                order: res.data.gameOrder,
                url: url,
                voice: voice,
                selected_gameParent_id: gameParentId, // parent id key => gameParent_id
                selected_gameParent_name: selectedGameParentName,
                featured: res.data.featured,
                returned_gameParent_id_viewGameParentPage: res.data.Game_Parent_Id,
                returned_gameParent_name_viewGameParentPage: returnedGameParentName_viewGameParentPage,
                contentVendorSelectedId: res.data.contentVendor?res.data.contentVendor.id:''

            })


        })
        this.handleIsFreeChange = this.handleIsFreeChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);


    }

    onChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });

    }

    getGameImageUrl(imageUrl) {
        if (imageUrl === 'delete') {
            this.setState({ game_Image: '' });
        }
        else if (imageUrl !== undefined && imageUrl !== '') {
            this.setState({ game_Image: imageUrl });
        }
    }

    getGirlImageUrl(imageUrl) {
        if (imageUrl === 'delete') {
            this.setState({ girl_image: '' });
        }
        else if (imageUrl !== undefined && imageUrl !== '') {
            this.setState({ girl_image: imageUrl });
        }
    }

    redirect = (e) => {

        this.setState({ redirection: true })
    }

    updateGameData(e) {
        e.preventDefault();
        const { name, key, game_Image, girl_image, is_free, status,
            url, type, order, voice, selected_gameParent_id, name_in_english, featured,contentVendorSelectedId } = this.state
        var id = this.props.match.params.gameId;

        if (name.length > 255) {
            alert("Name lenght can't be more than 255 character")
        }
        else if (key.length > 255) {
            alert("Key lenght can't be more than 255 character")
        }
        else if (selected_gameParent_id === '' || selected_gameParent_id === undefined || selected_gameParent_id === null) {
            alert("Empty Parent")
        }
        else if
            (type == "Html5" && url != null
            && url != undefined
            && url != ''
            && url != ""
            && !(validURL(url))) {
            alert("Please enter a vaild url ")

        }
        else {
            if (selected_gameParent_id) {
                this.props.addSeriesIDtoRedirectionPage(VIEW_GAME_PARENT, selected_gameParent_id)
            }
            this.props.updateGame(id, name, game_Image, girl_image, key, is_free, status, url, type, order, voice, selected_gameParent_id, name_in_english, featured,contentVendorSelectedId);
        }

    }

    handleIsFreeChange = (is_free) => {
        this.setState({ is_free })
    }

    handleStatusChange = (e, { value }) => {
        this.setState({ status: value });
    }

    handleTypeChange = (e, { value }) => {
        this.setState({ type: value })
    }

    getAudio(audioUrl) { // get  Audio
        if (audioUrl) {
            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {
                this.setState({ voice: '' });
            }
            // else send the url to DB
            else {
                this.setState({ voice: audioUrl });
            }

        }
    }

    handleGameParentChange = (e, { value }) => {
        this.setState({ selected_gameParent_id: value[0].id })
        this.setState({ selected_gameParent_name: value[1].name })
    }

    handleCheckboxChange = () => {
        this.setState({ featured: !this.state.featured })
    };

handleContentVendorSelection = (e, { value }) => {
        //console.log("contentVendor selected: " + value);
        this.setState({ contentVendorSelectedId: value })
    }
    
    render() {
        const { success, error } = this.props;

// --------------------------------
    const { ContentVendorsList } = this.props;
    const ContentVendorsArr = [];
    
    //console.log('ContentVendorsList : '+JSON.stringify(ContentVendorsList,null,2));
    
    for (let i = 0; i < ContentVendorsList.length; i++) 
    {
      ContentVendorsArr.push({
        key: ContentVendorsList[i].id,
        text: ContentVendorsList[i].user.name,
        value: ContentVendorsList[i].id,
      });
    }
// --------------------------------

        const InValid = this.state.name === " "
            || this.state.name === ""
            || this.state.name === ''
            || this.state.name === ' '
            // || this.state.name_in_english === " "
            // || this.state.name_in_english === ""
            // || this.state.name_in_english === ''
            // || this.state.name_in_english === ' '
            // ||this.state.name_in_english === null
            || this.state.key === ""
            || this.state.key === ""
            || this.state.key === ' '
            || this.state.key === ''
            || this.state.game_Image === ""
            || this.state.game_Image === ""
            || this.state.game_Image === ''
            || this.state.game_Image === ' '
            || this.state.girl_image === ""
            || this.state.girl_image === ""
            || this.state.girl_image === ''
            || this.state.girl_image === ' '
            || this.state.selected_gameParent_id === '';

        let buttonSubmitLoader = (
            <button
                className="btn btn-dark colorBTN"
                disabled={InValid}
                onClick={e => this.updateGameData(e)}>
                Save
            </button>
        )

        if (success === false) {
            alert(error)
            this.props.emptyGameData();
        }
        else if (success === true) {
            alert("Item updated successfully.");

            this.props.emptyGameData();
            if (this.state.selected_gameParent_id) {
                return (<Redirect to={VIEW_GAME_PARENT} ></Redirect>)
            } else {
                return (<Redirect to={ALL_GAME_PARENTS} ></Redirect>)
            }
            // this.props.history.push(GAME_DATA_DETAIL);
        }


        if (this.state.redirection == true) {
            if (this.state.selected_gameParent_id) {
                return (<Redirect to={VIEW_GAME_PARENT} ></Redirect>)
            } else {
                return (<Redirect to={ALL_GAME_PARENTS} ></Redirect>)
            }
        }

        const { gameParent } = this.props;
        const gameParentList = [];
        for (let i = 0; i < gameParent.length; i++) {
            gameParentList.push({
                key: gameParent[i].id,
                text: gameParent[i].name,
                value: [{ id: gameParent[i].id }, { name: gameParent[i].name }]
            })
        }
        return (
            <div className="container">

                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />

                                            <div className="col-12 left-main-body" id="left-main-body">
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link>
                                                        <span> {'>'} </span>
                                                    </span>
                                                    <span className="status-link">
                                                        <Link to={ALL_GAME_PARENTS}>Get All Game Parents</Link>
                                                        <span> {'>'} </span>
                                                    </span>


                                                    <span>Update Game Data</span>
                                                </div>
                                            </div>

                                            <div className="container">
                                                <h3 className="main-title mt-2 font-weight-bold">Update Game Data</h3>

                                                <div className="card">
                                                    <div className="row justify-content-md-center" >
                                                        <div className="col-12">

                                                            <div className="add-items icon-upload-itm mt-4">
                                                                <label className="label">
                                                                    Boy Image
                                                                    <span style={{ color: 'red' }}>*
                                                                    </span>
                                                                </label>
                                                                <UploadImage
                                                                    getImageURLCallBack={this.getGameImageUrl.bind(this)}
                                                                    imageUrl={this.state.game_Image}
                                                                    imagepreview={this.state.game_Image}
                                                                    s3DirectryrName='gamedata' />
                                                            </div>

                                                            <div className="add-items icon-upload-itm mt-4">
                                                                <label className="label">
                                                                    Girl Image
                                                                    <span style={{ color: 'red' }}>*
                                                                    </span>
                                                                </label>
                                                                <UploadImage
                                                                    getImageURLCallBack={this.getGirlImageUrl.bind(this)}
                                                                    imageUrl={this.state.girl_image}
                                                                    imagepreview={this.state.girl_image}
                                                                    s3DirectryrName='gamedata' />
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="label">
                                                                    Name
                                                                    <span style={{ color: 'red' }}>*
                                                                    </span>
                                                                </label>

                                                                <input
                                                                    dir="auto"
                                                                    className="form-control"
                                                                    value={this.state.name}
                                                                    name="name"
                                                                    onChange={e => this.onChange(e)}
                                                                    type="text" />
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="label">
                                                                    Name In English
                                                                    {/* <span style={{ color: 'red' }}>*
                                                                    </span> */}
                                                                </label>

                                                                <input
                                                                    dir="auto"
                                                                    className="form-control"
                                                                    value={this.state.name_in_english}
                                                                    name="name_in_english"
                                                                    onChange={e => this.onChange(e)}
                                                                    type="text" />
                                                            </div>

                                                            <label htmlFor="episode_series_id"
                                                                className="label">Select the Game Parent
                                                                <span style={{ color: 'red' }}>*
                                                                </span>
                                                                <br />
                                                                <strong>Parent is : {this.state.selected_gameParent_name}</strong>
                                                            </label>
                                                            <br />
                                                            <Dropdown fluid search
                                                                selection
                                                                placeholder='Select Game Parent'
                                                                name="episode_series_id"
                                                                onChange={this.handleGameParentChange}
                                                                options={gameParentList}
                                                            />
                                                            <br />

                                                            <div className="form-group">
                                                                <label className="label">
                                                                    Key
                                                                    <span style={{ color: 'red' }}>*
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    dir="auto"
                                                                    className="form-control"
                                                                    value={this.state.key}
                                                                    name="key"
                                                                    onChange={e => this.onChange(e)}
                                                                    type="text" />
                                                            </div>

                                                            <label className="label">
                                                                <span>Status</span>
                                                            </label>
                                                            <Dropdown required fluid selection
                                                                id="game_status"
                                                                onChange={this.handleStatusChange}
                                                                options={STATUESES}
                                                                value={this.state.status}
                                                            />
                                                            <br />

                                                            <label className="label">
                                                                <span>Type</span>
                                                            </label>
                                                            <Dropdown required fluid selection
                                                                id="type"
                                                                onChange={this.handleTypeChange}
                                                                options={TYPES}
                                                                value={this.state.type}
                                                            />
                                                            <br />

                                                            <label className="label">
                                                                <span>Order</span>
                                                            </label>
                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                type="number"
                                                                min="0"
                                                                fluid
                                                                name="order"
                                                                onChange={this.onChange}
                                                                value={this.state.order}
                                                            />
                                                            <br />

                                                            <div className="form-group">
                                                                <label className="label">
                                                                    Url
                                                                </label>
                                                                <input
                                                                    dir="auto"
                                                                    className="form-control"
                                                                    value={this.state.url}
                                                                    name="url"
                                                                    onChange={e => this.onChange(e)}
                                                                    type="text" />
                                                            </div>

                                                            <label className="label">
                                                                <span>Is Free</span>
                                                                <br />
                                                                <Switch onColor="#6f42c1" onChange={this.handleIsFreeChange} checked={this.state.is_free} />
                                                            </label>

                                                            <div className="form-group">

                                                                <label className="label">
                                                                    Audio
                                                                </label>
                                                                <UploadAudio
                                                                    getAudioUrlCallBack={this.getAudio.bind(this)}
                                                                    audioPreview={this.state.voice}
                                                                    audioUrl={this.state.voice}
                                                                    s3DirectryrName="episode/audio"
                                                                />
                                                            </div>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={this.state.featured}
                                                                    onChange={this.handleCheckboxChange}
                                                                />
                                                                {' Featured'}
                                                            </label>
                                                            
                                                            
<label
                                htmlFor="episode_ContentVendor_id"
                                className="label"
                              >
                                
                                Select Content Vendor
                              </label>
                              <br />

                              <Dropdown                                
                                fluid
                                selection
                                placeholder="Select Content Vendor"
                                id="episode_ContentVendor_id"
                                onChange={this.handleContentVendorSelection}
                                options={ContentVendorsArr}
                                value={this.state.contentVendorSelectedId}
                              />
                              <br />
                                                                                          
                                                            <div className="groups-btn mt-5 editBTNs">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="float-right rest-floating">
                                                                            <button className="btn btn-dark" onClick={this.redirect}>
                                                                                Cancel
                                                                            </button>
                                                                            {buttonSubmitLoader}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        )
    }
}

EditGameData.propTypes = {
    updateGame: propTypes.func.isRequired,
    fetchAllGameParent: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    auth: state.firebase.auth,
    redirectionPage: state.redirectionPage.dragDropitem,

    error: state.gameData.updating_game_data_error,
    success: state.gameData.updating_game_data_sucessfully,
    gameParent: state.gameParent.items,
    ContentVendorsList : state.contentVendor.State_items,

});
export default withFirebase(
    connect(
        mapStateToProps,
        {
            updateGame,
            emptyGameData,
            fetchAllGameParent,
            addSeriesIDtoRedirectionPage
        }
    )(EditGameData));

