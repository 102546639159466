import React from 'react';
import '../../../css/EditGroupModal.css'; 

const EditGroupModal = ({ isOpen, onClose, title, children, onSave }) => {
    if (!isOpen) return null;

    return (
        <div className="custom-modal-overlay">
            <div className="custom-modal">
                <div className="custom-modal-header">
                    <h3>{title}</h3>
                    <button className="close-button" onClick={onClose}>
                        &times;
                    </button>
                </div>
                <div className="custom-modal-body">{children}</div>
                <div className="custom-modal-footer">
                    <button className="save-button" onClick={onSave}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditGroupModal;
