import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { fetchChildrenByVendor } from '../../../Actions/VendorActions';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import '../../../css/GroupMembers.css';
import {VIEW_CUSTOMER} from '../../../routes.js'
import AssignToGroupModal from './AssignToGroupModal.js';
import { assignStudentToGroup, fetchAllGroups ,emptyGroup} from '../../../Actions/GroupActions.js';
class KidsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalPages: 0,
            pageSize: 5,
            page: 0,
            loading: false,
            childName: '',
            parentEmail: '',
            selectedChildren: new Set(),
            isModalOpen: false

        };
    }
 openModal = () => {
    if (this.state.selectedChildren.size == 0) {
        alert('Please select at least one child to assign to group');
        return;
    }
        this.setState({ isModalOpen: true });
    };

    closeModal = () => {
        this.setState({ isModalOpen: false });
    };
    componentDidMount() {
        this.fetchMembers();
        this.props.fetchAllGroups("", this.props.page, this.props.pageSize, true);
    }
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props&& prevProps.assignStudentToGroupError !== this.props.assignStudentToGroupError&&this.props.assignStudentToGroupError!=="") {
            alert(this.props.assignStudentToGroupError);
            this.props.fetchAllGroups("", this.props.page, this.props.pageSize, true);
        }
        else if (prevProps !== this.props&& prevProps.assignStudentToGroupSuccess !== this.props.assignStudentToGroupSuccess&&this.props.assignStudentToGroupSuccess) {
            alert('Student assigned to group successfully');
            this.setState({ selectedChildren: new Set() });
            this.props.fetchAllGroups("", this.props.page, this.props.pageSize, true);
        }
    }
    fetchMembers = () => {
        const { page, pageSize, childName, parentEmail } = this.state;
        this.props.fetchChildrenByVendor(page, pageSize, childName, parentEmail)
            .then((response) => {
                if (response && response.totalPages) {
                    this.setState({ totalPages: response.totalPages });
                }
            });
    };

    handlePageChange = (pageIndex) => {
        this.setState({ page: pageIndex }, this.fetchMembers);
    };

    handlePageSizeChange = (size, pageIndex) => {
        this.setState({ pageSize: size, page: pageIndex }, this.fetchMembers);
    };
assignToGroup = (groupId) => {
    this.props.assignStudentToGroup(groupId,Array.from(this.state.selectedChildren));
    this.props.emptyGroup();
    this.closeModal();
}
    handleGroupSearchChange = (value)=>{
        this.props.fetchAllGroups(value.target.value, 0, 20);
    }
    onFilteredChange = (filtered) => {
        let childName = '';
        let parentEmail = '';

        filtered.forEach(filter => {
            if (filter.id === 'name') {
                childName = filter.value;
            }
            if (filter.id === 'parentEmail') {
                parentEmail = filter.value;
            }
        });

        this.setState({ childName, parentEmail, page: 0 }, this.fetchMembers);
    };

    toggleSelectChild = (childId) => {
        this.setState((prevState) => {
            const selectedChildren = new Set(prevState.selectedChildren);

            if (selectedChildren.has(childId)) {
                selectedChildren.delete(childId);
            } else {
                selectedChildren.add(childId);
            }


            return { selectedChildren };
        });
    };




    render() {
        const { pageSize, page, totalPages, selectedChildren } = this.state;
        const { childrenByVendor, loading } = this.props;

        const columns = [
            {
                Header: "",
                accessor: "select",
                headerClassName: 'table-header',
                width: 30,
                Cell: ({ original }) => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%"
                        }}
                    >
                        <input
                            type="checkbox"
                            checked={selectedChildren.has(original.id)}
                            onChange={() => this.toggleSelectChild(original.id)}
                        />
                    </div>
                ),
                filterable: false,
            },            
            {
                Header: "Child Name",
                accessor: "name",
                headerClassName: 'table-header',
                Cell: (props) => (
                    <div className="name-cell">
                        <img
                            src={props.original.profileImageLink}
                            alt="profile"
                            className="profile-image"
                        />
                        <span>{props.original.name}</span>
                    </div>
                ),
            },
            {
                Header: "Gender",
                accessor: "gender",
                width: 100,
                headerClassName: 'table-header',
                filterable: false, 
                Cell: props => <div style={{ textAlign: "center" }}>{props.original.gender}</div>,
            },
            {
                Header: "Age",
                accessor: "age",
                width: 70,
                headerClassName: 'table-header',
                filterable: false, 
                Cell: props => <div style={{ textAlign: "center" }}>{props.original.age}</div>,
            },
            {
                Header: "Parent Email",
                accessor: "parentEmail",
                headerClassName: 'table-header',
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>
                            <Link className="link"
                                to={{ pathname: VIEW_CUSTOMER + "/" + props.original.parentUID }}>

                                {props.original.parentEmail}
                                {/* </div> */}
                            </Link>
                        </div>
                    )
                }
            },
            {
                Header: "Number of Views",
                accessor: "numberOfViews",
                width: 130,
                headerClassName: 'table-header',
                filterable: false,
                Cell: props => <div style={{ textAlign: "center" }}>{props.original.numberOfViews}</div>,
            },
            {
                Header: "Total Score",
                accessor: "totalScore",
                width: 120,
                headerClassName: 'table-header',
                filterable: false,
                Cell: props => <div style={{ textAlign: "center" }}>{props.original.totalScore}</div>,
            },
        ];

        return (
            <div className="group-members">
                <div className="header">
                    <h2>Kids</h2>
                </div>

                <div className="button-container">
                        <button onClick={this.openModal} className="assign-students-button">
                            Assign Students to Group
                        </button>
                </div>

                <div className="mt-4 responsive-table">
                    <ReactTable
                        className="table-striped custm-table"
                        columns={columns}
                        data={childrenByVendor.content || []}
                        pageSize={pageSize}
                        page={page}
                        pages={totalPages}
                        manual
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        loading={loading}
                        filterable
                        onFilteredChange={this.onFilteredChange}
                    />
                </div>
                {this.state.isModalOpen && <AssignToGroupModal isOpen={this.state.isModalOpen} onClose={this.closeModal} groups={this.props.groups} handleGroupSearchChange={this.handleGroupSearchChange} assignToGroup={this.assignToGroup}/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    childrenByVendor: state.vendor.childrenByVendor,
    loading: state.vendor.loading,
    error: state.vendor.childrenError,
    groups: state.group.items,
    assignStudentToGroupSuccess: state.group.assign_studet_to_group_success,
    assignStudentToGroupError: state.group.assign_studet_to_group_error

});

export default connect(mapStateToProps, { fetchChildrenByVendor,fetchAllGroups, assignStudentToGroup,emptyGroup })(withRouter(KidsTable));
