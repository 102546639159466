import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
/* Components */
import DashboardContent from './DashboardContent';
/* Navbar */
import OuterHeader from '../../Layout/OuterHeader';
import InnerHeader from '../../Layout/InnerHeader';
import Dashboard from '../../Dashboard';
import Footer from '../../Layout/Footer';
/* CSS */
import './../../../../src/css/group.css';

export class VendorDashboard extends Component {
    render() {
        return (
            <div className="container">
                <OuterHeader />
                <div className="container p-10">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                {/* <InnerHeader /> */}
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <div className="box-container">
                                                <DashboardContent />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
});

export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(VendorDashboard)
);
