import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../../../config';
import firebase from 'firebase/app';
import { connect } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import '../../../css/StudentActivity.css';
import { useLocation } from 'react-router-dom';
import { DETAILED_VIEWS_REPORT, DETAILED_ACTIVITIES_REPORT } from '../../../routes';
import{ formatMinutesToHoursAndMinutes } from "../../../utils/TimeUtil";

/* Components */

import OuterHeader from '../../Layout/OuterHeader';
import InnerHeader from '../../Layout/InnerHeader';
import Dashboard from '../../Dashboard';
import Footer from '../../Layout/Footer';
import Loader from '../../Layout/Loader';

/* Actions */
import { getUserData } from '../../../Actions/MobilePaymentProfileAction';
import { getStudentActivitiesReport, getRadiosReport, getChildWatchingsReport, getWeeklyUsageByDay } from '../../../Actions/StudentActivityAction';

import jeelSongsImg from '../../../images/studentactivity/jeel-songs.png';
import jeelRadioImg from '../../../images/studentactivity/jeel-radio.png';
import jeelSeriesImg from '../../../images/studentactivity/jeel-series.png';
import jeelStoriesImg from '../../../images/studentactivity/jeel-stories.png';


/* Routes */
import { PARTNERHOME, STUDENTACTIVITY } from '../../../routes';

/* Constants */
import { PERIODS, PERIOD_KEYS, DAY_ORDER } from '../../../constants'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

class StudentActivity extends Component {


    state = {
        selectedStudentId: this.props.match.params.studentId,
        startDate: '12/12/2022',
        endDate: '12/12/2024',
        songActivities: [],
        storyActivities: [],
        seriesActivities: [],
        radioActivities: [],
        discoverSeriesActivities: [],
        songReports: [],
        seriesReports: [],
        discoverySeriesReports: [],
        storyReports: [],
        radioReports: [],
        totalWatchingMins: 0,
        weeklyUsage: [],
        totalUsage: 0,
        periodTotals: {
            [PERIODS.PERIOD_12AM_6AM]: { duration: 0, percentage: 0 },
            [PERIODS.PERIOD_6AM_12PM]: { duration: 0, percentage: 0 },
            [PERIODS.PERIOD_12PM_6PM]: { duration: 0, percentage: 0 },
            [PERIODS.PERIOD_6PM_12AM]: { duration: 0, percentage: 0 },
        },
        totalRadioDuration: 0,
        totalStoryDuration: 0,
        totalSeriesDuration: 0,
        totalSongDuration: 0,
        discoverSeriesDuration: 0,
        totalSeriesActivities: 0,
        fullSongReport: {},
        fullSeriesReport: {},
        fullDiscoverySeriesReport: {},
        fullStoryReport: {},
        fullRadioReport: {}
    };



    constructor(props) {
        super(props);
        if (firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((token) => {
                this.props.getUserData(token);
            });
        }
    }

    componentDidMount() {
        this.loadStudentActivities();
        this.loadRadiosReport();
        this.loadWatchingsReport();
        this.loadWeeklyUsage();
    }




    componentDidUpdate(prevProps, prevState) {
        const prevStudentId = prevProps.match.params.studentId;
        const currentStudentId = this.props.match.params.studentId;
        const { startDate, endDate } = this.state;

        // If student ID or dates change, reload data
        if (prevStudentId !== currentStudentId || prevState.startDate !== startDate || prevState.endDate !== endDate) {
            this.loadStudentActivities();
            this.loadRadiosReport();
            this.loadWatchingsReport();
            this.loadWeeklyUsage();
        }

        if (prevStudentId !== currentStudentId) {
            this.setState({ selectedStudentId: currentStudentId });
        }
    }


    loadStudentActivities = () => {
        const { startDate, endDate, selectedStudentId } = this.state;
        this.props.getStudentActivitiesReport(startDate, endDate, selectedStudentId)
            .then((activitiesReport) => {
                if (!activitiesReport) {
                    console.error("Failed to load activities");
                    return;
                }

                const { songsActivity, storiesActivity, normalSeriesActivity, discoverSeriesActivity } = activitiesReport;
                const totalSeriesActivities = normalSeriesActivity ? normalSeriesActivity.length : 0;
                const discoverSeriesActivities = discoverSeriesActivity ? discoverSeriesActivity.length : 0;

                this.setState({
                    songActivities: songsActivity || [],
                    storyActivities: storiesActivity || [],
                    seriesActivities: normalSeriesActivity || [],
                    discoverSeriesActivities: discoverSeriesActivity || [],
                    totalSeriesActivities,
                });
            })
            .catch(error => console.error("Error loading student activities:", error));
    };

    loadRadiosReport = () => {
        const { startDate, endDate, selectedStudentId } = this.state;

        this.props.getRadiosReport(startDate, endDate, selectedStudentId)
            .then((radiosReport) => {
                if (!radiosReport || !radiosReport.radioReports) {
                    console.error("Failed to load radios report: API returned undefined or empty");
                    return;
                }

                console.log("Radios Report:", radiosReport);

                this.setState({
                    radioActivities: radiosReport.radioReports,
                });
            })
            .catch((error) => {
                console.error("Error fetching radios report:", error);
            });
    };

    loadWatchingsReport = () => {
        const { startDate, endDate, selectedStudentId } = this.state;

        this.props.getChildWatchingsReport(startDate, endDate, selectedStudentId)
            .then((watchingsReport) => {
                if (!watchingsReport) {
                    console.error("Failed to load watchings report: API returned undefined or empty");
                    return;
                }

                console.log("Watchings Report:", watchingsReport);

                const songReports = (watchingsReport.songsReport && watchingsReport.songsReport.songsReports) || [];
                const seriesReports = (watchingsReport.normalSeriesesReport && watchingsReport.normalSeriesesReport.seriesReports) || [];
                const discoverySeriesReports = (watchingsReport.discoverSeriesesReport && watchingsReport.discoverSeriesesReport.seriesReports) || [];
                const storyReports = (watchingsReport.storiesReport && watchingsReport.storiesReport.storiesReports) || [];
                const radioReports = (watchingsReport.radiosReport && watchingsReport.radiosReport.radioReports) || [];

                // Calculate total watching minutes
                const totalWatchingMins =
                    songReports.length +
                    seriesReports.length +
                    discoverySeriesReports.length +
                    // storyReports.length +
                    radioReports.length;


                const totalSongDuration = songReports.length;
                const totalSeriesDuration = seriesReports.length;
                const discoverSeriesDuration = discoverySeriesReports.length;
                const totalStoryDuration = storyReports.length;
                const totalRadioDuration = radioReports.length;


                const fullSongReport = watchingsReport.songsReport || {};
                const fullSeriesReport = watchingsReport.normalSeriesesReport || {};
                const fullDiscoverySeriesReport = watchingsReport.discoverSeriesesReport || {};
                const fullStoryReport = watchingsReport.storiesReport || {};
                const fullRadioReport = watchingsReport.radiosReport || {};

                // Update state with new data
                this.setState({
                    songReports,
                    seriesReports,
                    discoverySeriesReports,
                    storyReports,
                    radioReports,
                    totalWatchingMins,
                    totalSongDuration,
                    totalSeriesDuration,
                    totalStoryDuration,
                    totalRadioDuration,
                    discoverSeriesDuration,
                    fullSongReport,
                    fullSeriesReport,
                    fullDiscoverySeriesReport,
                    fullStoryReport,
                    fullRadioReport
                });
            })
            .catch((error) => {
                console.error("Error fetching watchings report:", error);
            });
    };

    loadWeeklyUsage = () => {
        const { startDate, endDate, selectedStudentId } = this.state;

        this.props.getWeeklyUsageByDay(startDate, endDate, selectedStudentId)
            .then((weeklyUsageData) => {
                if (!weeklyUsageData) {
                    console.error("Failed to load weekly usage: API returned undefined");
                    return;
                }

                // Create an empty template for all days of the week
                const template = DAY_ORDER.map(day => ({
                    dayOfWeek: day,
                    periodFrequencies: [
                        { periodStartDate: "00:00", periodEndDate: "06:00", duration: 0, percentage: 0 },
                        { periodStartDate: "06:00", periodEndDate: "12:00", duration: 0, percentage: 0 },
                        { periodStartDate: "12:00", periodEndDate: "18:00", duration: 0, percentage: 0 },
                        { periodStartDate: "18:00", periodEndDate: "00:00", duration: 0, percentage: 0 }
                    ],
                    totalUsage: 0
                }));

                // Merge the API data with the template
                const orderedUsageData = template.map(templateDay => {
                    const matchingDay = weeklyUsageData.find(day => day.dayOfWeek === templateDay.dayOfWeek);
                    return matchingDay || templateDay;
                });

                let totalUsage = weeklyUsageData.length > 0 ? weeklyUsageData[0].totalUsage : 0;

                const periodTotals = {
                    [PERIODS.PERIOD_12AM_6AM]: { duration: 0, percentage: 0 },
                    [PERIODS.PERIOD_6AM_12PM]: { duration: 0, percentage: 0 },
                    [PERIODS.PERIOD_12PM_6PM]: { duration: 0, percentage: 0 },
                    [PERIODS.PERIOD_6PM_12AM]: { duration: 0, percentage: 0 },
                };

                orderedUsageData.forEach(day => {
                    day.periodFrequencies.forEach(period => {
                        const periodKey = this.getPeriodKey(period.periodStartDate, period.periodEndDate);
                        if (periodKey) {
                            periodTotals[periodKey].duration += period.duration;
                            periodTotals[periodKey].percentage += period.percentage;
                        }
                    });
                });

                this.setState({ weeklyUsage: orderedUsageData, totalUsage, periodTotals });
            })
            .catch((error) => {
                console.error("Error fetching weekly usage:", error);
            });
    };

    getPeriodKey = (start, end) => {
        if (start === "00:00" && end === "06:00") return PERIODS.PERIOD_12AM_6AM;
        if (start === "06:00" && end === "12:00") return PERIODS.PERIOD_6AM_12PM;
        if (start === "12:00" && end === "18:00") return PERIODS.PERIOD_12PM_6PM;
        if (start === "18:00" && end === "00:00") return PERIODS.PERIOD_6PM_12AM;
        return null;
    };

    handleDateChange = (event, dateType) => {
        const dateValue = event.target.value;
        const formattedDate = this.formatDate(new Date(dateValue));

        this.setState({ [dateType]: formattedDate });
    };
    

    formatDate(date) {
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    }

    formatDateForInput = (date) => {
        const [month, day, year] = date.split('/');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }

    handleStudentChange = (event) => {
        const newStudentId = event.target.value;

        const newPath = STUDENTACTIVITY.replace(':studentId', newStudentId);

        this.props.history.push({
            pathname: newPath,
            state: {
                children: this.props.location.state.children, // Pass children data here
            },
        });

        this.setState({ selectedStudentId: newStudentId });
    };


    render() {
        const { loading } = this.props;

        const studentId = this.props.match.params.studentId;
        const { selectedStudentId, startDate, endDate } = this.state;

        const formattedStartDate = this.formatDateForInput(startDate);
        const formattedEndDate = this.formatDateForInput(endDate);

        const childrenData = this.props.location && this.props.location.state && this.props.location.state.children;
        console.log(childrenData);

        let student = null;

        if (loading.studentActivities || loading.radiosReport || loading.watchingsReport || loading.weeklyUsage) {
            return <Loader />;
        }

        if (childrenData && Array.isArray(childrenData)) {
            // Find student based on the id
            student = childrenData.find(child => child.id.toString() === studentId);
        } else {
            console.error('childrenData is not an array');
        }

        const studentName = student ? student.name : 'Student Name Not Found';

        const { songActivities, storyActivities, seriesActivities, discoverSeriesActivities } = this.state;

        const { weeklyUsage, totalUsage, periodTotals } = this.state;

        // Convert totalUsage from minutes to hours
        const totalUsageInHours = formatMinutesToHoursAndMinutes(totalUsage);

        const CustomTooltip = ({ active, payload, label }) => {
            if (active && payload && payload.length) {
                return (
                    <div className="custom-tooltip">
                        <p className="label">{`${label}`}</p>
                        {payload.map((entry, index) => (
                            <p key={`item-${index}`} style={{ color: entry.fill }}>
                                {`${entry.name}: ${formatMinutesToHoursAndMinutes(entry.value)}`} 
                            </p>
                        ))}
                    </div>
                );
            }
            return null;
        };
        
        

        const barChartData = weeklyUsage.map(day => ({
            name: day.dayOfWeek.slice(0, 3),
            [PERIODS.PERIOD_12AM_6AM]: day.periodFrequencies[0].duration, 
            [PERIODS.PERIOD_6AM_12PM]: day.periodFrequencies[1].duration,
            [PERIODS.PERIOD_12PM_6PM]: day.periodFrequencies[2].duration,
            [PERIODS.PERIOD_6PM_12AM]: day.periodFrequencies[3].duration,
        }));
        


        const periods = PERIOD_KEYS.map((period, index) => {
            const durationInMinutes = (periodTotals[period] && periodTotals[period].duration) || 0;
            const total = totalUsage || 0;
        
            const formattedDuration = formatMinutesToHoursAndMinutes(durationInMinutes);
            const percentage = total > 0 ? ((durationInMinutes / total) * 100).toFixed(2) : 0;
        
            return {
                label: period,
                color: ['#53c654', '#85dc4c', '#b33bad', '#6a1c66'][index],
                percentage: percentage,
                hours: formattedDuration, 
            };
        });
        


        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                {/* <InnerHeader /> */}
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <div className="box-container">
                                                <div className="dashboard">
                                                    {/* Header Section with Filters */}
                                                    <div className="header">
                                                        <h1>{studentName}</h1>
                                                        <div className="filters">
                                                            <input
                                                                type="date"
                                                                onChange={(event) => this.handleDateChange(event, 'startDate')}
                                                                value={formattedStartDate} />
                                                            <input
                                                                type="date"
                                                                onChange={(event) => this.handleDateChange(event, 'endDate')}
                                                                value={formattedEndDate}
                                                            />
                                                            <select onChange={this.handleStudentChange} value={selectedStudentId || ''}>
                                                                {childrenData.map(child => (

                                                                    <option key={child.id} value={child.id}>
                                                                        {child.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {/* Usage Section */}
                                                    <div className="usage-section">
                                                        <div className="header-bar">
                                                            <p>Usage</p>
                                                            <div className="total-hours">{totalUsageInHours} Hr</div>
                                                        </div>
                                                        <div className="store-metrics">
                                                            <div className="store-metrics-header">
                                                                <h2>Store Metrics</h2>
                                                                <div className="summary-frequency">
                                                                    <p>Weekly</p>
                                                                </div>
                                                            </div>
                                                            <p className="metrics-description">Your current sales summary and activity.</p>
                                                            <div className="metrics-summary">
                                                                {periods.map((period, index) => (
                                                                    <div key={index} className="period-summary">
                                                                        <div className="vertical-line"></div>
                                                                        <div className="period-content">
                                                                            <p className="period-label">{period.label}</p>
                                                                            <div className="percentage-and-hours">
                                                                                <p className="period-percentage">{period.percentage}%</p>
                                                                                <p className="period-hours">
                                                                                    <span className="arrow">▲</span> {period.hours}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="chart-and-legend">
                                                                <BarChart
                                                                    width={550}
                                                                    height={250}
                                                                    data={barChartData}
                                                                    margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
                                                                    barCategoryGap="20%"
                                                                >
                                                                    <CartesianGrid strokeDasharray="3 3" />
                                                                    <XAxis dataKey="name" />
                                                                    <YAxis />
                                                                    <Tooltip content={<CustomTooltip />} /> 
                                                                    <Bar dataKey={PERIODS.PERIOD_12AM_6AM} stackId="a" fill="#53c654" />
                                                                    <Bar dataKey={PERIODS.PERIOD_6AM_12PM} stackId="a" fill="#85dc4c" />
                                                                    <Bar dataKey={PERIODS.PERIOD_12PM_6PM} stackId="a" fill="#b33bad" />
                                                                    <Bar dataKey={PERIODS.PERIOD_6PM_12AM} stackId="a" fill="#6a1c66" />
                                                                </BarChart>
                                                                <div className="legend-container-right">
                                                                    {periods.map((period, index) => (
                                                                        <div key={index} className="legend-item">
                                                                            <div className="legend-color" style={{ backgroundColor: period.color }}></div>
                                                                            <span>{period.label}</span>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Views and Activities Section */}
                                                    <div className="views-activities">
                                                        {/* Views Section */}
                                                        <div className="views">
                                                            <div className="views-header">
                                                                <h3>Views</h3>
                                                                <p>{this.state.totalWatchingMins} Watching mins</p>
                                                            </div>

                                                            {/* Songs View Card */}
                                                            <Link
                                                                to={{
                                                                    pathname: DETAILED_VIEWS_REPORT.replace(':type', 'songs'),
                                                                    state: { data: this.state.fullSongReport, startDate: this.state.startDate, endDate: this.state.endDate },
                                                                }}
                                                                className={`view-item ${this.state.totalSongDuration === 0 ? 'disabled' : ''}`}
                                                                style={{ pointerEvents: this.state.totalSongDuration === 0 ? 'none' : 'auto' }}
                                                            >
                                                                <img src={jeelSongsImg} alt="Songs" />
                                                                <h3>Jeel Songs</h3>
                                                                <div>
                                                                    <span>{this.state.totalSongDuration} Views</span>
                                                                </div>
                                                            </Link>

                                                            {/* Series View Card */}
                                                            <Link
                                                                to={{
                                                                    pathname: DETAILED_VIEWS_REPORT.replace(':type', 'series'),
                                                                    state: { data: this.state.fullSeriesReport, startDate: this.state.startDate, endDate: this.state.endDate },
                                                                }}
                                                                className={`view-item ${this.state.totalSeriesDuration === 0 ? 'disabled' : ''}`}
                                                                style={{ pointerEvents: this.state.totalSeriesDuration === 0 ? 'none' : 'auto' }}
                                                            >
                                                                <img src={jeelSeriesImg} alt="Series" />
                                                                <h3>Jeel Series</h3>
                                                                <div>
                                                                    <span>{this.state.totalSeriesDuration} Views</span>
                                                                </div>
                                                            </Link>

                                                            {/* Discover View Card */}
                                                            <Link
                                                                to={{
                                                                    pathname: DETAILED_VIEWS_REPORT.replace(':type', 'discover'),
                                                                    state: { data: this.state.fullDiscoverySeriesReport, startDate: this.state.startDate, endDate: this.state.endDate },
                                                                }}
                                                                className={`view-item ${this.state.discoverSeriesDuration === 0 ? 'disabled' : ''}`}
                                                                style={{ pointerEvents: this.state.discoverSeriesDuration === 0 ? 'none' : 'auto' }}
                                                            >
                                                                <img src={jeelSeriesImg} alt="Learning & Skills" />
                                                                <h3>Jeel Learning & Skills</h3>
                                                                <div>
                                                                    <span>{this.state.discoverSeriesDuration} Views</span>
                                                                </div>
                                                            </Link>

                                                            {/* Radio View Card */}
                                                            <Link
                                                                to={{
                                                                    pathname: DETAILED_VIEWS_REPORT.replace(':type', 'radio'),
                                                                    state: { data: this.state.fullRadioReport, startDate: this.state.startDate, endDate: this.state.endDate },
                                                                }}
                                                                className={`view-item ${this.state.totalRadioDuration === 0 ? 'disabled' : ''}`}
                                                                style={{ pointerEvents: this.state.totalRadioDuration === 0 ? 'none' : 'auto' }}
                                                            >
                                                                <img src={jeelRadioImg} alt="Radios" />
                                                                <h3>Jeel Radio</h3>
                                                                <div>
                                                                    <span>{this.state.totalRadioDuration} Views</span>
                                                                </div>
                                                            </Link>
                                                        </div>

                                                        {/* Activities Section */}
                                                        <div className="activities">
                                                            <div className="activities-header">
                                                                <h3>Activities</h3>
                                                                <p>{songActivities.length + storyActivities.length + seriesActivities.length + discoverSeriesActivities.length} Activities</p>
                                                            </div>

                                                            <div className="activities-list">
                                                                {/* Songs Activity Card */}
                                                                <Link
                                                                    to={{
                                                                        pathname: DETAILED_ACTIVITIES_REPORT.replace(':type', 'songs'),
                                                                        state: { data: songActivities, startDate: this.state.startDate, endDate: this.state.endDate, type: 'songs' },
                                                                    }}
                                                                    className={`activity-item ${songActivities.length === 0 ? 'disabled' : ''}`}
                                                                    style={{ pointerEvents: songActivities.length === 0 ? 'none' : 'auto' }}
                                                                >
                                                                    <img src={jeelSongsImg} alt="Songs" />
                                                                    <h3>Jeel Songs</h3>
                                                                    <span>{songActivities.length} Activities</span>
                                                                </Link>

                                                                {/* Stories Activity Card */}
                                                                {/* <Link
                                                                    to={{
                                                                        pathname: DETAILED_ACTIVITIES_REPORT.replace(':type', 'stories'),
                                                                        state: { data: storyActivities, startDate: this.state.startDate, endDate: this.state.endDate, type: 'stories' },
                                                                    }}
                                                                    className={`activity-item ${storyActivities.length === 0 ? 'disabled' : ''}`}
                                                                    style={{ pointerEvents: storyActivities.length === 0 ? 'none' : 'auto' }}
                                                                >
                                                                    <img src={jeelStoriesImg} alt="Stories" />
                                                                    <h3>Jeel Stories</h3>
                                                                    <span>{storyActivities.length} Activities</span>
                                                                </Link> */}

                                                                {/* Series Activity Card */}
                                                                <Link
                                                                    to={{
                                                                        pathname: DETAILED_ACTIVITIES_REPORT.replace(':type', 'series'),
                                                                        state: { data: seriesActivities, startDate: this.state.startDate, endDate: this.state.endDate, type: 'series' },
                                                                    }}
                                                                    className={`activity-item ${seriesActivities.length === 0 ? 'disabled' : ''}`}
                                                                    style={{ pointerEvents: seriesActivities.length === 0 ? 'none' : 'auto' }}
                                                                >
                                                                    <img src={jeelSeriesImg} alt="Series" />
                                                                    <h3>Jeel Series</h3>
                                                                    <span>{seriesActivities.length} Activities</span>
                                                                </Link>

                                                                {/* Discover Activity Card */}
                                                                <Link
                                                                    to={{
                                                                        pathname: DETAILED_ACTIVITIES_REPORT.replace(':type', 'discoverSeries'),
                                                                        state: { data: discoverSeriesActivities, startDate: this.state.startDate, endDate: this.state.endDate, type: 'discoverSeries' },
                                                                    }}
                                                                    className={`activity-item ${discoverSeriesActivities.length === 0 ? 'disabled' : ''}`}
                                                                    style={{ pointerEvents: discoverSeriesActivities.length === 0 ? 'none' : 'auto' }}
                                                                >
                                                                    <img src={jeelSeriesImg} alt="Learning & Skills" />
                                                                    <h3>Jeel Learning & Skills</h3>
                                                                    <span>{discoverSeriesActivities.length} Activities</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,
    loading: state.studentActivity.loading,
    activities: state.studentActivity.activities,
    watchingsReport: state.studentActivity.watchingsReport,
    error: state.studentActivity.error,
});

export default withFirebase(
    connect(mapStateToProps, { getUserData, getStudentActivitiesReport, getRadiosReport, getChildWatchingsReport, getWeeklyUsageByDay })(withRouter(StudentActivity))
);
