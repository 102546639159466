import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/* Components */
import GroupTable from './GroupTabel';
/* Navbar */
import OuterHeader from '../../Layout/OuterHeader';
import InnerHeader from '../../Layout/InnerHeader';
import Dashboard from '../../Dashboard';
import Footer from '../../Layout/Footer';
/* Routes */
import { ADD_GROUP } from '../../../routes';
import AddGroupModal from './AddGroupModal';
import { createGroup } from '../../../Actions/GroupActions';
import './../../../../src/css/group.css';
export class Group extends Component {
    state = {
        isModalOpen: false
    };

    openModal = () => {
        console.log('Group and students assigned');
        this.setState({ isModalOpen: true });
    };

    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

   
    render() {
        return (
            <div className="container">
                <OuterHeader />
                <div className="container p-10">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                {/* <InnerHeader /> */}
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <div className="box-container">
                                                    <button type="button"
                                                    onClick={this.openModal}
                                                    style={{ marginTop: '-10px' }}
                                                            className="seriesButtons mt-10">
                                                        + Add New Group
                                                    </button>
                                                    <AddGroupModal 
                                                isOpen={this.state.isModalOpen}
                                                onClose={this.closeModal}
                                            />

                                                <h5 className="main-title mt-2 font-weight-bold">
                                                    Groups
                                                </h5>
                                                <div className="card mt-3 p-0">
                                                    <div className="card-body p-4">
                                                        {/* Admins Table */}
                                                        <form className="alluserMng" onSubmit={(e) => e.preventDefault()}>
                                                            <div className="mt-4 responsive-table">
                                                                <GroupTable />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    // Define any specific state mappings needed here
});

export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(Group)
);
