import {
    PARTENER_BATCH_LOAD, 
    PARTENER_BATCH_FETCH_SUCCESSFULLY,
    PARTENER_BATCH_FETCH_ERROR,
    
    PARTENER_BATCH_Voucher_LOAD,
    PARTENER_BATCH_Voucher_FETCH_SUCCESSFULLY,
    PARTENER_BATCH_Voucher_FETCH_ERROR,
    
    PARTENER_Assign_LOAD,
    PARTENER_Assign_ERROR,
    PARTENER_Assign_SUCCESSFULLY,
    PARTENER_Assign_Empty,
    
} from '../../../Actions/Types'
import axios from 'axios'
import { post,get } from 'axios';

   
    
export const getPartenerBatches = (packageType,pageNum,period,batchsPerPage) => {
		
    return (dispatch, getState, { getFirebase }) => {
	
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            
            
			dispatch({ type: PARTENER_BATCH_LOAD });
	
	        const url = "/Vendor/batches";
	        const formData = new FormData();
	        formData.append("packageType",packageType);
	        formData.append("period",period);
	        formData.append("page",pageNum);
	        formData.append("size",batchsPerPage);
	        
	        const config = {
	            headers: {
	                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
	                'X-Authorization-Firebase': token
	            }
	        }
	       
	       post(url,formData, config).then((res) => {
				//console.log('res in useEffect : '+JSON.stringify(res.data,null,2));
	
				dispatch({ type: PARTENER_BATCH_FETCH_SUCCESSFULLY, payload: res.data })
				//setListOfPartenerBatches(res.data.content);
				//setTotalPages(res.data.totalPages)
	            //setLoading(false);                                
	
	        }).catch((Error) => {
				 dispatch({ type: PARTENER_BATCH_FETCH_ERROR, payload: Error })
	             //console.log('Error while getBatches :'+Error)
	        })            

        })
        
        

    }
}

export const getBatchesVouchers = (listOfFilteredCriteria,batchId,page,pageSize) => {
		
    return (dispatch, getState, { getFirebase }) => {
	
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            
            
           dispatch({ type: PARTENER_BATCH_Voucher_LOAD });
            
		   const url = `/Vendor/batch/${batchId}/vouchers?page=${page}&size=${pageSize}`;
           
           const formData = new FormData();
            
            for(let i=0;i<listOfFilteredCriteria.length;i++)
            {
				formData.append("names",listOfFilteredCriteria[i].name);
				formData.append("values",listOfFilteredCriteria[i].value);
			}

	        
	        const config = {
	            headers: {
	                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
	                'X-Authorization-Firebase': token
	            }
	        }
	       
	       post(url,formData, config).then((res) => {
				//console.log('res in useEffect : '+JSON.stringify(res.data,null,2));
	
				dispatch({ type: PARTENER_BATCH_Voucher_FETCH_SUCCESSFULLY, payload: res.data })
				//setListOfPartenerBatches(res.data.content);
				//setTotalPages(res.data.totalPages)
	            //setLoading(false);                                
	
	        }).catch((Error) => {
				 dispatch({ type: PARTENER_BATCH_Voucher_FETCH_ERROR, payload: Error })
	             //console.log('Error while getVouchers :'+Error)
	        })            

        })               

    }
}


export const uploadPartenerBatchAssignment = (file,batchId) => {
	
    return (dispatch, getState, { getFirebase }) => {
	
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            
            //console.log('dispatch assign load');
			dispatch({ type: PARTENER_Assign_LOAD });
	
	
	        const url = `/Vendor/assign/upload`;
	        const formData = new FormData();
	        formData.append("file",file);
	        formData.append("batchId",batchId);
	        
	        const config = {
	            headers: {
	                //'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
	                //"Content-Type": "multipart/form-data",
	                'X-Authorization-Firebase': token
	            }
	        }
	       
	       post(url,formData, config).then((res) => {
				//console.log('res after Import : '+JSON.stringify(res,null,2));
				
				if(res.status!=200)
					dispatch({ type: PARTENER_Assign_ERROR, payload: res.data.msg })
	
				const msg = res.data.msg;
				//console.log('res msg: '+msg);
				
				if(msg == 'SUCCESS')
					dispatch({ type: PARTENER_Assign_SUCCESSFULLY, payload: msg })
				else
					dispatch({ type: PARTENER_Assign_ERROR, payload: res.data.errors })
						   
	        }).catch((Error) => {
		
				 //console.log('Error while Assignmenttttttt :'+Error)
				 //console.log('e: '+JSON.stringify(Error,null,2));
				 const arr = [];
				 arr.push(Error.response.data.message);
				 dispatch({ type: PARTENER_Assign_ERROR, payload: arr })
	             
	             
	             //alert('Error :'+Error);
	        })            

        })

    }
}


export const emptyPartenerBatchAssignment = (file,batchId) => {
	
	return (dispatch, getState, { getFirebase }) => {
	
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            
            //console.log('dispatch empty load');
			//dispatch({ type: PARTENER_Assign_LOAD });
			dispatch({ type: PARTENER_Assign_Empty });
	       
	       /*
	       setTimeout(function() {
	    		dispatch({ type: PARTENER_Assign_ERROR });
			}, 3000);
			*/		                

        })

    }
}
