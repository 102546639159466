
import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Link, NavLink, withRouter } from "react-router-dom";


import { connect } from 'react-redux';
import firebase from 'firebase/app';

/*Navbar*/
import OuterHeader from '../../Layout/OuterHeader';
import InnerHeader from '../../Layout/InnerHeader';
import Dashboard from '../../Dashboard';
import Footer from '../../Layout/Footer';
import '../../../css/partener.css';
import '../../../css/AppCss/dropzone.css'
import uploadImg from '../../../images/upload.png';
import { FileUploader } from "./FileUploader";

import  Pagination from './Pagination';
import PartenerBatches from './PartenerBatch';
import {getPartenerBatches,uploadPartenerBatchAssignment,emptyPartenerBatchAssignment} from './PartenerBatchActions';

import { useLocation } from 'react-router-dom'   

import { useState } from "react";
import { useEffect } from 'react';
import {getFirebase} from 'react-redux-firebase';
import { get,post } from 'axios';
import axios from 'axios'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Partener_Vouchers } from '../../../routes';



 const PartenerAssignationAsDialog = ({ uploadPartenerBatchAssignment,emptyPartenerBatchAssignment,loading,success,error,emptyAlert,assignErrors,batchId2,partenerBatcheItems }) => {
	
const [batchId, setBatchId] = useState(''); 
const [file, setFile] = useState(null);
const [fileName, setFileName] = useState('');

console.log('batchId is : '+JSON.stringify(batchId2,null,2));
console.log('partenerBatcheItems is : '+JSON.stringify(partenerBatcheItems,null,2));          
// =======================================================================================================================================================        
useEffect(() => {    
    	emptyPartenerBatchAssignment(); 
    	getPartenerBatches('',0,'',100);   
  }, []);  
  
    
// =======================================================================================================================================================
const downloadExcelFile = () => {
    // Define the header row
    const headers = [
        ["First Name", "Last Name", "Email", "password(Optional)", "Country"]
    ];

    // Create a new workbook and add a sheet
    const worksheet = XLSX.utils.aoa_to_sheet(headers);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to a binary string
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Create a Blob from the binary string and download it
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "UserTemplate.xlsx");
};
  const uploadAssignment = ()=>
  {
		//console.log('upload Clicked :'+batchId);
		if(!batchId || batchId.length < 1){
			alert('Please Select a Batch ');
			return;
		}
	
		if(file){	
			uploadPartenerBatchAssignment(file,batchId);
			setFile(null);
		}else{
			alert('Please Choose a file to Upload');
		}
			
  }


	const onFileChange = event => {
        //this.setState({ selectedFile: event.target.files[0] });
        //this.setState({ selectedFileName: event.target.files[0].name });
        
        //console.log('File is selected : '+event.target.files[0]);
        //console.log('BatchId after file :'+batchId);
        
        setFile(event.target.files[0]);
        
    };
  
  const handleFileSelection = (file) => {    
    setFile(file);
    setFileName(file.name);
  };
  
	const handleBatchChange = (e, { value }) => {
	    //console.log('batch selected: '+value);
	    setBatchId(value);
	};
  

const partenerBatchesOptions = [];
        for (let i = 0; i < partenerBatcheItems.content.length; i++) {
            partenerBatchesOptions.push({
                key: partenerBatcheItems.content[i].batchId,
                text: partenerBatcheItems.content[i].batchNo,
                value: partenerBatcheItems.content[i].batchId
            })
        }
          


/* =====================================================================================================================================================*/
/* =====================================================================================================================================================*/
/* =====================================================================================================================================================*/


        return (
           					   <div className="dlgContents">
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                           
                                            <div className="box-container">                                                
                                                

                                                <h4 className="main-title mt-2 font-weight-bold pl-1"> Upload Assignment File </h4>
    
    											<div className ="row mb-3">
													<label htmlFor="batch_id" className="label">
                                                            <span className="required_input">*</span>
                                                                Select Batch 
                                                    </label>
                                                    <br />
{/*options={partenerBatcheItems.content*/}
                                                        <Dropdown fluid search selection
                                                            placeholder='Select Batch'
                                                            id="batch_id"
                                                            onChange={handleBatchChange}
                                                            options={partenerBatchesOptions}
                                                                                                                        
                                                        />
                                                        <br />    					
                                                </div>
                                                
                                                <div className ="row">
                                                	<div className="form-group">
					                                    <label className="mr-5  ml-3">Download Template</label>
					                                    <button onClick={()=>downloadExcelFile()}  id="nextButton">Download</button>
					                                </div>
					                                
                                                </div>   
					<div className ="row">
						<div className="form-group">
	
	            
	            	  {loading ?  
				      	<h2>Uploading......</h2>
				      : 
				        <>
				        	<label className="mr-5 ml-3">Select File</label>  
				      		<FileUploader handleFile={handleFileSelection} /> 
				      		<p className="ml-3 text-primary">{fileName}</p> 
				      		<br/><br/><br/>
				      						      			
				      		<button id="nextButton" className="ml-3" onClick={uploadAssignment} >Upload</button>
				      			
				      	</> 
		              }
	            
						</div>
					</div>                                                
                                                
                                                
                                                
                                                {success ?
	                                                <div className="row m-5">
	                                                	<div className="col-3"></div> 
	                                                	<div className="col-6 text-success"> <h3> File uploaded Successfully </h3> </div>  
	                                                </div>
                                                :''}
                                                
                                                {error ?
	                                                <div className="row m-5">
	                                                	<div className="col-1"></div> 
	                                                	{assignErrors?
	                                                		assignErrors.map((error, index) => (   
	                                                			<div className="col-10 text-danger" key="index">  {error?JSON.stringify(error):''}  </div>
	                                                	)):''}  
	                                                </div>
                                                :''}                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               </div>
        )        
}

const mapStateToProps = state => ({
    // userPaymentProfile: state.userPaymentProfile.item
    loading: state.partenerBatchReducer.State_Assign_loading,    
    
    error: state.partenerBatchReducer.State_Assign_Error,
    success: state.partenerBatchReducer.State_Assign_sucessfully,
    emptyAlert: state.partenerBatchReducer.State_Assign_empty,
    assignErrors: state.partenerBatchReducer.State_Assign_Error_List,
    
    partenerBatcheItems: state.partenerBatchReducer.State_items,
    parenerBatchLoading: state.partenerBatchReducer.State_loading,
    
    parenerBatchError: state.partenerBatchReducer.State_Partener_Batch_Get_Error,
    parenerBatchSuccess: state.partenerBatchReducer.State_Partener_Batch_Get_sucessfully,
    
});

export default connect( mapStateToProps, { uploadPartenerBatchAssignment,emptyPartenerBatchAssignment } )(withRouter(PartenerAssignationAsDialog));
    
