import Group from '../components/VendorPanel/Group/Group';
import {
    FETCH_ALL_GROUP, LOAD_GROUP, ERROR_LOADING_ALL_GROUP,
    ADD_GROUP_SUCCESSFULLY, ADD_GROUP_ERROR,
    UPDATE_GROUP_SUCCESSFULLY, UPDATE_GROUP_ERROR,
    GET_GROUP_DATA_SUCCESSFULLY, GET_GROUP_DATA_ERROR,
    EMPTY_GROUP,LOAD_GROUP_PAGE_OR_FILTER,
    LOAD_MEMBERS,FETCH_MEMBERS_SUCCESS,FETCH_MEMBERS_ERROR,FETCH_DASHBOARD_SUCCESS,FETCH_DASHBOARD_ERROR,DELETE_GROUP_SUCCESSFULLY,
    ASSIGN_STUDENT_TO_GROUP_SUCCESS, ASSIGN_STUDENT_TO_GROUP_ERROR,LOAD_ASSIGN_GROUP
} from './Types';
import axios from 'axios';

// Fetch All Groups
export const fetchAllGroups = (name = '', page = 0, size = 10,firstTime = false) => {
    return (dispatch, getState, { getFirebase }) => {
        if(firstTime){
        dispatch({ type: LOAD_GROUP, payload: 'loading' });}
    else {dispatch({ type: LOAD_GROUP_PAGE_OR_FILTER, payload: '' });}
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            const url = `/api/groups/get-all-groups?name=${name}&page=${page}&size=${size}`;
            const config = {
                headers: {
                    'content-type': 'application/json',
                    'X-Authorization-Firebase': token
                }
            };

            axios.get(url, config)
                .then((res) => {
                    dispatch({ type: FETCH_ALL_GROUP, payload: res.data });
                })
                .catch((error) => {
                    dispatch({ type: ERROR_LOADING_ALL_GROUP, payload: error.message });
                });
        });
    };
};

// Fetch Group by ID
export const getGroupData = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: LOAD_GROUP, payload: 'loading' });
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            const url = `/api/groups/${id}`;
            const config = {
                headers: {
                    'content-type': 'application/json',
                    'X-Authorization-Firebase': token
                }
            };

            axios.get(url, config)
                .then((res) => {
                    dispatch({ type: GET_GROUP_DATA_SUCCESSFULLY, payload: res.data });
                })
                .catch((error) => {
                    dispatch({ type: GET_GROUP_DATA_ERROR, payload: error.message });
                });
        });
    };
};

// Create a New Group
export const createGroup = (group) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: LOAD_GROUP, payload: 'loading' });
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            const url = `/api/groups/create-group`;
            const config = {
                headers: {
                    'content-type': 'application/json',
                    'X-Authorization-Firebase': token
                }
            };

            axios.post(url, group, config)
                .then((res) => {
                    dispatch({ type: ADD_GROUP_SUCCESSFULLY, payload: res.data });
                })
                .catch((error) => {
                    dispatch({ type: ADD_GROUP_ERROR, payload: error.message });
                });
        });
    };
};

// Update Group
export const editGroup = (id, updatedGroup) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: LOAD_GROUP, payload: 'loading' });
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            const url = `/api/groups/update-group/${id}`;
            const config = {
                headers: {
                    'content-type': 'application/json',
                    'X-Authorization-Firebase': token
                }
            };

            axios.put(url, updatedGroup, config)
                .then((res) => {
                    dispatch({ type: UPDATE_GROUP_SUCCESSFULLY, payload: res.data });
                })
                .catch((error) => {
                    dispatch({ type: UPDATE_GROUP_ERROR, payload: error.message });
                });
        });
    };
};

// Delete Group
export const deleteGroup = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        return new Promise((resolve, reject) => {
        dispatch({ type: LOAD_GROUP, payload: 'loading' });
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = `/api/groups/${id}`;
            const config = {
                headers: {
                    'content-type': 'application/json',
                    'X-Authorization-Firebase': token
                }
            };

            axios.post(url,{}, config)
                .then(() => {
                    dispatch({ type: DELETE_GROUP_SUCCESSFULLY, payload: id });
                })
                .catch((error) => {
                    dispatch({ type: ERROR_LOADING_ALL_GROUP, payload: error.message });
                });
        });
    });
    };
};

// Clear Group State
export const emptyGroup = () => (dispatch) => {
    dispatch({ type: EMPTY_GROUP, payload: '' });
};


export const fetchGroupMembers = (startDate, endDate, groupId, page, size, name, email) => {
    return (dispatch, getState, { getFirebase }) => {
        return new Promise((resolve, reject) => {
            const firebase = getFirebase();

            firebase.auth().currentUser.getIdToken()
                .then((token) => {
                    dispatch({ type: LOAD_MEMBERS });

                    const config = {
                        headers: {
                            'X-Authorization-Firebase': token,
                        },
                        params: {
                            groupId,
                            startDate,
                            endDate,
                            page,
                            size,
                            name,
                            email
                        },
                    };

                    const url = '/Child/findChildrenByGroupId';

                    axios.get(url, config)
                        .then((response) => {
                            dispatch({
                                type: FETCH_MEMBERS_SUCCESS,
                                payload: response.data.content,
                            });
                            resolve(response.data);
                        })
                        .catch((error) => {
                            dispatch({
                                type: FETCH_MEMBERS_ERROR,
                                payload: error.message,
                            });
                            reject(error);
                        });
                })
                .catch((error) => {
                    dispatch({
                        type: FETCH_MEMBERS_ERROR,
                        payload: error.message,
                    });
                    reject(error);
                });
        });
    };
};

// Fetch Group Dashboard
export const fetchGroupDashboard = (groupId) => {
    return (dispatch, getState, { getFirebase }) => {
        return new Promise((resolve, reject) => {
        dispatch({ type: LOAD_GROUP, payload: 'loading' });
        const firebase = getFirebase();
        
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = `/api/groups/getGroupDashboard?groupId=${groupId}`;
            const config = {
                headers: {
                    'content-type': 'application/json',
                    'X-Authorization-Firebase': token
                }
            };

            axios.get(url, config)
                .then((res) => {
                    dispatch({ type: FETCH_DASHBOARD_SUCCESS, payload: res.data });
                })
                .catch((error) => {
                    dispatch({ type: FETCH_DASHBOARD_ERROR, payload: error.message });
                });
        }).catch((error) => {
            dispatch({ type: FETCH_DASHBOARD_ERROR, payload: error.message });
        });
    });
    };
};

export const assignStudentToGroup = (groupId,studentsList) => {
    return (dispatch, getState, { getFirebase }) => {
        return new Promise((resolve, reject) => {
        dispatch({ type: LOAD_ASSIGN_GROUP, payload: 'loading' });
        const firebase = getFirebase();
        
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = `/api/groups/assignStudentsToGroup`;
            const config = {
                headers: {
                    'content-type': 'application/json',
                    'X-Authorization-Firebase': token
                }
            };
            const GroupData = new FormData();
            GroupData.append('groupId', groupId);
            GroupData.append('studentsList',studentsList);

            axios.post(url, GroupData, config)
                .then((res) => {
                    dispatch({ type: ASSIGN_STUDENT_TO_GROUP_SUCCESS, payload: res.data });
                })
                .catch((error) => {
                    dispatch({ type: ASSIGN_STUDENT_TO_GROUP_ERROR, payload: error.message });
                });
        }).catch((error) => {
            dispatch({ type: ASSIGN_STUDENT_TO_GROUP_ERROR, payload: error.message });
        });
    });
    };
};

export const editGroupNameAndStatus = (id, updatedGroupDTO) => {
    return (dispatch, getState, { getFirebase }) => {
        return new Promise((resolve, reject) => {
            dispatch({ type: LOAD_GROUP, payload: 'loading' });
            const firebase = getFirebase();
            firebase.auth().currentUser.getIdToken().then((token) => {
                const url = `/api/groups/update-name-status/${id}`;
                const config = {
                    headers: {
                        'content-type': 'application/json',
                        'X-Authorization-Firebase': token,
                    },
                };

                axios
                    .put(url, updatedGroupDTO, config)
                    .then((res) => {
                        dispatch({ type: UPDATE_GROUP_SUCCESSFULLY, payload: res.data });
                        resolve(res.data); 
                    })
                    .catch((error) => {
                        dispatch({ type: UPDATE_GROUP_ERROR, payload: error.message });
                        reject(error);
                    });
            }).catch((error) => {
                dispatch({ type: UPDATE_GROUP_ERROR, payload: error.message });
                reject(error);
            });
        });
    };
};
