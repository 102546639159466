import ReactTable from 'react-table';
import React, { Component } from 'react';
import Loader from '../Layout/Loader';
import PieChart from 'react-minimal-pie-chart';

class SubscriptionReportDashboard extends Component {
    usedColors = [];
    constructor(props) {
        super(props);
        this.state = { color: '' };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.color !== this.state.color) {
            this.usedColors = [this.state.color];
        }
    }

    generateColor = () => {
        let newColor;
        do {
            // Generate a random color in hexadecimal format
            newColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
        } while (this.usedColors.includes(newColor));
        this.usedColors.push(newColor);
        return newColor;
    };

    render() {
        const columns = [
            {
                Header: 'Payment Name',
                accessor: 'nameOfpaymentMethod',
                resizable: true,
                headerClassName: 'table-header',
                width: 140,
                Cell: props => {
                    const className = props.original.nameOfpaymentMethod === 'Total' ? 'table-header' : '';
                    return <div style={{ textAlign: 'center' }} className={className}>{props.original.nameOfpaymentMethod}</div>;
                },
            },
            {
                Header: 'Monthly Count',
                accessor: 'monthlyCount',
                resizable: true,
                headerClassName: 'table-header',
                width: 140,
                Cell: props => {
                    const className = props.original.nameOfpaymentMethod === 'Total' ? 'table-header' : '';
                    return <div style={{ textAlign: 'center' }} className={className}>{props.original.totalNumberOfSubscriptionsMonthly}</div>;
                },
            },
            {
                Header: 'Yearly Count',
                accessor: 'yearlyCount',
                resizable: true,
                headerClassName: 'table-header',
                width: 140,
                Cell: props => {
                    const className = props.original.nameOfpaymentMethod === 'Total' ? 'table-header' : '';
                    return <div style={{ textAlign: 'center' }} className={className}>{props.original.totalNumberOfSubscriptionsYearly}</div>;
                },
            },
            {
                Header: 'Weekly Count',
                accessor: 'weeklyCount',
                resizable: true,
                headerClassName: 'table-header',
                width: 140,
                Cell: props => {
                    const className = props.original.nameOfpaymentMethod === 'Total' ? 'table-header' : '';
                    return <div style={{ textAlign: 'center' }} className={className}>{props.original.totalNumberOfSubscriptionsWeekly}</div>;
                },
            },
            {
                Header: 'Custom Count',
                accessor: 'customCount',
                resizable: true,
                headerClassName: 'table-header',
                width: 140,
                Cell: props => {
                    const className = props.original.nameOfpaymentMethod === 'Total' ? 'table-header' : '';
                    return <div style={{ textAlign: 'center' }} className={className}>{props.original.totalNumberOfSubscriptionsCustom}</div>;
                },
            },
            {
                Header: 'Total Count',
                accessor: 'counter',
                resizable: true,
                headerClassName: 'table-header',
                width: 140,
                Cell: props => {
                    const className = props.original.nameOfpaymentMethod === 'Total' ? 'table-header' : '';
                    return <div style={{ textAlign: 'center' }} className={className}>{props.original.totalNumberOfSubscriptions}</div>;
                },
            },
        ];

        const { SubsReport, error, loading } = this.props;
        const pageSize = SubsReport.length;
        let table = null;
        let chart = null;

        if (loading === true) {
            table = (
                <center>
                    <div>
                        <Loader />
                    </div>
                </center>
            );
        } else if (error === '' && loading === false) {
            this.usedColors.length = 0;
            const newData = SubsReport.map(({ nameOfpaymentMethod, totalNumberOfSubscriptions }) => ({
                title: nameOfpaymentMethod,
                value: totalNumberOfSubscriptions,
                color: this.generateColor(),
                label: false,
            }));

            // Calculate sums
            const sumMonthlyCount = SubsReport.reduce((acc, curr) => acc + curr.totalNumberOfSubscriptionsMonthly, 0);
            const sumYearlyCount = SubsReport.reduce((acc, curr) => acc + curr.totalNumberOfSubscriptionsYearly, 0);
            const sumCustomCount = SubsReport.reduce((acc, curr) => acc + curr.totalNumberOfSubscriptionsCustom, 0);
            const sumWeeklyCount = SubsReport.reduce((acc, curr) => acc + curr.totalNumberOfSubscriptionsWeekly, 0);
            const sumTotalCount = SubsReport.reduce((acc, curr) => acc + curr.totalNumberOfSubscriptions, 0);

            // Create table data with an optional totals row
            const tableData = [...SubsReport];

            // Add totals row if SubsReport is not empty
            if (SubsReport.length > 0) {
                const totalsRow = {
                    nameOfpaymentMethod: 'Total',
                    totalNumberOfSubscriptionsMonthly: sumMonthlyCount,
                    totalNumberOfSubscriptionsYearly: sumYearlyCount,
                    totalNumberOfSubscriptionsCustom: sumCustomCount,
                    totalNumberOfSubscriptionsWeekly: sumWeeklyCount,
                    totalNumberOfSubscriptions: sumTotalCount,
                };
                tableData.push(totalsRow);
            }

            table = (
                <div>
                    <ReactTable
                        className="table-striped custm-table"
                        columns={columns}
                        data={tableData}
                        showPagination={false}
                        pageSize={pageSize === 0 ? 3 : pageSize + (SubsReport.length > 0 ? 1 : 0)} // Add one for the totals row if applicable
                    />
                </div>
            );

            chart = (
                <div>
                    <div>
                        <PieChart data={newData} />
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                        {this.usedColors.map((color, index) => (
                            <div key={color} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                <div style={{ width: '20px', height: '20px', backgroundColor: color }}></div>
                                <span style={{ marginLeft: '5px' }}>{SubsReport[index].nameOfpaymentMethod}</span>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        return (
            <div style={{ display: 'flex', columnGap: '20px' }}>
                <div style={{ width: '80%' }}>{table}</div>
                <div style={{ width: '20%' }}>{chart}</div>
            </div>
        );
    }
}

export default SubscriptionReportDashboard;
