import { 
    LOAD_STUDENT_ACTIVITIES, GET_STUDENT_ACTIVITIES_SUCCESS, GET_STUDENT_ACTIVITIES_ERROR,
    LOAD_RADIOS_REPORT, GET_RADIOS_REPORT_SUCCESS, GET_RADIOS_REPORT_ERROR,
    LOAD_WATCHINGS_REPORT, GET_WATCHINGS_REPORT_SUCCESS, GET_WATCHINGS_REPORT_ERROR,
    LOAD_WEEKLY_USAGE, GET_WEEKLY_USAGE_SUCCESS, GET_WEEKLY_USAGE_ERROR,


} from '../Actions/Types';

const initialState = {
    loading: {
        studentActivities: false,
        radiosReport: false,
        watchingsReport: false,
        weeklyUsage: false
    },
    activities: [], 
    radiosReport: [],
    watchingsReport: [], 
    weeklyUsage: [],
    error: null,
};

const studentActivityReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_STUDENT_ACTIVITIES:
            return {
                ...state,
                loading: { ...state.loading, studentActivities: true },
                error: null,
            };
        case GET_STUDENT_ACTIVITIES_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, studentActivities: false },
                activities: action.payload,
                error: null,
            };
        case LOAD_RADIOS_REPORT:
            return {
                ...state,
                loading: { ...state.loading, radiosReport: true },
                error: null,
            };
        case GET_RADIOS_REPORT_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, radiosReport: false },
                radiosReport: action.payload,
                error: null,
            };
        case LOAD_WATCHINGS_REPORT:
            return {
                ...state,
                loading: { ...state.loading, watchingsReport: true },
                error: null,
            };
        case GET_WATCHINGS_REPORT_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, watchingsReport: false },
                watchingsReport: action.payload,
                error: null,
            };
        case LOAD_WEEKLY_USAGE:
            return {
                ...state,
                loading: { ...state.loading, weeklyUsage: true },
                error: null,
            };
        case GET_WEEKLY_USAGE_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, weeklyUsage: false },
                weeklyUsage: action.payload,
                error: null,
            };
        case GET_STUDENT_ACTIVITIES_ERROR:
        case GET_RADIOS_REPORT_ERROR:
        case GET_WATCHINGS_REPORT_ERROR:
        case GET_WEEKLY_USAGE_ERROR:
            return {
                ...state,
                loading: {
                    studentActivities: false,
                    radiosReport: false,
                    watchingsReport: false,
                    weeklyUsage: false
                },
                error: action.payload,
            };
        default:
            return state;
    }
};


export default studentActivityReducer;
