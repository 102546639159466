import React, { Component } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import{ formatMinutesToHoursAndMinutes } from "../../../utils/TimeUtil";

class ContentSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'Series',
            barData: [],
            top10: 'Top 10 Series'
        };
    }

    componentDidMount() {
        this.updateBarData();
    }

    componentDidUpdate(prevProps, prevState) {
        // Update barData when activeTab changes or props change
        if (prevState.activeTab !== this.state.activeTab || prevProps !== this.props) {
            this.updateBarData();
        }
    }

    updateBarData = () => {
        const { activeTab } = this.state;
        const { series, videos, radioChannels, games } = this.props;
        let barData = [];
        let top10 = 'Top 10 Series';

        if (activeTab === 'Series') {
            top10 = 'Top 10 Series';
            barData = series.map(item => ({ name: item.name, views: item.numberOfHours }));
        } else if (activeTab === 'Videos') {
            top10 = 'Top 10 Videos';
            barData = videos.map(item => ({ name: item.name, views: item.numberOfHours }));
        } else if (activeTab === 'Radio Channels') {
            top10 = 'Top 10 Radio Channels';
            barData = radioChannels.map(item => ({ name: item.name, views: item.numberOfHours }));
        } else if (activeTab === 'Games') {
            top10 = 'Top 10 Games';
            barData = games.map(item => ({ name: item.name, views: item.numberOfHours }));
        }

        this.setState({ barData, top10 });
    };

    handleTabChange = (tab) => {
        this.setState({ activeTab: tab });
    };

    renderProgressBar = (name, value) => {
        const maxViews = Math.max(...this.state.barData.map(item => item.views), 1);
        return (
            <div className="progress-bar-row" key={name}>
                <span className="progress-bar-name">{name}</span>
                <div className="progress-bar">
                    <div className="progress-fill" style={{ width: `${(value / maxViews) * 100}%` }}></div>
                </div>
                <span className="progress-bar-value">{value}</span>
            </div>
        );
    };

    render() {
        const { pieData } = this.props;
        const { barData, top10, activeTab } = this.state;
        const MIN_ANGLE = 10;

        return (
            <div className="content-section">
                <div className="content-views">
                    <h3>Content Views</h3>
                    <ResponsiveContainer width="100%" height={300} minWidth={350}>
                        <PieChart>
                            <Pie
                                data={pieData}
                                dataKey="minutes"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                outerRadius={100}
                                fill="#8884d8"
                                label={({ value }) => formatMinutesToHoursAndMinutes(value)} 
                                minAngle={MIN_ANGLE}
                            >
                                {pieData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                    <div className="pie-legend">
                        {pieData.map((entry, index) => (
                            <div key={index} className="legend-item">
                                <span className="legend-color" style={{ backgroundColor: entry.color }}></span>
                                {entry.name}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="top-series">
                    <h3>{top10}</h3>
                    <div className="tabs-dash">
                        {['Series', 'Videos', 'Radio Channels', 'Games'].map((tab, index) => (
                            <button
                                key={index}
                                className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                                onClick={() => this.handleTabChange(tab)}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>
                    <div className="progress-bars">
                        {barData.map((item) => this.renderProgressBar(item.name, item.views))}
                    </div>
                </div>
            </div>
        );
    }
}

export default ContentSection;
