import {
    PARTENER_BATCH_LOAD, 
    PARTENER_BATCH_FETCH_SUCCESSFULLY,
    PARTENER_BATCH_FETCH_ERROR,
    PARTENER_BATCH_Voucher_LOAD,
    PARTENER_BATCH_Voucher_FETCH_SUCCESSFULLY,
    PARTENER_BATCH_Voucher_FETCH_ERROR,
    PARTENER_Assign_LOAD,
    PARTENER_Assign_SUCCESSFULLY,
    PARTENER_Assign_ERROR,
    PARTENER_Assign_Empty,

} from '../Actions/Types'


const initialState = {
    State_items: [],
    State_item: {},
    State_loading: '',
    State_TotalPages: '0',

        
    State_Partener_Batch_Get_sucessfully: '',
    State_Partener_Batch_Get_Error: '',

	State_Assign_sucessfully:false,
	State_Assign_Error:false,
	State_Assign_Error_List:[],
	State_Assign_loading:false,
	State_Assign_empty: false,
}

export default function (state = initialState, action) {
    switch (action.type) {

        
        case PARTENER_BATCH_LOAD:
            return {
                ...state,
                State_loading: true
            }                   
            
        case PARTENER_BATCH_FETCH_SUCCESSFULLY:
            return {
                ...state,
                State_items: action.payload,
                State_Partener_Batch_Get_sucessfully: true,
                State_Partener_Batch_Get_Error: '',
                State_loading: false,
                State_TotalPages:action.payload.totalPages
            }
            
        case PARTENER_BATCH_FETCH_ERROR:
            return {
                ...state,
                State_Partener_Batch_Get_Error: action.payload,
                State_Partener_Batch_Get_sucessfully: false,
                State_loading: false,
                //State_TotalPages:'0'
            }
            
            
            
        case PARTENER_BATCH_Voucher_LOAD:
            return {
                ...state,
                State_loading: true
            }                   
            
        case PARTENER_BATCH_Voucher_FETCH_SUCCESSFULLY:
            return {
                ...state,
                State_items: action.payload,
                State_Partener_Batch_Get_sucessfully: true,
                State_Partener_Batch_Get_Error: '',
                State_loading: false,
                State_TotalPages:action.payload.totalPages
            }
            
        case PARTENER_BATCH_Voucher_FETCH_ERROR:
            return {
                ...state,
                State_Partener_Batch_Get_Error: action.payload,
                State_Partener_Batch_Get_sucessfully: false,
                State_loading: false,
                //State_TotalPages:'0'
            }

        case PARTENER_Assign_LOAD:
            return {
                ...state,
                State_Assign_loading: true,
                State_Assign_Error:false,
                State_Assign_sucessfully:false,
                State_Assign_empty:false,
                State_Assign_Error_List:[],
            }                   
            
        case PARTENER_Assign_SUCCESSFULLY:
            return {
                ...state,
                State_Assign_loading: false,
                State_Assign_Error:false,
                State_Assign_sucessfully:true,
                State_Assign_empty:false,
                State_Assign_Error_List:[],
            }
            
        case PARTENER_Assign_ERROR:
            return {
                ...state,
                State_Assign_loading: false,
                State_Assign_Error:true,
                State_Assign_sucessfully:false,
                State_Assign_empty:false,
                State_Assign_Error_List:action.payload,
            }

        case PARTENER_Assign_Empty:
            return {
                ...state,
                State_Assign_loading: false,
                State_Assign_Error:false,
                State_Assign_sucessfully:false,
                State_Assign_empty:true,
                State_Assign_Error_List:[],
            }
                                    
        default:
            return state

    }
}
