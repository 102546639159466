import React, { Component } from 'react';
import '../../../css/dashboard.css';
import { connect } from 'react-redux';
import { fetchVendorDashboard } from '../../../Actions/VendorActions';
import ContentSection from './ContentSection';

class DashboardContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pieData: [],
            series: [],
            videos: [],
            radioChannels: [],
            games: [],
            totalUsers: 0,
            activeUsers: 0,
            remainingUsers: 0,
        };
    }

    componentDidMount() {
        this.props.fetchVendorDashboard();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dashboardData !== this.props.dashboardData && this.props.dashboardData) {
            const { totalTimeOfVideos, totalTimeOfRadioChannels, totalTimeOfGames, series, videos, radioChannels, games } = this.props.dashboardData;
            
            const pieData = [
                { name: 'Videos', minutes: totalTimeOfVideos, color: '#aa54e0' },
                { name: 'Games', minutes: totalTimeOfGames, color: '#51dc8a' },
                { name: 'Radio Channels', minutes: totalTimeOfRadioChannels, color: '#48bfd6' },
            ];
            

            this.setState({
                pieData,
                series,
                videos,
                radioChannels,
                games,
                totalUsers: this.props.dashboardData.numberOfCards,
                activeUsers: this.props.dashboardData.numberOfActiveCards,
                remainingUsers: this.props.dashboardData.numberOfRemainingCards
            });
        }
    }

    render() {
        const { pieData, series, videos, radioChannels, games, totalUsers, activeUsers, remainingUsers } = this.state;

        return (
            <div className="dashboard">
                <h1>Dashboard</h1>
                <div className="statistics-section">
                    <div className="stat-card total-users">
                        <h3>Total Cards</h3>
                        <p>{totalUsers.toLocaleString()}</p>
                    </div>
                    <div className="stat-card active-users">
                        <h3>Active</h3>
                        <p>{activeUsers.toLocaleString()}</p>
                    </div>
                    <div className="stat-card remaining-users">
                        <h3>Remain</h3>
                        <p>{remainingUsers.toLocaleString()}</p>
                    </div>
                </div>

                <ContentSection
                    pieData={pieData}
                    series={series}
                    videos={videos}
                    radioChannels={radioChannels}
                    games={games}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dashboardData: state.vendor.dashboardData,
    loading: state.vendor.dashboardLoading,
    error: state.vendor.dashboardError
});

export default connect(mapStateToProps, { fetchVendorDashboard })(DashboardContent);
