import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import '../../../css/DetailedActivities.css';
import { ACTIVITY_DETAIL_VIEW } from '../../../routes';

class DetailedActivitiesReportData extends Component {
    constructor(props) {
        super(props);
        const locationState = props.location.state || {};

        this.state = {
            data: locationState.data || [],
            startDate: locationState.startDate || "12/2/2024",
            endDate: locationState.endDate || "12/3/2024",
            type: locationState.type || '',
        };
    }

    render() {
        const { type, data, startDate, endDate } = this.state;

        return (
            <div className="detailed-activity-report">
                <h2 className="report-title">
                    {type === 'songs' ? 'Song Activity' :
                    type === 'stories' ? 'Story Activity' :
                    type === 'series' ? 'Series Activity' : 'Learning & Skills Activity'}
                </h2>

                {/* Date Range Display */}
                <div className="date-range">
                    <div className="date-input">Start Date: {startDate}</div>
                    <div className="date-input">End Date: {endDate}</div>
                </div>

                {/* Activity Count */}
                <div className="activity-header">
                    <h3>Activities</h3>
                    <p className="activity-count">{data.length} Activities</p>
                </div>

                {/* List of Activities in a Container */}
                <div className="activity-container">
                    {data.map((item, index) => {
                        const { customActivityTrackingDTO, watchingReport } = item;
                        return (
                            <div className="activityy-item" key={index}>
                                <img src={watchingReport.image} alt={watchingReport.name} className="activity-image"/>
                                <div className="activity-details">
                                    <h4 className="activity-name">{watchingReport.name}</h4>
                                    <p className="activity-evaluation">
                                        Evaluation: <span className="evaluation-score">{customActivityTrackingDTO.avgScoreText}</span>
                                    </p>
                                </div>
                                <Link
                                    to={{
                                        pathname: ACTIVITY_DETAIL_VIEW,
                                        state: { activity: item, type, startDate, endDate }
                                    }}
                                    className="details-but"
                                >
                                    Details
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default withRouter(DetailedActivitiesReportData);
