import { LOAD_SUBSCRIPTIONS_REPORT,
         GET_SUBSCRIPTIONS_REPORT,
         ERROR_LOADING_SUBSCRIPTIONS_REPORT,
         EMPTY_SUBSCRIPTIONS_REPORT,
         EXPORT_SUBSCRIPTIONS_REPORT_REQUEST,
         EXPORT_SUBSCRIPTIONS_REPORT_SUCCESS,
         EXPORT_SUBSCRIPTIONS_REPORT_FAIL} from "../Actions/Types";

const initial_state = {
    items: [],
    loading: '',
    error_loading_subscriptions_report: '',
    exporting: false, 
    exportError: '',  
}

export default function (state = initial_state, action){
    switch(action.type){
        case LOAD_SUBSCRIPTIONS_REPORT:
            return {
                ...state,
                loading: true,
            }
        
        case GET_SUBSCRIPTIONS_REPORT:
            return {
                ...state,
                items: action.payload,
                loading:false,
                error_loading_subscriptions_report:'',
            }

        case 'GET_Mondia_SUBSCRIPTIONS_REPORT':
            return {
                ...state,
                items: action.payload,
                loading:false,
                error_loading_subscriptions_report:'',
            }        
        case ERROR_LOADING_SUBSCRIPTIONS_REPORT:
            return{
                ...state,
                loading: false,
                error_loading_subscriptions_report: action.payload,
            }
        case EMPTY_SUBSCRIPTIONS_REPORT:
            return{
                ...state,
                items: '',
                loading: '',
                error_loading_subscriptions_report:'',
            }

            case EXPORT_SUBSCRIPTIONS_REPORT_REQUEST:
                return {
                    ...state,
                    exporting: true,
                    exportError: '',
                };
    
            case EXPORT_SUBSCRIPTIONS_REPORT_SUCCESS:
                return {
                    ...state,
                    exporting: false,
                    exportError: '',
                };
    
            case EXPORT_SUBSCRIPTIONS_REPORT_FAIL:
                return {
                    ...state,
                    exporting: false,
                    exportError: action.payload,
                };    
            
        default:
            return state;    
    }
}