import axios from 'axios';
import { 
    LOAD_STUDENT_ACTIVITIES, GET_STUDENT_ACTIVITIES_SUCCESS, GET_STUDENT_ACTIVITIES_ERROR,
    LOAD_RADIOS_REPORT, GET_RADIOS_REPORT_SUCCESS, GET_RADIOS_REPORT_ERROR,
    LOAD_WATCHINGS_REPORT, GET_WATCHINGS_REPORT_SUCCESS, GET_WATCHINGS_REPORT_ERROR,
    LOAD_WEEKLY_USAGE,GET_WEEKLY_USAGE_SUCCESS,GET_WEEKLY_USAGE_ERROR

} from './Types';
export const getStudentActivitiesReport = (startDate, endDate, childId) => {
    return (dispatch, getState, { getFirebase }) => {
        return new Promise((resolve, reject) => { 
            const firebase = getFirebase();

            firebase.auth().currentUser.getIdToken().then((token) => {
                dispatch({ type: LOAD_STUDENT_ACTIVITIES });

                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'X-Authorization-Firebase': token
                    },
                    params: {
                        Start_Date: startDate,
                        End_Date: endDate,
                        Child_ID: childId
                    }
                };

                axios.get('/ActivityTracking/getActivitiesReport', config)
                    .then((response) => {
                        dispatch({
                            type: GET_STUDENT_ACTIVITIES_SUCCESS,
                            payload: response.data
                        });
                        resolve(response.data); 
                    })
                    .catch((error) => {
                        dispatch({
                            type: GET_STUDENT_ACTIVITIES_ERROR,
                            payload: error.message
                        });
                        reject(error); 
                    });
            }).catch((error) => {
                dispatch({
                    type: GET_STUDENT_ACTIVITIES_ERROR,
                    payload: error.message
                });
                reject(error); 
            });
        });
    };
};


export const getRadiosReport = (startDate, endDate, childId) => {
    return (dispatch, getState, { getFirebase }) => {
        return new Promise((resolve, reject) => {
            const firebase = getFirebase();

            firebase.auth().currentUser.getIdToken().then((token) => {
                dispatch({ type: LOAD_RADIOS_REPORT });

                const config = {
                    headers: {
                        'X-Authorization-Firebase': token
                    },
                    params: {
                        Start_Date: startDate,
                        End_Date: endDate,
                        Child_ID: childId
                    }
                };

                axios.get('/Tracking/getRadiosReport', config)
                    .then((response) => {
                        dispatch({
                            type: GET_RADIOS_REPORT_SUCCESS,
                            payload: response.data,
                        });
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch({
                            type: GET_RADIOS_REPORT_ERROR,
                            payload: error.message,
                        });
                        reject(error);
                    });
            }).catch((error) => {
                dispatch({
                    type: GET_RADIOS_REPORT_ERROR,
                    payload: error.message,
                });
                reject(error);
            });
        });
    };
};

export const getChildWatchingsReport = (startDate, endDate, childId) => {
    return (dispatch, getState, { getFirebase }) => {
        return new Promise((resolve, reject) => {
            const firebase = getFirebase();

            firebase.auth().currentUser.getIdToken().then((token) => {
                dispatch({ type: LOAD_WATCHINGS_REPORT });

                const config = {
                    headers: {
                        'X-Authorization-Firebase': token,
                    },
                    params: {
                        Start_Date: startDate,
                        End_Date: endDate,
                        Child_ID: childId
                    }
                };

                axios.get('/Tracking/getChildWatchingsReport', config)
                    .then((response) => {
                        dispatch({
                            type: GET_WATCHINGS_REPORT_SUCCESS,
                            payload: response.data, 
                        });
                        resolve(response.data); 
                    })
                    .catch((error) => {
                        dispatch({
                            type: GET_WATCHINGS_REPORT_ERROR,
                            payload: error.message,
                        });
                        reject(error); 
                    });
            }).catch((error) => {
                dispatch({
                    type: GET_WATCHINGS_REPORT_ERROR,
                    payload: error.message,
                });
                reject(error); 
            });
        });
    };
};

export const getWeeklyUsageByDay = (startDate, endDate, childId) => {
    return (dispatch, getState, { getFirebase }) => {
        return new Promise((resolve, reject) => {
            const firebase = getFirebase();

            firebase.auth().currentUser.getIdToken().then((token) => {
                dispatch({ type: LOAD_WEEKLY_USAGE });

                const formData = new FormData();
                formData.append('Start_Date', startDate);
                formData.append('End_Date', endDate);
                formData.append('Child_ID', childId);

                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'X-Authorization-Firebase': token,
                    }
                };

                axios.post('/KidTracker/getWeeklyUsageByDay', formData, config)
                    .then((response) => {
                        dispatch({
                            type: GET_WEEKLY_USAGE_SUCCESS,
                            payload: response.data,
                        });
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch({
                            type: GET_WEEKLY_USAGE_ERROR,
                            payload: error.message,
                        });
                        reject(error);
                    });
            }).catch((error) => {
                dispatch({
                    type: GET_WEEKLY_USAGE_ERROR,
                    payload: error.message,
                });
                reject(error);
            });
        });
    };
};
