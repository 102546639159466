import axios, { post } from 'axios';
import firebase from 'firebase/app';

export const uploadImageProfileToW3s = (file) => {
  const url = "/Child/uploadChildImageToW3s"
  let token = firebase.auth().currentUser.h.b;
  const formData = new FormData();
  formData.append("file", file);
  return axios({
      method: "post",
      headers: {
          'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
          'X-Authorization-Firebase': token
      },
      url: url,
      data: formData,
  });
}
/*
call this api here
@PostMapping("/findChildrenByVendorIdAndName")
	public Page<Child> findChildrenByVendorIdAndName(@RequestParam("name") String name,
			Pageable pageable) {

		return childServiceImp.findChildrenByVendorIdAndName(name, pageable);
	} 
*/
export const findChildrenByVendorIdAndName = (name) => {
  const url = `/Child/findChildrenByVendorIdAndName?name=${name}&page=${0}&size=${20}`
  let token = firebase.auth().currentUser.h.b;
  return axios({
      method: "get",
      headers: {
          'content-type': 'application/json; charset=utf-8',
          'X-Authorization-Firebase': token
      },
      url: url,
  });
}
