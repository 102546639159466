import {
    ADD_EPISODE, PUBLISH_EPISODE, GET_EPISODE_DATA, PUBLISH_ACTIVITY, ERROR_ADDING_EPISODE,
    ERROR_ADDING_ACTIVITY, ERROR_LOADING_EPISODE, LOAD_EPISODE, UPDATE_EPISODE, ERROR_UPDATE_EPISODE,
    DELETING_EPISODE, ERROR_DELETING_EPISODE,
    SETTING_STATE_EPISODE, ERROR_SETTING_STATE_EPISODE,
    EMPTY_EPISODE, EMPTY_EPISODE_RESPONSE
} from './Types';
import axios, { post } from 'axios';
import { saveActivity } from '../Promises/Activity';
import { saveEpisode } from '../Promises/Episode';
import firebase from 'firebase/app'
import { put } from 'axios';

export const addEpisode = (episode_name,
    episode_description,
    episode_number,
    selected_series_id,
    selected_series_name,
    episode_publishDate,
    episode_imageUrl,
    episode_videoUrlwithMusic,
    episode_videoUrlwithoutMusic,
    episode_videoUrlwithMusic_duration,
    episode_videoUrlwithoutMusic_duration,
    radiobutton,
    isFree,
    voice,
    subValueId,
    episode_name_in_english,
    episode_description_in_english,
    videoKeyVideoWithMusic,
    videoKeyVideoWithoutMusic, featured,contentVendorSelectedId) => dispatch => {

        if (episode_description === null &&
            episode_description === undefined) {
            episode_description = '';
        }

        if (episode_description_in_english === null ||
            episode_description_in_english === undefined) {
            episode_description_in_english = '';
        }

        if (episode_name_in_english === null ||
            episode_name_in_english === undefined) {
            episode_name_in_english = '';
        }

        const episode = {
            episode_name: episode_name, episode_description: episode_description,
            episode_number: episode_number, selected_series_id: selected_series_id,
            selected_series_name: selected_series_name,
            episode_publishDate: episode_publishDate,
            episode_imageUrl: episode_imageUrl,
            episode_videoUrlwithMusic: episode_videoUrlwithMusic,
            episode_videoUrlwithoutMusic: episode_videoUrlwithoutMusic,
            episode_videoUrlwithMusic_duration: episode_videoUrlwithMusic_duration,
            episode_videoUrlwithoutMusic_duration: episode_videoUrlwithoutMusic_duration,
            videoKeyVideoWithMusic: videoKeyVideoWithMusic,
            videoKeyVideoWithoutMusic: videoKeyVideoWithoutMusic,
            radiobutton: radiobutton,
            isFree: isFree,
            voice: voice,
            subValueId: subValueId,
            episode_name_in_english: episode_name_in_english,
            episode_description_in_english: episode_description_in_english,
            featured: featured,
            contentVendorSelectedId:contentVendorSelectedId,
        }
        dispatch({
            type: ADD_EPISODE,
            payload: episode
        })
    }

export const getEpisodeData = (id) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_EPISODE,
                payload: 'loading'
            })

            const url = "/Episode/getEpisodeDetails/admin"
            const formData = new FormData();
            formData.append('Episode_ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_EPISODE_DATA,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_EPISODE,
                    payload: Error.message
                })
            })

        })

    }
}


export const publishEpisode = (name) => dispatch => {

    dispatch({
        type: LOAD_EPISODE,
        payload: 'loading'
    })

    saveActivity(name).then((res) => {

        dispatch({
            type: PUBLISH_ACTIVITY,
            payload: res
        })

        saveEpisode(res.id).then((res) => {
            dispatch(
                {
                    type: PUBLISH_EPISODE,
                    payload: res.data
                }
            )
        }).catch((Error) => {
            dispatch({
                type: ERROR_ADDING_EPISODE,
                payload: Error.message
            })
        })


    }).catch((Error) => {
        dispatch({
            type: ERROR_ADDING_ACTIVITY,
            payload: Error.message
        })
    });
}

export const updateEpisode = (episode_Id, episode_name,
    episode_description, episode_number,
    episode_publishDate, episode_imageUrl,
    episode_activityId, episode_seriesId,
    episode_video_with_music_Url,
    episode_video_withMusic_duration,
    episode_video_without_music_Url,
    episode_video_withoutMusic_duration, paymentAva,
    voice, subValueId,
    episode_name_in_english,
    episode_description_in_english,
    video_key_video_with_music,
    video_key_video_without_music, featured,contentVendorSelectedId
) => {
    return (dispatch, getState, { getFirebase }) => {

        if (episode_description === null &&
            episode_description === undefined) {
            episode_description = '';
        }

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_EPISODE,
                payload: 'loading'
            })

            let isFree = ''
            if (paymentAva === 'paid') {
                isFree = 0
            }
            else if (paymentAva === 'free') {
                isFree = 1
            }

            let updatedSubValueId
            if (subValueId === '' ||
                subValueId === undefined ||
                subValueId === null) {
                updatedSubValueId = 0
            } else {
                updatedSubValueId = subValueId
            }

            let videoWithMusicUrl = '', videoWithoutMusicUrl = '';
            let videoKeyVideoWithMusic = '', videoKeyVideoWithoutMusic = '';
            if (episode_video_with_music_Url === '' ||
                episode_video_with_music_Url === undefined ||
                episode_video_with_music_Url === null) {
                videoWithMusicUrl = ''
            } else {
                videoWithMusicUrl = episode_video_with_music_Url
            }


            if (episode_video_without_music_Url === '' ||
                episode_video_without_music_Url === undefined ||
                episode_video_without_music_Url === null) {
                videoWithoutMusicUrl = ''
            } else {
                videoWithoutMusicUrl = episode_video_without_music_Url
            }

            if (video_key_video_with_music === '' ||
                video_key_video_with_music === undefined ||
                video_key_video_with_music === null) {
                videoKeyVideoWithMusic = ''
            } else {
                videoKeyVideoWithMusic = video_key_video_with_music
            }
            if (video_key_video_without_music === '' ||
                video_key_video_without_music === undefined ||
                video_key_video_without_music === null) {
                videoKeyVideoWithoutMusic = ''
            } else {
                videoKeyVideoWithoutMusic = video_key_video_without_music
            }

            let status = '';
            const vedioWithMusicNotExist =  (videoWithMusicUrl === '' || videoWithMusicUrl === null || videoWithMusicUrl === undefined)
            const vedioWithoutMusicNotExist =  (videoWithoutMusicUrl === '' || videoWithoutMusicUrl === null || videoWithoutMusicUrl === undefined)
  
            if (episode_name === '' || episode_name === null || episode_name === undefined
                || episode_imageUrl === '' || episode_imageUrl === null || episode_imageUrl === undefined
                || episode_number === '' || episode_number === null || episode_number == 0
                || (episode_video_withoutMusic_duration == 0 && episode_video_withMusic_duration == 0)
                //|| (vedioWithMusicNotExist && vedioWithoutMusicNotExist)                      //both not existing
                || episode_publishDate === '' || episode_publishDate === null) {
                status = 'SUSPENDED'
            }
            else {
                status = ''
            }
                    
            const url = "/Episode/updateEpisode";
            const formData = new FormData();
            formData.append('ID', episode_Id);
            formData.append('Name', episode_name);
            formData.append('Image', episode_imageUrl);
            formData.append('Status', status);
            formData.append('Description', episode_description);
            formData.append('Episode_Number', episode_number);
            formData.append('Series_Id', episode_seriesId);
            formData.append('Activity_Id', episode_activityId);
            formData.append('Publish_Date', episode_publishDate);
            formData.append('Episode_Name_In_English', episode_name_in_english);
            formData.append('Episode_Description_In_English', episode_description_in_english);

            formData.append('Video_With_Music_URL', videoWithMusicUrl);
            formData.append('Video_With_Music_Duration', episode_video_withMusic_duration);

            formData.append('Video_Without_Music_URL', videoWithoutMusicUrl);
            formData.append('Video_Without_Music_Duration', episode_video_withoutMusic_duration);

            formData.append('Is_free', isFree);
            formData.append('Voice', voice);
            formData.append('Content_Sub_Value_Id', updatedSubValueId);
            formData.append('Video_Key_Video_With_Music', videoKeyVideoWithMusic);
            formData.append('Video_Key_Video_Without_Music', videoKeyVideoWithoutMusic);
            formData.append('featured', featured);
            formData.append('contentVendorSelectedId', contentVendorSelectedId);
            


            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            put(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: UPDATE_EPISODE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATE_EPISODE,
                    payload: Error.response.data.message
                })
            })
            
            
        })

    }
}


export const deleteEpisode = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_EPISODE,
                payload: 'loading'
            })

            const url = "/Episode/deleteEpisode";
            const formData = new FormData();
            formData.append('ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: DELETING_EPISODE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_EPISODE,
                    payload: Error.response.data.message
                })
            })
        })

    }
}


export const setStateEpisode = (id, state) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_EPISODE,
                payload: 'loading'
            })

            const url = "/Episode/setState";
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('State', state);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: SETTING_STATE_EPISODE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SETTING_STATE_EPISODE,
                    payload: Error.response.data.message//Error.message
                })
            })
        })

    }
}


export const emptyEpisodes = () => dispatch => {
    dispatch({
        type: EMPTY_EPISODE,
        payload: ''
    })
}

export const emptyError = () => dispatch => {
    dispatch({
        type: EMPTY_EPISODE_RESPONSE,
        payload: ''
    })
}




export const deleteEpisodePuzzle = (id) => {

    const url = "/EpisodePuzzleController/deleteEpisodePuzzle"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Episode_Puzzle_Id", id);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}


export const saveEpisodePuzzle = (Episode_ID, Image_URL) => {

    const url = "/EpisodePuzzleController/saveEpisodePuzzle"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Episode_ID", Episode_ID);
    formData.append("Image_URL", Image_URL);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}



export const deleteEpisodePaintingGame = (id) => {

    const url = "/EpisodePaintingGameController/deleteEpisodePaintingGame"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Episode_Painting_Game_Id", id);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}


export const saveEpisodePaintingGame = (Episode_ID, Image_URL) => {

    const url = "/EpisodePaintingGameController/saveEpisodePaintingGame"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Episode_ID", Episode_ID);
    formData.append("Image_URL", Image_URL);
    return axios({

        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

/*Start of Drag Drop*/

export const getDragAndDropEpisode = (DDID) => {
    const url = "/EpisodeDragDrop/getEpisodeDragAndDropData"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("EpisodeDragDrop_ID", DDID);
    return axios({
        method: "post",
        headers: {
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const saveEpisodeDragAndDrop = (attributes) => {
    const url = "/EpisodeDragDrop/saveEpisodeDragDrop"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/json',
            'X-Authorization-Firebase': token
        },
        data: {
            episodeId: attributes.parentId,
            imageUrl: attributes.game_Image,
            cropedImageUrl: attributes.cropedImageUrl,
            dragDropWidth: attributes.dragDropWidth,
            dragDropHeight: attributes.dragDropHeight,
            draggableElements: attributes.listElements
        },
        url: url
    });
}

export const deleteEpisodeDragAndDrop = (id) => {
    const url = "/EpisodeDragDrop/deleteDragAndDrop"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("EpisodeDragDrop_ID", id);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const addDraggableElementEpisode = (DDID, draggableWidth, draggableHeight, xCoordinate, yCoordinate, draggableImage) => {
    const url = "/EpisodeDragDrop/addDraggableElement"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "post",
        headers: {
            'X-Authorization-Firebase': token
        },
        data: {
            dragDropId: DDID,
            draggableWidth: draggableWidth,
            draggableHeight: draggableHeight,
            xCoordinate: xCoordinate,
            yCoordinate: yCoordinate,
            draggableImage: draggableImage,
        },
        url: url
    });
}

export const deleteDraggableElementEpisode = (id) => {
    const url = "/EpisodeDragDrop/deleteDraggableElement"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("DraggableElement_ID", id);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const updateOneDraggableElementEpisode = (id, width, height, XCoordinate, YCoordinate, imageURL) => {
    const url = "/EpisodeDragDrop/updateOneDraggableElement";
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("DraggableId", id);
    formData.append("DraggbleWidth", width);
    formData.append("DraggbleHeight", height);
    formData.append("XCoordinate", XCoordinate);
    formData.append("YCoordinate", YCoordinate);
    formData.append("url", imageURL);

    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const updateDragAndDropEpisode = (id, width, height, imageURL, croppedImageURL) => {
    const url = "/EpisodeDragDrop/updateEpisodeDragAndDrop";
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("DragDropWidth", width);
    formData.append("DragDropHeight", height);
    formData.append("url", imageURL);
    formData.append("Cropped_Image_Url", croppedImageURL);

    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

/*End of Drag Drop*/
