import React from 'react';
import ReactTable from 'react-table';
import Loader from '../Layout/Loader';

class CountryCountSubscriptionReportDashboard extends React.Component {
  renderTable() {
    const { SubsReport, error, loading } = this.props;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <div>Error loading data</div>;
    }

    const countries = Object.keys(SubsReport);
    const paymentMethods = Array.from(
      new Set(
        countries.reduce(
          (methods, country) => methods.concat(SubsReport[country].map((item) => item.nameOfpaymentMethod)),
          []
        )
      )
    );

// Inside the renderTable method
const methodColumns = paymentMethods.map((method) => {
  const baseColumns = [
    {
      Header: 'Monthly',
      accessor: `monthly_${method}`,
      headerClassName: 'table-header',
      Cell: (props) => <div style={{ textAlign: 'center' }}>{props.value}</div>,
    },
    {
      Header: 'Weekly',
      accessor: `weekly_${method}`,
      headerClassName: 'table-header',
      Cell: (props) => <div style={{ textAlign: 'center' }}>{props.value}</div>,
    },
    {
      Header: 'Yearly',
      accessor: `yearly_${method}`,
      headerClassName: 'table-header',
      Cell: (props) => <div style={{ textAlign: 'center' }}>{props.value}</div>,
    }
  ];

  // add the 'Custom' column if method is "MONDIA"
  if (method === "MONDIA") {
    baseColumns.push({
      Header: 'Custom',
      accessor: `custom_${method}`,
      headerClassName: 'table-header',
      Cell: (props) => <div style={{ textAlign: 'center' }}>{props.value}</div>,
    });
  }

  return {
    Header: method,
    accessor: method,
    headerClassName: 'table-header',
    columns: baseColumns,
  };
});

const columns = [
  {
    Header: 'Method',
    accessor: 'paymentMethodHeader',
    headerClassName: 'table-header',
    columns: [
      {
        Header: 'Country',
        accessor: 'country',
        headerClassName: 'table-header',
        Cell: (props) => <div style={{ textAlign: 'center' }}>{props.value}</div>,
      },
    ],
  },
  ...methodColumns,
];


    const data = countries.map((country) => {
      const row = { paymentMethodHeader: 'Payment Method', country };
      paymentMethods.forEach((method) => {
        const subscription = SubsReport[country].find((item) => item.nameOfpaymentMethod === method);
        row[`monthly_${method}`] = subscription ? subscription.totalNumberOfSubscriptionsMonthly : 0;
        row[`yearly_${method}`] = subscription ? subscription.totalNumberOfSubscriptionsYearly : 0;
        row[`weekly_${method}`] = subscription ? subscription.totalNumberOfSubscriptionsWeekly : 0;

        if (method === "MONDIA"){
          row[`custom_${method}`] = subscription ? subscription.totalNumberOfSubscriptionsCustom : 0;
        }
      });
      return row;
    });

    return (
      <div>
        <ReactTable
          className=" table-striped custm-table mt-4"
          columns={columns}
          data={data}
          showPagination={false}
          pageSize={data.length === 0 ? 3 : data.length}
        />
      </div>
    );
  }

  render() {
    return <div style={{ overflow: 'hidden' }}>{this.renderTable()}</div>;
  }
}

export default CountryCountSubscriptionReportDashboard;
