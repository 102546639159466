import { Promise } from 'q';
import firebase from 'firebase/app';
import {post} from 'axios'
import 'firebase/firestore';
import 'firebase/auth';


export function getCustomerAsPages (page_Number, size, filterBy, usernameFilter)
{
    return new Promise (function(resolve,reject)
    {

        console.log('filterBy = '+ filterBy)
        console.log('User_Name_Filter = '+ usernameFilter)

        const url = '/User/getCustomerAsPages';

        const formData = new FormData();
        formData.append('Page_Number', page_Number);
        formData.append('Size', size);
        formData.append('filterBy', filterBy);
        formData.append('User_Name_Filter', usernameFilter);


        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }
        }
        post(url, formData, config).then((res)=>
        {
            resolve(res);
            return res;
        }).catch((Error)=>
        {
            reject(Error)
            return Error.message
        })
    })
}


export function getVendorCustomerAsPages (page_Number, size, filterBy, usernameFilter)
{
    return new Promise (function(resolve,reject)
    {

        console.log('filterBy = '+ filterBy)
        console.log('User_Name_Filter = '+ usernameFilter)

        const url = '/User/getCustomersByVendor';

        const formData = new URLSearchParams();
        formData.append('Page_Number', page_Number);
        formData.append('Size', size);
        formData.append('filterBy', filterBy);
        formData.append('User_Name_Filter', usernameFilter);


        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }
        }
        post(url, formData, config).then((res)=>
        {
            resolve(res);
            return res;
        }).catch((Error)=>
        {
            reject(Error)
            return Error.message
        })
    })
}
