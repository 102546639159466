
import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Link, NavLink, withRouter } from "react-router-dom";


import { connect } from 'react-redux';
import firebase from 'firebase/app';

/*Navbar*/
import OuterHeader from '../../Layout/OuterHeader';
import InnerHeader from '../../Layout/InnerHeader';
import Dashboard from '../../Dashboard';
import Footer from '../../Layout/Footer';
import '../../../css/partener.css';

import  Pagination from './Pagination';
import PartenerBatches from './PartenerBatch';
import {getPartenerBatches} from './PartenerBatchActions';



import { useState } from "react";
import { useEffect } from 'react';
import {getFirebase} from 'react-redux-firebase';
import { get,post } from 'axios';
import axios from 'axios'

import { Partener_Vouchers } from '../../../routes';



 const PartenerBatch = ({ getPartenerBatches,partenerBatches,totalPages,loading,success,error }) => {
												  //const [formData, setFormData] = useState({
												  //  company: '',
												  //});

  												  //const [displaySocialInputs, toggleSocialInputs] = useState(false);
		
		const [listOfPartenerBatches, setListOfPartenerBatches] = useState([])
		const [packageType, setPackageType] = useState('')
		const [period, setPeriod] = useState('')
		const [currentPage, setCurrentPage] = useState(0);
		const [batchsPerPage, setBatchesPerPage] = useState(10);
		//const [totalPages, setTotalPages] = useState(totalPages); 


// =======================================================================================================================================================
       
// =======================================================================================================================================================        
        
// =======================================================================================================================================================
  
const formatPercentage=(partial,total)=>{

	if(!partial || partial < 1)
		return '';
			
	let percentageInDouble = (100*partial)/total;
	let percentageWithoutDouble = Math.trunc( percentageInDouble );
	
	if(percentageInDouble==percentageWithoutDouble)
	{	
		return "("+percentageInDouble+"%)"
	} 	
	else
	{
		percentageInDouble = percentageInDouble.toFixed(1)
		return "("+percentageInDouble+"%)"
	}
}
// =====================================================================
const handlePackageTypeChange = (e, { value }) => {
    setPackageType(value);
}

const handlePeriodChange = (e, { value }) => {
    setPeriod(value);
}														    
// =====================================================================
useEffect(() => {
	getPartenerBatches(packageType,0,period,batchsPerPage);														    
}, [packageType,period]); 
// ===================================================================== 
 /*const getPartenerCrads = (type,pageNum)=>{

	setLoading(true);
	
	const firebase = getFirebase();
	//console.log('firebase is :'+firebase);																																															
    firebase.auth().currentUser.getIdToken().then((token) => {
    //console.log('token is :'+token);
        
        const url = "/Vendor/batches";
        const formData = new FormData();
        formData.append("packageType",type);
        formData.append("period",period);
        formData.append("page",pageNum);
        formData.append("size",batchsPerPage);
        
        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': token
            }
        }
       
       post(url,formData, config).then((res) => {
			console.log('res in useEffect : '+JSON.stringify(res.data,null,2));

			setListOfPartenerBatches(res.data.content);
			setTotalPages(res.data.totalPages)
            setLoading(false);                                

        }).catch((Error) => {
             console.log('Error while getBatches :'+Error)
        })

    })         
 }*/


 // =====================================================================
	//Pagination logic :
	  														 
	const handlePagination = (pageNumber) => {
	
		setCurrentPage(pageNumber);
	    
	    getPartenerBatches(packageType,pageNumber,period,batchsPerPage);
	
	};

 // =====================================================================  
  														 

    	const packageTypesOptions = [];        
    	packageTypesOptions.push({key: 0,text: 'All',value: '',});
	  	packageTypesOptions.push({key: 1,text: 'Monthly',value: 1,});
	  	packageTypesOptions.push({key: 2,text: 'Yearly',value: 2,});
	  	packageTypesOptions.push({key: 3,text: 'Weekly',value: 3,});
	  														 
    	const packagePeriodsOptions = [];        
    	packagePeriodsOptions.push({key: 0,text: 'All',value: '',});	  	
	  	packagePeriodsOptions.push({key: 1,text: 'This Month',value: 1,});
	  	packagePeriodsOptions.push({key: 2,text: 'This Year',value: 2,});  														 
														 
/* =====================================================================================================================================================*/
/* =====================================================================================================================================================*/
/* =====================================================================================================================================================*/


        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                {/* <InnerHeader /> */}
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                           
                                            <div className="box-container">                                                
                                                

                                                <h4 className="main-title mt-2 font-weight-bold pl-4"> Cards </h4>
                                                
                                                <div className="row">
	                                                <div className="col-8"> </div>
	                                                <div className="col-2">
	                                                	<div className="text-right  mb-2">Package Type </div>	                                                
														<Dropdown                                
							                                fluid
							                                selection
							                                placeholder="Select"
							                                id="packageType_id"
							                                onChange={handlePackageTypeChange}
							                                options={packageTypesOptions}
							                                value={packageType}
							                              />         
	                                                                              
													</div>
													<div className="col-2">
													<div className="text-right mb-2">Period </div>                     
														<Dropdown                                
							                                fluid
							                                selection
							                                placeholder="Select"
							                                id="packagePeriodId"
							                                onChange={handlePeriodChange}
							                                options={packagePeriodsOptions}
							                                value={period}
							                              />         
	                                                                              
													</div>		                                           	                                                

                                                </div>
                                                
                                                <div className=" mt-3 p-3 panel">                                                												    
												
												{
													/*listOfPartenerBatches && listOfPartenerBatches.length>0?*/
													success?

														<>
															
															<PartenerBatches batches={partenerBatches.content} loading={loading} />
																					   														
															<Pagination 
																length={listOfPartenerBatches.length} 
																batchesPerPage={batchsPerPage} 
																handlePagination={handlePagination} 
																currentPage={currentPage}	
																totalPages={totalPages}														
															/>
													</>
													: <div className="p-10 m-10 "> No Batches Avaliable</div> 
												}                                                	                                                
                                                
                                                
                                                                                                                                                         
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )        
}

const mapStateToProps = state => ({
    // userPaymentProfile: state.userPaymentProfile.item
    partenerBatches: state.partenerBatchReducer.State_items,
    loading: state.partenerBatchReducer.State_loading,
    totalPages: state.partenerBatchReducer.State_TotalPages,
    
    error: state.partenerBatchReducer.State_Partener_Batch_Get_Error,
    success: state.partenerBatchReducer.State_Partener_Batch_Get_sucessfully,
});

//export default PartenerCards;
//export default withRouter (connect (mapStateToProps, { getPartenerBatches }) (PartenerBatch));

export default connect( mapStateToProps, { getPartenerBatches } )(withRouter(PartenerBatch));
    
